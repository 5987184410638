<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { findColorfulIconByHost, validateImageByDimensions } from '../../utils';

import BaseColorfulIcon from '../BaseColorfulIcon/BaseColorfulIcon.vue';

const props = defineProps<{
  host: string;
}>();

const faviconSize = ref(32);
const error = ref(false);

const colorfulIconName = computed(() => {
  const icon = findColorfulIconByHost(props.host);

  if (icon) {
    return icon.name.toLowerCase();
  }

  if (error.value || !src.value) return 'generic';

  return undefined;
});

const src = computed(() => {
  const hostParts = props.host.split('.');
  const host =
    hostParts.length > 2 ? hostParts.slice(-2).join('.') : props.host;

  return `https://www.google.com/s2/favicons?domain=${host}&sz=${faviconSize.value}`;
});

function onImgError() {
  error.value = true;
}

async function validateFavicon() {
  try {
    const isValid = await validateImageByDimensions(
      src.value,
      faviconSize.value,
      faviconSize.value
    );

    error.value = !isValid;
  } catch (e) {
    error.value = true;
  }
}

watch(
  () => props.host,
  () => {
    error.value = false;
    validateFavicon();
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <BaseColorfulIcon
    v-if="colorfulIconName"
    class="favicon"
    :name="colorfulIconName"
  />
  <img
    v-else
    class="favicon"
    :src="src"
    :alt="`${host} icon`"
    @error="onImgError"
  />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.favicon {
  $self: &;

  height: 1em;
  width: 1em;
}
</style>
