import { type FlowContent, RemedyType } from '../../../types/content';

const content: FlowContent = {
  type: RemedyType.Flow,
  name: 'Rerouting API Calls Between APIs',
  videoUrl: '/videos/routing-api-calls-between-apis.mp4',
  summary:
    'Switching Between OpenAI API /completions endpoint and Anthropic API.',
};

export default content;
