import { type FlowContent, RemedyType } from '../../../types/content';

const content: FlowContent = {
  type: RemedyType.Flow,
  name: 'Caching Google Maps API',
  videoUrl: '/videos/google-maps-caching.mp4',
  summary: "Caching Google Maps API according to Google's T&C.",
};

export default content;
