import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';
import {
  UserRole,
  UserStatus,
  type UserWithStatusWithApiKeysDAO,
  type OrganizationDAO,
  type UserDAO,
  type InviteeDAO,
} from '@monorepo/shared-model/src/user';
import type {
  Feedback,
  OrganizationRequest,
} from '@monorepo/shared-model/src/webserver-payloads';
import { type User, useAuth0 } from '@auth0/auth0-vue';
import { FeatureFlag, type UserPermissions } from '../../types';
import { useToast } from '../../use/toast';
import { useFetch } from '../../use/fetch';
import { mockProvidersGroups } from '../demo/mocks';
import posthog from 'posthog-js';
import {
  hasDevToolsAccess,
  isAdmin,
  isUserEligibleForPostHogRecording,
} from '../../utils';

type Token = {
  id_token: string;
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  scope?: string;
};

const socialProviderMap: { [key: string]: string } = {
  'google-oauth2': 'Google',
  github: 'GitHub',
};

export const useUserStore = defineStore('user', () => {
  const toast = useToast();

  /**
   * State
   * ------------------------------------------------------------
   */
  const {
    isAuthenticated,
    logout: sdkLogout,
    getAccessTokenSilently,
  } = useAuth0();

  const isPlayground = import.meta.env.VITE_PLAYGROUND === 'true';
  const user = ref<User>({
    user_metadata: {},
  });
  const token = ref<Token>();
  const extendedUser = ref<UserWithStatusWithApiKeysDAO>();
  const org = ref<OrganizationDAO>();

  /**
   * Getters
   * ------------------------------------------------------------
   */
  const isUserInitialized = computed<boolean>(
    () =>
      isPlayground || (!!user.value && !!token.value && !!extendedUser.value)
  );

  const isSocial = computed<boolean>(() => user.value?.identities[0].isSocial);

  const isSSO = computed<boolean>(() =>
    user.value?.authenticationmethod.includes('SAML')
  );

  const ssoEmail = computed<string | undefined>(
    () => isSSO.value && user.value?.nameIdAttributes?.value
  );

  const socialProvider = computed<string | undefined>(
    () =>
      (isSocial.value &&
        socialProviderMap[user.value?.identities[0]?.provider]) ||
      undefined
  );

  const permissions = computed<UserPermissions>(() => ({
    // Use can access dev tools.
    canAccessDevTools: hasDevToolsAccess(user.value),

    // User is an admin.
    isAdmin: isAdmin(extendedUser.value, org.value),

    // User is in playground mode.
    isPlayground,

    // Feature flags.
    featureFlag: {
      // User has access to plugin suggestions.
      pluginSuggestions:
        user.value.user_metadata?.dev_mode?.feature_flags?.[
          FeatureFlag.PluginSuggestions
        ] || isPlayground,
      altNavigation: true,
      apiProviderView:
        user.value.user_metadata?.dev_mode?.feature_flags?.[
          FeatureFlag.ApiProviderView
        ],
    },
  }));

  /**
   * Actions
   * * ------------------------------------------------------------
   */
  const getUser = async (): Promise<User> => {
    const { data, error } = await useFetch('/external-user').json();

    return !error.value && data.value;
  };

  const updateUser = async (payload: User): Promise<User> => {
    const { data, error } = await useFetch('/external-user')
      .patch(payload)
      .json();

    return !error.value && data.value;
  };

  const updatePassword = async (password: string): Promise<User> => {
    return await updateUser({
      password,
      // The connection name is the name of the default database in Auth0.
      // https://manage.auth0.com/dashboard/us/dev-b8usc66hvtpq73zg/connections/database
      connection: 'Username-Password-Authentication',
    });
  };

  const deleteUser = async (): Promise<{ success: boolean }> => {
    const { data, error } = await useFetch(`/user`).delete().json();

    return !error.value && data.value;
  };

  const verifyPassword = async (
    attemptedPassword: string
  ): Promise<{ valid: boolean }> => {
    const { data } = await useFetch(`/verify-external-user-password`)
      .post({ attemptedPassword })
      .json();

    return data.value;
  };

  const getExtendedUser = async (): Promise<
    UserWithStatusWithApiKeysDAO | undefined
  > => {
    const { data, error } = await useFetch(`/user`).json();

    return !error.value && data.value;
  };

  const getOrganization = async (): Promise<OrganizationDAO | undefined> => {
    const { data, error } = await useFetch('/organization').json();

    return !error.value && data.value;
  };

  const updateOrCreateOrganization = async (
    payload: OrganizationRequest
  ): Promise<OrganizationDAO | undefined> => {
    if (org.value) {
      return await updateOrganization(payload);
    }

    return await createOrganization(payload);
  };

  const createOrganization = async (
    payload: OrganizationRequest
  ): Promise<OrganizationDAO | undefined> => {
    const { data, error } = await useFetch('/organization')
      .post(payload)
      .json();

    return !error.value && data.value;
  };

  const updateOrganization = async (
    payload: OrganizationRequest
  ): Promise<OrganizationDAO | undefined> => {
    const { data, error } = await useFetch('/organization')
      .patch(payload)
      .json();

    return !error.value && data.value;
  };

  const deleteOrganizationUser = async (
    payload: UserDAO
  ): Promise<{ members: UserDAO[] } | undefined> => {
    const { data, error } = await useFetch(`/organization/user/${payload.id}`)
      .delete()
      .json();

    return !error.value && data.value;
  };

  const revokeInvitation = async (
    payload: InviteeDAO
  ): Promise<{ invitees: InviteeDAO[] } | undefined> => {
    const { data, error } = await useFetch(
      `/organization/invitation/${payload.id}`
    )
      .delete()
      .json();

    return !error.value && data.value;
  };

  const sendFeedback = async (payload: Feedback) => {
    const { data, error } = await useFetch(`/feedback`).post(payload).json();

    return !error.value && data.value;
  };

  const waitForInitialization = () => {
    return new Promise((resolve) => {
      if (isUserInitialized.value) {
        resolve(true);
      } else {
        const unwatch = watch(
          () => isUserInitialized.value,
          (newValue) => {
            if (newValue) {
              unwatch();
              resolve(true);
            }
          },
          { immediate: true }
        );
      }
    });
  };

  // Convinence function to check if a user has a feature flag.
  const hasFeatureFlag = (flag: FeatureFlag) => {
    return permissions.value.featureFlag[flag];
  };

  const logout = async () => {
    sdkLogout({
      openUrl() {
        window.location.replace(import.meta.env.VITE_BASE_URL);
      },
    });
  };

  if (isPlayground) {
    user.value = {
      name: 'Playground User',
      email: 'playground@lunar.dev',
      user_metadata: {
        dev_mode: {
          demo_mode: true,
          demo_update_interval: 15000,
          demo_providers: Array.from(
            { length: Object.keys(mockProvidersGroups).length },
            (_, index) => index
          ),
        },
      },
    };
    extendedUser.value = {
      id: 1,
      createdAt: new Date(),
      updatedAt: new Date(),
      status: UserStatus.Integrated,
      externalUserId: '1',
      apiKeys: [
        {
          id: 1,
          createdAt: new Date(),
          updatedAt: new Date(),
          key: 'dummy-api-key',
        },
      ],
      email: 'foo@bar.com',
      role: UserRole.Member,
    };
    token.value = {
      id_token: '1',
      access_token: 'dummy-access-token',
      expires_in: 3600,
    };

    // Ensure we posthog record sessions in playground mode.
    posthog.startSessionRecording();
  } else {
    watch(
      () => isAuthenticated.value,
      async () => {
        if (isAuthenticated.value) {
          // Ordering here is important to ensure multiple attempts to create an extended user aren't triggered at the same time.
          // First we want to get the access token from Auth0.
          const accessToken = await getAccessTokenSilently({
            detailedResponse: true,
          });

          // Store the accessToken in state for use in the API.
          token.value = accessToken;

          // Then we can use the access token to get the Auth0 user.
          const userResult = await getUser();

          if (!userResult) {
            toast.add({
              severity: 'error',
              summary: 'Failed to retrieve user details.',
              life: 3000,
            });
          } else {
            user.value = userResult;
          }

          // Then we can use the access token to get the extended user which include API key.
          const extendedUserResult = await getExtendedUser();

          if (!extendedUserResult) {
            toast.add({
              severity: 'error',
              summary: 'Failed to retrieve user details.',
              life: 3000,
            });
          } else {
            extendedUser.value = extendedUserResult;
          }

          if (isUserEligibleForPostHogRecording(user.value)) {
            posthog.startSessionRecording();
          } else {
            console.log('User is excluded from PostHog session recording.');
          }

          // Set user organization state.
          const orgResult = await getOrganization();

          if (orgResult) {
            org.value = orgResult;
          }
        }
      },
      {
        immediate: true,
      }
    );
  }

  return {
    user,
    isAuthenticated,
    isUserInitialized,
    isSocial,
    isSSO,
    ssoEmail,
    socialProvider,
    permissions,
    extendedUser,
    token,
    org,
    getUser,
    getExtendedUser,
    updateUser,
    updatePassword,
    deleteUser,
    verifyPassword,
    waitForInitialization,
    sendFeedback,
    hasFeatureFlag,
    logout,
    getOrganization,
    updateOrCreateOrganization,
    deleteOrganizationUser,
    revokeInvitation,
  };
});
