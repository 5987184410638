<script lang="ts">
import { cva, cx, type VariantProps } from 'cva';

export const buttonVariants = {
  variant: {
    primary: 'button--primary',
    secondary: 'button--secondary',
    ghost: 'button--ghost',
    danger: 'button--danger',
    ['danger-ghost']: 'button--danger-ghost',
    link: 'button--link',
    ['link-danger']: 'button--link-danger',
    select: 'button--select',
    raw: 'button--raw',
  },
  size: {
    small: 'button--small',
    large: 'button--large',
    xlarge: 'button--xlarge',
  },
  type: {
    button: undefined,
    submit: undefined,
    reset: undefined,
  },
  disabled: {
    true: 'button--disabled',
  },
  fillWidth: {
    true: 'button--fill-width',
  },
  mode: {
    dark: 'button--mode-dark',
  },
};

const classes = cva({ variants: buttonVariants });

export type ButtonProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

const props = withDefaults(
  defineProps<{
    variant?: ButtonProps['variant'];
    size?: ButtonProps['size'];
    disabled?: boolean;
    fillWidth?: boolean;
    type?: ButtonProps['type'];
    mode?: ButtonProps['mode'];
  }>(),
  {
    variant: 'primary',
    size: 'large',
    type: 'button',
  }
);

const slots = useSlots();

const hasLeftIcon = computed(() => !!slots.leftIcon);
const hasRightIcon = computed(() => !!slots.rightIcon);
const hasText = computed(() => !!slots.default);

const isIconOnly = computed(
  () => (hasLeftIcon.value || hasRightIcon.value) && !hasText.value
);

const computedClasses = computed(() => ({
  'button--has-left-icon': hasLeftIcon.value && !isIconOnly.value,
  'button--has-right-icon': hasRightIcon.value && !isIconOnly.value,
  'button--icon-only': isIconOnly.value,
}));

const computedLayoutIconsSize = computed(() => {
  if (props.size !== 'small') return;

  return 'small';
});
</script>

<template>
  <button
    class="button"
    :class="
      cx(classes({ variant, size, disabled, fillWidth, mode }), computedClasses)
    "
    :type="type"
    :disabled="disabled"
  >
    <template v-if="variant === 'raw'">
      <slot />
    </template>
    <BaseLayoutIcons
      v-else
      class="button__container"
      :icon-size="16"
      :size="computedLayoutIconsSize"
    >
      <template v-if="hasLeftIcon" #leftIcon>
        <slot name="leftIcon" />
      </template>
      <template v-if="hasText" #default>
        <span class="button__text">
          <slot name="default" />
        </span>
      </template>
      <template v-if="hasRightIcon" #rightIcon>
        <slot name="rightIcon" />
      </template>
    </BaseLayoutIcons>
  </button>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.button {
  $self: &;

  align-items: center;
  appearance: none;
  border: none;
  border-radius: $space-xxxsmall;
  color: $color-text-inverted;
  cursor: pointer;
  display: inline-flex;
  font-weight: $font-weight-bold;
  line-height: $line-height-default;
  justify-content: center;
  position: relative;
  text-decoration: none;
  user-select: none; /* users probably don’t need to copy button text */
  white-space: nowrap;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    background-color: $color-bg-disabled;
    color: $color-text-disabled;
  }

  &:not(#{$self}--fill-width) + #{$self} {
    margin-left: $space-xxsmall;
  }

  &--disabled {
    cursor: no-drop;
    opacity: 0.7;
  }

  &--fill-width {
    align-items: center;
    width: 100%;

    #{$self}__text {
      margin: 0 auto;
    }
  }

  /**
   * Size modifiers
   */
  &--xlarge {
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    border-radius: $space-xxsmall;
    padding: $space-xxsmall $space-small;

    &:not(#{$self}--link):not(#{$self}--link-danger) {
      height: $scale-xlarge;

      &#{$self}--icon-only {
        width: $scale-medium + $scale-xtiny;
        position: relative;

        #{$self}__container {
          left: 50%;
          position: absolute;
          transform: translate3d(-50%, 0, 0);
        }
      }
    }
  }

  &--large {
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    border-radius: $space-xxsmall;
    padding: $space-xxsmall $space-small;

    &:not(#{$self}--link):not(#{$self}--link-danger) {
      height: $scale-large;

      &#{$self}--icon-only {
        width: $scale-medium + $scale-xtiny;
        position: relative;

        #{$self}__container {
          left: 50%;
          position: absolute;
          transform: translate3d(-50%, 0, 0);
        }
      }
    }
  }

  &--small {
    font-size: $font-size-xsmall;
    padding: $space-xsmall $space-xxsmall;

    &:not(#{$self}--link):not(#{$self}--link-danger) {
      height: $scale-small + $scale-xtiny;

      &#{$self}--icon-only {
        width: $scale-small + $scale-xtiny;
        position: relative;
        padding: $space-xxsmall;

        #{$self}__container {
          left: 50%;
          position: absolute;
          transform: translate3d(-50%, 0, 0);
        }
      }
    }
  }

  /**
   * Variant modifiers
   */
  &--primary {
    background: $color-bg-brand;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: $color-bg-brand-hover;
    }
  }

  &--secondary {
    background-color: $color-bg-default;
    color: $color-text-secondary;
    box-shadow: inset 0 0 0 1px $color-border-default;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: $color-bg-surface;
      box-shadow: inset 0 0 0 1px $color-border-secondary;
    }
  }

  &--ghost,
  &--danger-ghost {
    background-color: transparent;
    color: $color-text-secondary;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: $color-bg-surface;
    }
  }

  &--danger-ghost {
    color: $color-text-danger;
  }

  &--danger {
    background: $color-bg-danger;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: $color-bg-danger-hover;
    }
  }

  &--link,
  &--link-danger {
    background-color: transparent;
    border-radius: 0;
    color: $color-text-secondary;
    font-size: inherit;
    font-weight: inherit;
    display: inline-flex;
    padding: 0;

    #{$self}__text {
      text-decoration: underline;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: $color-bg-surface;
    }

    &#{$self}--disabled {
      background-color: transparent;
      opacity: 1;
      color: $color-text-disabled;

      #{$self}__text {
        text-decoration: underline;
      }
    }

    &#{$self}--icon-only {
      #{$self}__container {
        overflow: hidden;
        padding: 0 $space-xxxsmall;
      }
    }
  }

  &--link-danger {
    color: $color-text-danger;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      color: $color-text-danger;

      #{$self}__text {
        text-decoration: underline;
      }
    }
  }

  &--select {
    background-color: transparent;
    color: $color-text-secondary;
    padding-left: $space-small;
    padding-right: $space-xxsmall;

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      box-shadow: inset 0 0 0 1px $color-border-secondary;
    }

    &#{$self}--disabled {
      background-color: $color-bg-surface;
      box-shadow: none;
    }
  }

  &--raw {
    background-color: transparent;
    border: none;
    color: inherit;
    height: auto;
    outline: none;
    padding: 0;
    vertical-align: middle;
    width: auto;

    &#{$self}--small,
    &#{$self}--large {
      height: auto;
    }
  }

  &--mode-dark {
    &#{$self}--select {
      color: rgba($color-text-inverted, 0.6);

      &:not([disabled]):hover,
      &:not([disabled]):focus {
        color: $color-text-inverted;
      }
    }
    &#{$self}--ghost {
      color: $color-text-inverted;

      &:not([disabled]):hover,
      &:not([disabled]):focus {
        background-color: $color-dark-bg-default-hover;
      }

      &[disabled] {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
</style>
