<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const connectionLineVariants = {
  variant: {
    neutral: 'connection-line--neutral',
    success: 'connection-line--success',
  },
  direction: {
    row: 'connection-line--row',
    column: 'connection-line--column',
  },
};

const classes = cva({ variants: connectionLineVariants });

export type ConnectionLineProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    variant?: ConnectionLineProps['variant'];
    direction?: ConnectionLineProps['direction'];
  }>(),
  {
    variant: 'neutral',
    direction: 'column',
  }
);
</script>

<template>
  <div class="connection-line" :class="classes({ variant, direction })">
    <span class="connection-line__notch" />
    <span class="connection-line__notch" />
    <span class="connection-line__notch" />
  </div>

  <div class="dot-flashing"></div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.connection-line {
  $self: &;

  display: flex;

  &__notch {
    border-radius: 2px;
    display: block;
  }

  &--neutral {
    #{$self}__notch {
      background-color: $color-bg-neutral;
    }
  }

  &--success {
    #{$self}__notch:nth-child(1) {
      background-color: $pine-500;
    }
    #{$self}__notch:nth-child(2) {
      background-color: $pine-400;
    }
    #{$self}__notch:nth-child(3) {
      background-color: $pine-300;
    }
  }

  &--row {
    flex-direction: row;

    #{$self}__notch {
      height: 2px;
      margin-right: $space-xxxsmall;
      width: $space-xxsmall;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &--column {
    flex-direction: column;

    #{$self}__notch {
      height: $space-xxsmall;
      margin-bottom: $space-xxxsmall;
      width: 2px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
