<template>
  <ul class="menu" role="menu" aria-labelledby="menubutton">
    <slot />
  </ul>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu {
  $self: &;

  list-style: none;
  margin: 0;
  padding: $space-xxsmall 0;
}
</style>
