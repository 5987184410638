<script lang="ts">
export type BaseFormFieldProps = {
  /**
   * Optional label for the input.
   */
  label?: string;
  /**
   * Is the input required?
   */
  required?: boolean;
  /**
   * Is the input invalid?
   */
  invalid?: boolean;
  /**
   * Invalid message via prop, alternatively use the #invalidMessage slot.
   */
  invalidMessage?: string;
  /**
   * Custom validator to be used in place for invalid prop.
   */
  validator?: () => boolean;
  /**
   * Note via prop, alternatively use the #note slot.
   */
  note?: string;
  /**
   * Is the input disabled?
   */
  disabled?: boolean;
  /**
   * Layout of the form field.
   */
  layout?: 'stacked' | 'inline';
};
</script>

<script setup lang="ts">
import { computed, ref, useAttrs, useSlots } from 'vue';
import { omit, uniqueId } from 'lodash-es';

import BaseProse from '../BaseProse/BaseProse.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<BaseFormFieldProps>(), {
  label: undefined,
  invalidMessage: undefined,
  validator: () => false,
  note: undefined,
  layout: 'stacked',
});

const slots = useSlots();
const attrs = useAttrs();

const field = ref<HTMLInputElement | null>(null);
const uid = ref(uniqueId('form-field-'));

const hasNote = computed(() => !!slots.note);

const formFieldId = computed(() =>
  typeof attrs.id === 'string' ? attrs.id : uid.value
);

const inputAttrs = computed(() => omit(attrs, ['class']));

const passthroughAttrs = computed(() => ({
  disabled: props.disabled,
  ...inputAttrs.value,
}));

const computedClasses = computed(() => {
  /*
   * Split the attribute class string into an array
   */
  const attrClassArray = attrs.class ? (attrs.class as string).split(' ') : [];

  /*
   * Convert the array into an object where each key is a class name and each value is true
   */
  const attrClassObject = attrClassArray.reduce((obj, className) => {
    return { ...obj, [className]: true };
  }, {});

  return {
    'form-field--invalid': props.invalid,
    'form-field--disabled': props.disabled,
    ...attrClassObject,
  };
});

const hasLabel = computed(() => !!props.label || !!slots.label);

const isInvalid = computed(() => {
  return props.invalid || props.validator();
});

const hasInvalidMessage = computed(() => {
  return (
    !!slots.invalidMessage ||
    (props.invalidMessage && props.invalidMessage !== ' ')
  );
});

const hasDescription = computed(() => {
  return (
    (isInvalid.value && hasInvalidMessage.value) ||
    (isInvalid.value && props.required) ||
    props.note ||
    !!hasNote.value
  );
});

const descriptionVariant = computed(() =>
  (isInvalid.value && hasDescription) || (isInvalid.value && props.required)
    ? 'danger'
    : 'secondary'
);

defineExpose({
  field,
});
</script>

<template>
  <BaseLayoutGap
    v-if="props.layout === 'stacked'"
    direction="column"
    size="xxxsmall"
    class="form-field"
    :class="computedClasses"
  >
    <label v-if="hasLabel" :for="formFieldId" class="form-field__label"
      ><slot name="label">{{ label }}</slot>
      <sup v-if="required" class="form-field__required">*</sup></label
    >
    <slot :form-field-id="formFieldId" :passthrough-attrs="passthroughAttrs" />

    <BaseProse v-if="hasDescription" :variant="descriptionVariant" size="small"
      ><template v-if="isInvalid && hasInvalidMessage">
        <slot name="invalidMessage">{{ invalidMessage }}</slot>
      </template>
      <template v-else-if="isInvalid && required"
        >This field is required</template
      >
      <template v-else>
        <slot name="note">{{ note }}</slot>
      </template></BaseProse
    >
  </BaseLayoutGap>
  <BaseLayoutGap
    v-if="props.layout === 'inline'"
    direction="column"
    alignment="top"
    size="xxxxsmall"
    class="form-field form-field--inline"
    :class="computedClasses"
  >
    <label v-if="hasLabel" :for="formFieldId" class="form-field__container"
      ><slot
        :form-field-id="formFieldId"
        :passthrough-attrs="passthroughAttrs"
      />
      <span class="form-field__label">
        <slot name="label">{{ label }}</slot>
        <sup v-if="required" class="form-field__required">*</sup></span
      ></label
    >
    <BaseProse
      v-if="hasDescription"
      class="form-field__body"
      :variant="descriptionVariant"
      size="small"
      ><template v-if="isInvalid && hasInvalidMessage">
        <slot name="invalidMessage">{{ invalidMessage }}</slot>
      </template>
      <template v-else-if="isInvalid && required"
        >This field is required</template
      >
      <template v-else>
        <slot name="note">{{ note }}</slot>
      </template></BaseProse
    >
  </BaseLayoutGap>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.form-field {
  $self: &;

  position: relative;
  width: 100%;

  &__label {
    color: $color-text-secondary;
    font-size: $font-size-small;
    line-height: $line-height-default;
    font-weight: $font-weight-regular;
  }

  &__required {
    color: $color-text-danger;
    line-height: 0.8;
  }

  &--invalid:not(#{$self}--disabled) {
    &:deep(input),
    &:deep(textarea) {
      border-color: $color-border-danger;
    }
  }

  &--disabled {
    &:deep(input),
    &:deep(textarea) {
      background-color: $color-bg-disabled;
      color: $color-text-disabled;

      &:hover,
      &:focus {
        border-color: $color-border-default;
        cursor: default;
      }
    }
  }

  &--inline {
    #{$self} {
      &__container {
        display: flex;
        direction: row;
        gap: $space-xxsmall;
        cursor: pointer;
        width: 100%;
      }

      &__label {
        width: 100%;
      }

      &__body {
        padding-left: $scale-xxsmall + $space-xxsmall;
        width: 100%;
      }
    }
  }
}
</style>
