import type { EndpointHistories, ProviderInstances } from './providers-worker';

export function createProvidersWorker() {
  const providersWorker = new Worker(
    new URL('./providers-worker.ts', import.meta.url),
    {
      type: 'module',
    }
  );

  return {
    postMessage(data?: EndpointHistories) {
      providersWorker.postMessage(data);
    },
    onMessage(callback: (data: ProviderInstances) => void) {
      providersWorker.onmessage = function (
        event: MessageEvent<ProviderInstances>
      ) {
        callback(event.data);
      };
    },
    terminate() {
      providersWorker.terminate();
    },
  };
}
