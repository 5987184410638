<script setup lang="ts">
import { format, formatDistanceToNow } from 'date-fns';

import type { FlowContent } from '../../types/content';

import AppPageDrawerHeading from '../AppPageDrawerHeading/AppPageDrawerHeading.vue';

import BaseHeading from '../BaseHeading/BaseHeading.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

import DashboardMetric from '../DashboardMetric/DashboardMetric.vue';

import { getAsset } from '../../utils';

const props = defineProps<{
  flow: FlowContent;
  installed?: boolean;
}>();

const emit = defineEmits<{
  applyRemediation: [remedy: FlowContent];
  removeRemediation: [remedy: FlowContent];
}>();

const now = new Date();

const onApplyRemediation = () => {
  emit('applyRemediation', props.flow);
};

const onRemoveRemediation = () => {
  emit('removeRemediation', props.flow);
};
</script>

<template>
  <div class="plugins-installation-flow">
    <AppPageDrawerHeading>{{ flow.name }}</AppPageDrawerHeading>

    <video
      :key="flow.videoUrl"
      class="plugins-installation-flow__video"
      controls
      autoplay
      muted
      loop
    >
      <source :src="getAsset(flow.videoUrl)" type="video/mp4" />
    </video>

    <template v-if="installed">
      <BaseLayoutGap direction="column" alignment="center" size="xlarge">
        <BaseProse size="small" alignment="center">{{
          flow.summary
        }}</BaseProse>
        <BaseLayoutGap alignment="top" style="width: 100%">
          <DashboardMetric
            icon="calendar-check-outline"
            title="Installed"
            style="width: 50%"
          >
            <BaseLayoutGap direction="column" alignment="center">
              <BaseHeading
                :level="3"
                variant="secondary"
                weight="semibold"
                no-margin
                style="text-align: center; margin-bottom: 6px"
                >{{
                  formatDistanceToNow(now.setMinutes(now.getMinutes() - 3), {
                    addSuffix: true,
                  })
                }}</BaseHeading
              >

              <BaseBadge>{{ format(now, 'MMM io, yyyy') }}</BaseBadge>
            </BaseLayoutGap>
          </DashboardMetric>

          <DashboardMetric
            icon="performance-increase-outline"
            title="API calls optimized"
            style="width: 50%"
          >
            <BaseLayoutGap direction="column" alignment="center">
              <BaseHeading
                :level="2"
                variant="secondary"
                weight="semibold"
                no-margin
                >78</BaseHeading
              >

              <BaseBadge variant="success-subtle"
                ><template #leftIcon><BaseIcon name="triangle-up" /></template
                >20%</BaseBadge
              >
            </BaseLayoutGap>
          </DashboardMetric>
        </BaseLayoutGap>
        <BaseButton variant="link-danger" @click="onRemoveRemediation"
          ><template #leftIcon><BaseIcon name="bin" /></template>Remove
          {{ flow.name }} flow</BaseButton
        >
      </BaseLayoutGap>
    </template>
    <template v-else>
      <BaseLayoutGap direction="column" size="large">
        <BaseLayoutGap direction="column" alignment="center">
          <BaseProse size="small" alignment="center">
            <template #default="{ markdownToHtml }">
              <div v-html="markdownToHtml(flow.summary)" />
            </template>
          </BaseProse>

          <BaseButton @click="onApplyRemediation">Apply flow</BaseButton>
        </BaseLayoutGap>
      </BaseLayoutGap>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use 'sass:math';
@use '../../assets/styles/utils' as *;

.plugins-installation-flow {
  $self: &;

  &__header {
    margin-bottom: $space-small;
  }

  &__title {
    margin-bottom: $space-large;
  }

  &__body {
    margin-bottom: $space-large;
  }

  &__video {
    border-radius: $space-xxsmall;
    width: 100%;
    margin-bottom: $space-large;
  }
}
</style>
