<script setup lang="ts">
import { computed } from 'vue';
import BaseIcon, { type IconProps } from '../BaseIcon/BaseIcon.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

const props = defineProps<{
  icon?: IconProps['name'];
  compact?: boolean;
}>();

const computedClasses = computed(() => ({
  'block-header--compact': props.compact,
}));
</script>

<template>
  <header class="block-header" :class="computedClasses">
    <BaseLayoutGap class="block-header__container" alignment="space-between">
      <BaseLayoutIcons
        ><template v-if="icon" #leftIcon><BaseIcon :name="icon" /></template
        ><slot name="title"
      /></BaseLayoutIcons>
      <slot />
    </BaseLayoutGap>
  </header>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.block-header {
  color: $color-text-secondary;
  padding: $space-small $space-large;
  width: 100%;

  &__container {
    min-height: $space-large + $space-xxxxsmall;
  }

  &--compact {
    padding: $space-xsmall $space-large;
  }
}
</style>
