import { createRouter, createWebHistory } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';
import DashboardView from '../views/DashboardView.vue';
import ControlPaneView from '../views/ControlPaneView.vue';
import {
  authGuard,
  hasDevToolAccessGuard,
  hasFeatureFlagGuard,
} from './guards';
import { FeatureFlag } from '../types';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '',
      beforeEnter: [authGuard()],
      children: [
        {
          path: '/',
          component: ControlPaneView,
          children: [
            {
              path: '',
              name: 'dashboard',
              component: DashboardView,
            },
            {
              path: 'provider/:hostName',
              name: 'provider',
              component: () => import('../views/ProviderView.vue'),
              beforeEnter: hasFeatureFlagGuard(FeatureFlag.ApiProviderView),
            },
            {
              path: 'flows',
              component: () => import('../views/PluginsView.vue'),
              children: [
                {
                  path: '',
                  name: 'flows',
                  component: () =>
                    import(
                      '../components/PluginsCatalogPolicies/PluginsCatalogPolicies.vue'
                    ),
                },
                {
                  path: 'plugins',
                  name: 'plugins',
                  component: () =>
                    import(
                      '../components/PluginsCatalogPlugins/PluginsCatalogPlugins.vue'
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/settings',
      name: 'settings',
      beforeEnter: [authGuard({ playgroundAccessible: false })],
      children: [
        {
          path: 'account',
          name: 'account',
          component: () => import('../views/AccountView.vue'),
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('../views/ProfileView.vue'),
        },
        {
          path: 'organization',
          name: 'organization',
          component: () => import('../views/OrganizationView.vue'),
        },
        {
          path: 'dev-tools',
          name: 'dev-tools',
          component: () => import('../views/DevToolsView.vue'),
          beforeEnter: hasDevToolAccessGuard,
        },
      ],
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => {},
      beforeEnter: () => {
        const { logout } = useAuth0();
        logout({
          openUrl() {
            window.location.replace(import.meta.env.VITE_BASE_URL);
          },
        });

        return false;
      },
    },
    {
      path: '/:catchAll(.*)*',
      name: 'not-found',
      redirect: { name: 'dashboard' },
    },
  ],
});

export default router;
