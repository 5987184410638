<template>
  <footer class="block-footer">
    <slot />
  </footer>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.block-footer {
  padding: $space-xxxsmall $space-large;
  width: 100%;

  &:not(:empty) {
    padding: $space-xsmall $space-large;
  }
}
</style>
