import type { ProxyContent } from '../../../../types/content';

const content: ProxyContent = {
  name: 'Docker',
  steps: [
    {
      title: "Run Lunar's gateway container",
      details: `
\`\`\`powershell
docker run -d --rm -p 8000:8000 -p 8081:8081 -p 8040:8040 -e TENANT_NAME=#TENANT_NAME# -e LUNAR_API_KEY=#API_KEY# -v $(pwd):/etc/lunar-proxy --name lunar-proxy lunarapi/lunar-proxy:latest
\`\`\`

Note that the TENANT_NAME environment variable is required. This variable should be set to the name of your organization.
`,
    },
  ],
};

export default content;
