<script setup lang="ts">
import { computed } from 'vue';
import {
  emptyStatusCodeGroups,
  type StatusCodeGroups,
} from '@monorepo/shared-model/src/aggregated-discovery';

const props = withDefaults(
  defineProps<{
    statusCodes?: StatusCodeGroups;
  }>(),
  {
    statusCodes: () => emptyStatusCodeGroups(),
  }
);

const totalCalls = computed(() => {
  return Object.values(props.statusCodes).reduce((acc, value) => {
    return acc + value.total;
  }, 0);
});

const status200Percentage = computed(() => {
  return calculatePercentage(props.statusCodes['2xx'].total, totalCalls.value);
});

const status400Percentage = computed(() => {
  return calculatePercentage(props.statusCodes['4xx'].total, totalCalls.value);
});

const status500Percentage = computed(() => {
  return calculatePercentage(props.statusCodes['5xx'].total, totalCalls.value);
});

const otherPercentage = computed(() => {
  const otherTotal =
    props.statusCodes['1xx'].total +
    props.statusCodes['3xx'].total +
    props.statusCodes.irregular.total;

  return calculatePercentage(otherTotal, totalCalls.value);
});

function calculatePercentage(number: number, total: number) {
  if (total === 0) {
    return 0;
  }
  return parseFloat(((number / total) * 100).toFixed(2));
}
</script>

<template>
  <ul class="status-chart-legend">
    <li class="status-chart-legend__item status-chart-legend__item--200">
      <span>2xx / {{ status200Percentage }}%</span>
    </li>
    <li class="status-chart-legend__item status-chart-legend__item--400">
      <span>4xx / {{ status400Percentage }}%</span>
    </li>
    <li class="status-chart-legend__item status-chart-legend__item--500">
      <span>5xx / {{ status500Percentage }}%</span>
    </li>
    <li class="status-chart-legend__item status-chart-legend__item--other">
      <span>Other / {{ otherPercentage }}%</span>
    </li>
  </ul>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.status-chart-legend {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  &__item {
    align-items: center;
    color: $color-text-secondary;
    display: flex;
    font-size: $font-size-xsmall;
    line-height: $line-height-default;
    margin-bottom: 0;
    position: relative;
    text-wrap: nowrap;

    &::before {
      background-color: $color-bg-neutral;
      border-radius: 100%;
      display: block;
      content: '';
      height: $scale-xxxxsmall;
      margin-right: $space-xxsmall;
      width: $scale-xxxxsmall;
    }

    &--200::before {
      background-color: $color-bg-success;
    }

    &--400::before {
      background-color: $color-bg-warning;
    }

    &--500::before {
      background-color: $color-bg-danger;
    }

    &--other::before {
      background-color: $color-bg-neutral;
    }
  }
}
</style>
