<script setup lang="ts">
import { computed } from 'vue';
import { escapeRegExp } from 'lodash-es';

const props = withDefaults(
  defineProps<{
    string: string;
    query?: string;
    wrapper?: string;
  }>(),
  {
    query: undefined,
    wrapper: 'span',
  }
);

const parsedString = computed(() => {
  if (!props.query) {
    return [props.string];
  }

  // Escape match string for RegExp usage i.e. () becomes \(\)
  const parsed = `(${escapeRegExp(props.query)})`;
  return props.string.split(new RegExp(parsed, 'gi'));
});

function isHighlighted(text: string): boolean {
  return !!props.query && text.toLowerCase() === props.query.toLowerCase();
}
</script>

<template>
  <component :is="wrapper" class="highlight">
    <template v-for="(text, i) in parsedString">
      <mark
        v-if="isHighlighted(text)"
        :key="`highlight-${i}`"
        class="highlight__text"
        >{{ text }}</mark
      >
      <span v-else :key="i">{{ text }}</span>
    </template>
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.highlight {
  $self: &;

  &__text {
    background-color: $color-bg-warning-subtle;
    color: $color-text-warning;
    font-weight: $font-weight-semibold;
  }
}
</style>
