<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const statusIndicatorVariants = {
  variant: {
    neutral: 'status-indicator--neutral',
    success: 'status-indicator--success',
    info: 'status-indicator--info',
    warning: 'status-indicator--warning',
    danger: 'status-indicator--danger',
  },
};

const classes = cva({ variants: statusIndicatorVariants });

export type StatusIndicatorVariants = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    variant?: StatusIndicatorVariants['variant'];
  }>(),
  {
    variant: 'neutral',
  }
);
</script>

<template>
  <div class="status-indicator" :class="classes({ variant })" />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.status-indicator {
  $self: &;

  border-radius: 100%;
  height: $scale-xxxxsmall;
  width: $scale-xxxxsmall;

  &--neutral {
    background-color: $color-bg-neutral;
  }

  &--success {
    background-color: $color-bg-success;
  }

  &--info {
    background-color: $color-bg-info;
  }

  &--warning {
    background-color: $color-bg-warning;
  }

  &--danger {
    background-color: $color-bg-danger;
  }
}
</style>
