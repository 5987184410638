import { type PolicyContent, RemedyType } from '../../../types/content';
import retry from '../plugin/retry';
import strategyBasedQueue from '../plugin/strategy-based-queue';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Payments API Fallback',
  comingSoon: true,
  summary:
    'Handles intermittent failures in payments API requests with automatic retries and prioritized queuing.',
  plugins: [retry, strategyBasedQueue],
  docsUrl: undefined,
  details: `
Utilizing the Retry plugin, this policy automatically retries failed payments API requests, with Strategy-Based Queue prioritizing them in the queue, ensuring timely processing and minimizing disruptions in payment processing.
`,
};

export default content;
