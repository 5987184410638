import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { clone, cloneDeep } from 'lodash-es';
import { useUserStore } from '../user';
import { useSocketStore, type MappedPathPartsRequest } from '../socket';
import {
  mockConsumers,
  mockConsumersProvidersLookup,
  mockGateways,
  mockInterceptors,
  mockProvidersGroups,
  mockUrlTree,
} from './mocks';
import type { PrimitiveProvider, DemoEndpoint } from './types';
import { calculateChangeRatio } from '../../utils';
import type { RemedyContent } from '../../types/content';
import { createEndpointsWorker } from './endpoints-worker-wrapper';
import { createProvidersWorker } from './providers-worker-wrapper';
import type { ProviderInstances } from './providers-worker';
import {
  getTotalCount,
  getTotalEndpointCount,
  getTotalErrorRate,
  getTotalRuntimeMsAvg,
  getTotalStatusCodes,
} from './utils';
import {
  PathPartKindDAO,
  type DeclaredOrAssumedPathPartDAO,
  type PrimitiveUrlTreeDAO,
  type PrimitiveUrlTreeProviderDAO,
} from '@monorepo/shared-model/src/url-tree';
import type { ToRaw } from '@monorepo/toolkit-core/types';
import type { MappedPathPart } from '@prisma/client';
import { rand } from '@vueuse/core';
import type { GetAggregatedDiscoveryPayload } from '@monorepo/shared-model/src/webserver-events';

export const useDemoStore = defineStore('demo', () => {
  const userStore = useUserStore();
  const socketStore = useSocketStore();

  const forceDemoMode = ref(false);
  const isDemoConnected = ref(false);
  const isLoading = ref(true);
  const updateInterval = ref(15000);
  const selectedMockProviderGroups = ref(
    Array.from(
      { length: Object.keys(mockProvidersGroups).length },
      (_, index) => index
    )
  );
  let interval: NodeJS.Timeout | undefined = undefined;
  const cachedSocketStoreState = ref(clone(socketStore.$state));

  const endpointsHistory = ref<DemoEndpoint[]>([]);
  const endpointsWorker = createEndpointsWorker();

  const mutableMockUrlTree = ref<PrimitiveUrlTreeDAO>(cloneDeep(mockUrlTree));

  endpointsWorker.onMessage((endpoints) => {
    endpointsHistory.value.push(...endpoints);

    // Whenever endpointsHistory is updated, compute the provider instances.
    computeProviderInstances();
  });

  const instances = ref<PrimitiveProvider[]>([]);
  const previousInstances = ref<PrimitiveProvider[]>([]);
  const providersWorker = createProvidersWorker();

  providersWorker.onMessage((data: ProviderInstances) => {
    instances.value = data.instances;
    previousInstances.value = data.previousInstances;

    updateSocketStore();

    isLoading.value = false;
  });

  const computeProviderInstances = () => {
    const data = {
      endpointHistory: filteredEndpointsHistory.value,
      previousEndpointHistory: previousFilteredEndpointsHistory.value,
    };

    // Data needs to be parsed and stringified to avoid a data clone error within the worker.
    providersWorker.postMessage(JSON.parse(JSON.stringify(data)));
  };

  const isDemoMode = computed<boolean>(
    () =>
      forceDemoMode.value ||
      (userStore.user.user_metadata?.dev_mode?.demo_mode &&
        (userStore.permissions.canAccessDevTools ||
          userStore.permissions.isPlayground))
  );

  const traffic = computed(() => {
    return {
      ...trafficTotals.value,
      statusCodes: getTotalStatusCodes(instances.value),
      providers: {
        count: instances.value.length,
        endpointCount: getTotalEndpointCount(instances.value),
        instances: preparedInstances(instances.value),
      },
    };
  });

  const trafficTotals = computed(() => {
    const countCurrent = getTotalCount(instances.value);
    const errorRateCurrent = getTotalErrorRate(instances.value);
    const runtimeMsAvgCurrent = getTotalRuntimeMsAvg(instances.value);
    const countPrevious = getTotalCount(previousInstances.value);
    const errorRatePrevious = getTotalErrorRate(previousInstances.value);
    const runtimeMsAvgPrevious = getTotalRuntimeMsAvg(previousInstances.value);

    return {
      count: {
        current: countCurrent,
        previous: countPrevious,
        changeRatio: calculateChangeRatio(countCurrent, countPrevious),
      },
      errorRate: {
        current: errorRateCurrent,
        previous: errorRatePrevious,
        changeRatio: calculateChangeRatio(errorRateCurrent, errorRatePrevious),
      },
      runtimeMs: {
        avg: {
          current: runtimeMsAvgCurrent,
          previous: runtimeMsAvgPrevious,
          changeRatio: calculateChangeRatio(
            runtimeMsAvgCurrent,
            runtimeMsAvgPrevious
          ),
        },
      },
    };
  });

  const sortedEndpointsHistory = computed(() => {
    return endpointsHistory.value.sort(
      (a, b) => new Date(b.maxTime).getTime() - new Date(a.maxTime).getTime()
    );
  });

  const filteredEndpointsHistory = computed(() => {
    const dateRange = socketStore.filters.dateRange;
    const filteredConsumerTags = socketStore.filters.filteredConsumerTags;

    return sortedEndpointsHistory.value.filter((endpoint) => {
      const maxTime = new Date(endpoint.maxTime).getTime();

      // Filter by consumer tags
      if (filteredConsumerTags.length) {
        const matchesConsumerTag = filteredConsumerTags.some((tag) => {
          const providers = mockConsumersProvidersLookup[tag];
          return providers && providers.includes(endpoint.demoProvider);
        });

        if (!matchesConsumerTag) {
          return false;
        }
      }

      // Filter by date range
      if (dateRange.dateRangeType === 'absolute') {
        const startDate = new Date(dateRange.startDate);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(dateRange.endDate);
        endDate.setHours(23, 59, 59, 999);

        return maxTime >= startDate.getTime() && maxTime <= endDate.getTime();
      } else {
        const now = new Date().getTime();
        const startDate = new Date(now);
        const endDate = new Date(now);

        if (dateRange.unit === 'month') {
          startDate.setMonth(startDate.getMonth() - dateRange.length);
        } else if (dateRange.unit === 'week') {
          startDate.setDate(startDate.getDate() - dateRange.length * 7);
        } else if (dateRange.unit === 'day') {
          startDate.setDate(startDate.getDate() - dateRange.length);
        } else if (dateRange.unit === 'hour') {
          startDate.setHours(startDate.getHours() - dateRange.length);
        } else if (dateRange.unit === 'minute') {
          startDate.setMinutes(startDate.getMinutes() - dateRange.length);
        }

        return maxTime >= startDate.getTime() && maxTime <= endDate.getTime();
      }
    });
  });

  const previousFilteredEndpointsHistory = computed(() => {
    const dateRange = socketStore.filters.dateRange;

    return sortedEndpointsHistory.value.filter((endpoint) => {
      const maxTime = new Date(endpoint.maxTime).getTime();

      if (dateRange.dateRangeType === 'absolute') {
        const startDate = new Date(dateRange.startDate);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(dateRange.endDate);
        endDate.setHours(23, 59, 59, 999);

        const delta = endDate.getTime() - startDate.getTime();

        return (
          maxTime <= startDate.getTime() - 1 &&
          maxTime >= new Date(startDate.getTime() - delta).getTime()
        );
      } else {
        const now = new Date();

        if (dateRange.unit === 'month') {
          now.setMonth(now.getMonth() - dateRange.length);
        } else if (dateRange.unit === 'week') {
          now.setDate(now.getDate() - dateRange.length * 7);
        } else if (dateRange.unit === 'day') {
          now.setDate(now.getDate() - dateRange.length);
        } else if (dateRange.unit === 'hour') {
          now.setHours(now.getHours() - dateRange.length);
        } else if (dateRange.unit === 'minute') {
          now.setMinutes(now.getMinutes() - dateRange.length);
        }

        const startDate = new Date(now.getTime());
        const endDate = new Date(now.getTime());

        if (dateRange.unit === 'month') {
          startDate.setMonth(startDate.getMonth() - dateRange.length);
        } else if (dateRange.unit === 'week') {
          startDate.setDate(startDate.getDate() - dateRange.length * 7);
        } else if (dateRange.unit === 'day') {
          startDate.setDate(startDate.getDate() - dateRange.length);
        } else if (dateRange.unit === 'hour') {
          startDate.setHours(startDate.getHours() - dateRange.length);
        } else if (dateRange.unit === 'minute') {
          startDate.setMinutes(startDate.getMinutes() - dateRange.length);
        }

        return maxTime >= startDate.getTime() && maxTime <= endDate.getTime();
      }
    });
  });

  function preparedInstances(instances: PrimitiveProvider[]) {
    return instances.map((instance) => {
      return {
        ...instance,
        endpoints: instance.endpoints.map((endpoint) => {
          return {
            ...endpoint,
            path: getDisplayPath(instance.host, endpoint.path),
          };
        }),
      };
    });
  }

  function getDisplayPath(host: string, path: string) {
    if (
      mutableMockUrlTree.value.providers[host] &&
      (mutableMockUrlTree.value.providers[host].declared[path] ||
        mutableMockUrlTree.value.providers[host].underdeclared[path])
    ) {
      return getPathFromUrlTree(host, path);
    }

    return path;
  }

  function getPathFromUrlTree(host: string, path: string) {
    const urlTree: ToRaw<DeclaredOrAssumedPathPartDAO>[] =
      mutableMockUrlTree.value.providers[host].declared[path] ||
      mutableMockUrlTree.value.providers[host].underdeclared[path];

    const sortedUrlTree = urlTree.sort((a, b) => a.index - b.index);

    return (
      '/' +
      sortedUrlTree
        .map((part) => {
          if (part.kind === PathPartKindDAO.Mapped) {
            return `{${part.mapping.value}}`;
          } else if (part.kind === PathPartKindDAO.Assumed) {
            return `{${part.value}}`;
          }

          return part.value;
        })
        .join('/')
    );
  }

  function applyRemediation(
    provider: PrimitiveProvider,
    remedy: RemedyContent
  ) {
    const historyIndex = endpointsHistory.value.findIndex(
      (endpoint) =>
        endpoint.method === provider.endpoints[0].method &&
        endpoint.path === provider.endpoints[0].path &&
        endpoint.maxTime === provider.endpoints[0].maxTime
    );

    endpointsHistory.value[historyIndex].remedies?.push(remedy);

    endpointsWorker.postMessage();
  }

  function removeRemediation(
    provider: PrimitiveProvider,
    remedy: RemedyContent
  ) {
    const historyIndex = endpointsHistory.value.findIndex(
      (endpoint) =>
        endpoint.method === provider.endpoints[0].method &&
        endpoint.path === provider.endpoints[0].path &&
        endpoint.maxTime === provider.endpoints[0].maxTime
    );

    const endpoint = endpointsHistory.value[historyIndex];
    const remedyIndex = endpoint.remedies?.findIndex(
      (r) => r.name === remedy.name
    );

    if (remedyIndex !== undefined && remedyIndex !== -1) {
      endpoint.remedies?.splice(remedyIndex, 1);
    }

    endpointsWorker.postMessage();
  }

  function setConnected() {
    socketStore.isConnected = true;
  }

  function setProxies() {
    socketStore.isProxyInstalled = true;

    if (traffic.value.count.current === 0) {
      return (socketStore.proxies = {
        instances: [],
        lastTransactionDate: undefined,
      });
    }

    socketStore.proxies = mockGateways;
  }

  function setInterceptors() {
    socketStore.isInterceptorInstalled = true;

    if (traffic.value.count.current === 0) {
      socketStore.interceptors = {
        instances: [],
        lastTransactionDate: undefined,
      };

      return;
    }

    socketStore.interceptors = mockInterceptors;
  }

  function setConsumers() {
    socketStore.consumers = mockConsumers;
  }

  function updateSocketStore() {
    setConnected();
    setProxies();
    setInterceptors();
    setConsumers();
    socketStore.traffic = traffic.value;
  }

  function listen() {
    clearInterval(interval);

    interval = setInterval(async () => {
      endpointsWorker.postMessage();
    }, updateInterval.value);
  }

  function reset() {
    clearInterval(interval);
    socketStore.$state = cachedSocketStoreState.value;
  }

  function connect() {
    isDemoConnected.value = true;
    reset();

    endpointsWorker.postMessage({ historic: true });

    listen();
  }

  function disconnect() {
    clearInterval(interval);
    isDemoConnected.value = false;

    reset();
  }

  function getAggregatedDiscovery(
    payload: GetAggregatedDiscoveryPayload | undefined = undefined
  ) {
    return socketStore.getAggregatedDiscovery(payload);
  }

  async function getUrlTreeProvider(
    host: string
  ): Promise<PrimitiveUrlTreeProviderDAO | undefined> {
    return mutableMockUrlTree.value.providers[host];
  }

  async function getUrlTree(): Promise<PrimitiveUrlTreeDAO> {
    return mutableMockUrlTree.value;
  }

  async function createOrUpdateMappedPathParts(
    payload: MappedPathPartsRequest
  ): Promise<MappedPathPart[] | undefined> {
    const data: MappedPathPart[] = [];
    const errors: number[] = [];

    for (const id in payload) {
      const { kind, value } = payload[id];

      if (kind === PathPartKindDAO.Assumed) {
        const result = await createMappedPathPart(Number(id), value);

        if (result) {
          data.push(result);
        } else {
          errors.push(Number(id));
        }
      } else if (kind === PathPartKindDAO.Mapped) {
        const result = await updateMappedPathPart(Number(id), value);

        if (result) {
          data.push(result);
        } else {
          errors.push(Number(id));
        }
      } else {
        errors.push(Number(id));
      }
    }

    return errors.length ? undefined : data;
  }

  async function createMappedPathPart(
    id: number,
    value: string
  ): Promise<MappedPathPart | undefined> {
    const error = ref(undefined);
    const data = ref<MappedPathPart>();

    // Traverse the declared paths to find and update the mapping value
    const traverseAndCreate = (urlTree: PrimitiveUrlTreeDAO) => {
      for (const provider in urlTree.providers) {
        if (urlTree.providers[provider]) {
          const paths = urlTree.providers[provider].underdeclared;
          for (const path in paths) {
            if (paths[path]) {
              const randomId = rand(1, 1000);

              // Path set to any as I'm forcibly changing mocked data from assumed to mapped and TypeScript is not happy.
              paths[path].forEach((part: any) => {
                if (part.kind === PathPartKindDAO.Assumed && part.id === id) {
                  part.kind = PathPartKindDAO.Mapped;
                  part.mapping = {
                    id: id + randomId,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    value,
                  };

                  data.value = {
                    id: part.id,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    pathPartId: id + randomId,
                    value: value,
                  };
                }
              });
            }
          }
        }
      }
    };

    try {
      traverseAndCreate(mutableMockUrlTree.value);
    } catch (e: any) {
      error.value = e;
    }

    return !error.value && data.value;
  }

  async function updateMappedPathPart(
    id: number,
    value: string
  ): Promise<MappedPathPart | undefined> {
    const error = ref(undefined);
    const data = ref<MappedPathPart>();

    // Traverse the declared paths to find and update the mapping value
    const traverseAndUpdate = (urlTree: PrimitiveUrlTreeDAO) => {
      for (const provider in urlTree.providers) {
        if (urlTree.providers[provider]) {
          const paths = {
            ...urlTree.providers[provider].declared,
            ...urlTree.providers[provider].underdeclared,
          };
          for (const path in paths) {
            if (paths[path]) {
              paths[path].forEach((part) => {
                if (part.kind === PathPartKindDAO.Mapped && part.mapping) {
                  part.mapping.value = value;

                  data.value = {
                    id,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    pathPartId: part.mapping.id,
                    value: value,
                  };
                }
              });
            }
          }
        }
      }
    };

    try {
      traverseAndUpdate(mutableMockUrlTree.value);
    } catch (e: any) {
      error.value = e;
    }

    return !error.value && data.value;
  }

  // If computed traffic changes then update the socket store.
  watch(
    () => traffic.value,
    () => {
      updateSocketStore();
    }
  );

  // Watch for filter changes and recompute the provider instances.
  watch(
    () => socketStore.filters,
    () => {
      computeProviderInstances();
    }
  );

  // Watch for updateInterval and selectedMockProviderGroups changes and restart the demo store interval.
  watch(
    [() => updateInterval.value, () => selectedMockProviderGroups.value],
    (
      [newUpdateInterval, newSelectedMockProviderGroups],
      [oldUpdateInterval, oldSelectedMockProviderGroups]
    ) => {
      if (
        !isDemoMode.value ||
        (newUpdateInterval === oldUpdateInterval &&
          newSelectedMockProviderGroups === oldSelectedMockProviderGroups)
      )
        return;

      listen();
    }
  );

  // Watch for changes to the user's demo mode setting and connect/disconnect the demo store accordingly.
  watch(
    () => userStore.user.user_metadata?.dev_mode?.demo_mode,
    async () => {
      if (
        userStore.isUserInitialized &&
        userStore.token &&
        isDemoMode.value &&
        !isDemoConnected.value
      ) {
        socketStore.disconnect();

        connect();
      } else if (userStore.isUserInitialized && userStore.token) {
        disconnect();

        socketStore.connect(userStore.token.id_token);
      }
    }
  );

  // Watch for changes to the user's demo update interval setting and update the demo store accordingly.
  watch(
    () => userStore.user.user_metadata?.dev_mode,
    () => {
      updateInterval.value =
        userStore.user.user_metadata?.dev_mode?.demo_update_interval;
      selectedMockProviderGroups.value =
        userStore.user.user_metadata?.dev_mode?.demo_providers;
    }
  );

  return {
    forceDemoMode,
    isDemoMode,
    isDemoConnected,
    isLoading,
    updateInterval,
    getUrlTree,
    getUrlTreeProvider,
    createOrUpdateMappedPathParts,
    applyRemediation,
    removeRemediation,
    connect,
    disconnect,
    getAggregatedDiscovery,
  };
});
