import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePageDrawer = defineStore('pageDrawer', () => {
  const openInstance = ref<string | undefined>();

  return {
    openInstance,
  };
});
