import { type PolicyContent, RemedyType } from '../../../types/content';
import llmOrchestration from '../plugin/llm-orchestration';
import strategyBasedThrottling from '../plugin/strategy-based-throttling';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'LLMs API Orchestration',
  comingSoon: true,
  summary:
    'Orchestrates API requests across multiple accounts to balance loads and enforces throttling for efficient API management.',
  plugins: [llmOrchestration, strategyBasedThrottling],
  docsUrl: undefined,
  details: `
This policy leverages LLMs Orchestration to distribute API requests across multiple LLMs, preventing overload on any single account, and implements throttling to regulate request rates, ensuring efficient API management and compliance with API limits.
`,
};

export default content;
