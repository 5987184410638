<script lang="ts">
import { cva, type VariantProps } from 'cva';

export type ColorfulIcon = {
  name: string;
  hosts?: string[];
};

export const colorfulIcons: ColorfulIcon[] = [
  {
    name: 'Python',
    hosts: ['python.org'],
  },
  {
    name: 'Docker',
    hosts: ['docker.com'],
  },
  {
    name: 'JavaScript',
    hosts: ['javascript.com'],
  },
  {
    name: 'Java',
    hosts: ['java.com'],
  },
  {
    name: 'Slack',
    hosts: ['slack.com'],
  },
  {
    name: 'Aws',
    hosts: ['aws.amazon.com'],
  },
  {
    name: 'Github',
    hosts: ['github.com'],
  },
  {
    name: 'OpenAi',
    hosts: ['openai.com'],
  },
  {
    name: 'Stripe',
    hosts: ['stripe.com'],
  },
  {
    name: 'Twilio',
    hosts: ['twilio.com'],
  },
  {
    name: 'DataDog',
    hosts: ['datadoghq.com'],
  },
  {
    name: 'SendGrid',
    hosts: ['sendgrid.com'],
  },
  {
    name: 'Generic',
    hosts: undefined,
  },
  {
    name: 'DirectTraffic',
    hosts: undefined,
  },
  {
    name: 'Unknown',
    hosts: undefined,
  },
];

export const colorfulIconVariants = {
  name: colorfulIcons.reduce(
    (obj: Record<string, string>, icon: ColorfulIcon) => {
      obj[icon.name.toLowerCase()] = icon.name;
      return obj;
    },
    {}
  ),
};

const classes = cva({ variants: colorfulIconVariants });

export type ColorfulIconProps = VariantProps<typeof classes> & { name: string };
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  /**
   * The name of the icon.
   */
  name: ColorfulIconProps['name'];
  /**
   * An aria-label is automatically applied to the icon based on the icon name. You can override this using the label prop.
   */
  label?: string;
}>();

const computedAriaLabel = computed(() => {
  const name = props.name
    .split('-')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return props.label ? props.label : `${name} icon`;
});
</script>

<template>
  <span class="colorful-icon">
    <svg
      class="colorful-icon__img"
      role="img"
      :aria-label="computedAriaLabel"
      height="1em"
      width="1em"
    >
      <use
        :xlink:href="`#${colorfulIconVariants.name[props.name]}`"
        :href="`#${colorfulIconVariants.name[props.name]}`"
      />
    </svg>
  </span>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.colorful-icon {
  align-items: center;
  color: inherit;
  display: inline-flex;
  height: 1em;
  justify-content: center;
  line-height: inherit;
  width: 1em;

  &::before {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    text-rendering: auto;
  }
}
</style>
