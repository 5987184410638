import {
  StatusCodeGroup,
  emptyStatusCodeGroups,
  type StatusCodeGroups,
} from '@monorepo/shared-model/src/aggregated-discovery';
import type { PrimitiveEndpoint, PrimitiveProvider } from './types';

export function getProviderCallCount(endpoints: PrimitiveEndpoint[]) {
  return endpoints.reduce((acc, endpoint) => acc + endpoint.count, 0);
}

export function getProviderErrorRate(endpoints: PrimitiveEndpoint[]) {
  const totalErrorCount = endpoints.reduce(
    (acc, endpoint) => {
      return (
        acc +
        endpoint.statusCodes['4xx'].total +
        endpoint.statusCodes['5xx'].total
      );
    },

    0
  );

  const totalCallCount = endpoints.reduce(
    (acc, endpoint) => acc + endpoint.count,
    0
  );

  return totalErrorCount / totalCallCount;
}

export function getProviderRuntimeMsAvg(endpoints: PrimitiveEndpoint[]) {
  let totalRuntime = 0;

  endpoints.forEach((endpoint) => {
    totalRuntime += endpoint.runtimeMs.avg;
  });

  return endpoints.length > 0 ? totalRuntime / endpoints.length : 0;
}

export function getTotalCount(instances: PrimitiveProvider[] = []) {
  return instances.reduce((totalCount, instance) => {
    return instance.endpoints.reduce(
      (acc, endpoint) => acc + endpoint.count,
      totalCount
    );
  }, 0);
}

export function getTotalErrorRate(instances: PrimitiveProvider[] = []) {
  if (!instances.length) return 0;

  const totalErrorRate = instances.reduce((acc, instance) => {
    const instanceErrorRate = getProviderErrorRate(instance.endpoints);
    return acc + instanceErrorRate;
  }, 0);

  const averageErrorRate = totalErrorRate / instances.length;

  return averageErrorRate;
}

export function getTotalRuntimeMsAvg(instances: PrimitiveProvider[] = []) {
  const totalRuntime = instances.reduce((acc, instance) => {
    return acc + instance.runtimeMs.avg.current;
  }, 0);
  return instances.length > 0 ? totalRuntime / instances.length : 0;
}

export function getTotalStatusCodes(instances: PrimitiveProvider[] = []) {
  const totalStatusCodes: StatusCodeGroups = emptyStatusCodeGroups();

  instances.forEach((instance) => {
    instance.endpoints.forEach((endpoint) => {
      Object.keys(endpoint.statusCodes).forEach((key) => {
        const groupKey = key as keyof typeof StatusCodeGroup;

        totalStatusCodes[groupKey].total +=
          endpoint.statusCodes[groupKey].total;
        Object.keys(endpoint.statusCodes[groupKey].codes).forEach((code) => {
          if (totalStatusCodes[groupKey].codes[code]) {
            totalStatusCodes[groupKey].codes[code] +=
              endpoint.statusCodes[groupKey].codes[code];
          } else {
            totalStatusCodes[groupKey].codes[code] =
              endpoint.statusCodes[groupKey].codes[code];
          }
        });
      });
    });
  });

  return totalStatusCodes;
}

export function getTotalEndpointCount(instances: PrimitiveProvider[] = []) {
  return instances.reduce(
    (total, provider) => total + provider.endpointCount.current,
    0
  );
}
