<script setup lang="ts">
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import { computed } from 'vue';

const props = defineProps<{
  navigate?: boolean;
}>();

const open = defineModel();

const computedClasses = computed(() => ({
  'accordion--open': open.value,
  'accordion--navigate': props.navigate,
}));

const toggleOpen = () => {
  open.value = !open.value;
};

const onAccordionClick = () => {
  if (props.navigate) return;
  toggleOpen();
};

const onExpandClick = (e: Event) => {
  if (!props.navigate) return;

  e.stopPropagation();

  toggleOpen();
};
</script>

<template>
  <div class="accordion" :class="computedClasses" @click="onAccordionClick">
    <BaseLayoutGap
      class="accordion__header"
      alignment="space-between"
      size="medium"
    >
      <BaseLayoutGap class="accordion__header" size="small">
        <BaseIcon
          class="accordion__expand"
          name="chevron-down"
          @click="onExpandClick"
        />

        <slot name="heading"></slot>

        <BaseIcon
          v-if="navigate"
          class="accordion__navigate"
          name="chevron-right"
        />
      </BaseLayoutGap>
    </BaseLayoutGap>
    <div class="accordion__body">
      <div class="accordion__container">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.accordion {
  $self: &;

  border-bottom: 1px solid $color-border-default;

  &__header {
    cursor: pointer;
    padding: $space-xxsmall $space-xxsmall;
    width: 100%;

    &:hover {
      background-color: $color-bg-surface;
    }
  }

  &__navigate {
    margin-left: auto;
  }

  &__body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
    position: relative;

    &::before {
      border-top: 1px solid $color-border-default;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__container {
    padding: $space-small 0 $space-small $space-xlarge;
  }

  &--open {
    #{$self}__body {
      max-height: 1500px;
      transition: max-height 0.5s ease-in-out;
    }
    #{$self}__expand {
      transform: rotate(180deg);
    }
  }

  &--navigate {
    #{$self}__expand {
      &:hover {
        background-color: $color-bg-surface-hover;
        color: $color-text-brand;
        border-radius: $space-xxxxsmall;
      }
    }
  }
}
</style>
