<script setup lang="ts">
import { computed } from 'vue';
import type { PlaygroundVisitor } from '../../stores/demo/types';

import BaseButton from '../BaseButton/BaseButton.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';

const props = defineProps<{
  playgroundVisitor?: PlaygroundVisitor;
  compact?: boolean;
}>();

const computedClasses = computed(() => ({
  'app-schedule-call--compact': props.compact,
}));

const onBookDemo = () => {
  if (!props.playgroundVisitor) return;

  window.open(props.playgroundVisitor.redirectUri, '_blank');
};
</script>

<template>
  <div class="app-schedule-call" :class="computedClasses">
    <BaseTooltip
      v-if="compact"
      class="app-schedule-call__tooltip"
      content="Schedule a call"
      placement="right"
      :disabled="!compact"
      @click.stop
    >
      <BaseButton
        class="app-schedule-call__action"
        variant="primary"
        fill-width
        @click="onBookDemo"
        ><template #leftIcon><BaseIcon name="call" /></template
      ></BaseButton>
    </BaseTooltip>
    <BaseButton
      v-else
      class="app-schedule-call__action"
      variant="primary"
      fill-width
      @click="onBookDemo"
      >Schedule a call</BaseButton
    >
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-schedule-call {
  $self: &;

  width: 100%;

  &--compact {
    #{$self}__action {
      align-items: center;
      display: flex;
      height: $scale-large + $scale-xtiny !important;
      width: $scale-large + $scale-xtiny !important;
    }
  }
}
</style>
