<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const proseVariants = {
  variant: {
    primary: 'prose--primary',
    secondary: 'prose--secondary',
    danger: 'prose--danger',
    tertiary: 'prose--tertiary',
  },
  size: {
    xsmall: 'prose--xsmall',
    small: 'prose--small',
    default: 'prose--default',
  },
  weight: {
    semibold: 'prose--semibold',
    bold: 'prose--bold',
  },
  family: {
    lato: 'prose--lato',
  },
  alignment: {
    left: 'prose--left',
    center: 'prose--center',
    right: 'prose--right',
  },
};

const classes = cva({ variants: proseVariants });

export type ProseProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useClipboard } from '@vueuse/core';
import markdownit from 'markdown-it';
import markdownitContainer from 'markdown-it-container';

import hljs from 'highlight.js/lib/core';
import bash from 'highlight.js/lib/languages/bash';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import powershell from 'highlight.js/lib/languages/powershell';
import python from 'highlight.js/lib/languages/python';
import 'highlight.js/styles/github.css';

// Selectively importing languages as needed to reduce bundle size
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('java', java);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('powershell', powershell);
hljs.registerLanguage('python', python);

withDefaults(
  defineProps<{
    variant?: ProseProps['variant'];
    size?: ProseProps['size'];
    weight?: ProseProps['weight'];
    family?: ProseProps['family'];
    alignment?: ProseProps['alignment'];
    wrapper?: string;
  }>(),
  {
    variant: 'primary',
    size: 'default',
    alignment: 'left',
    wrapper: 'div',
  }
);

const emit = defineEmits<{
  copied: [source: string];
}>();

const root = ref<HTMLElement>();

function markdownToHtml(markdown: string) {
  const md = markdownit({
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return hljs.highlight(str, { language: lang }).value;
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }

      return ''; // use external default escaping
    },
  }).use(markdownitContainer, 'warning', {
    validate: function (params: string) {
      return params.trim().match(/^success|info|warning|danger$/);
    },

    render: function (tokens: any, idx: number) {
      if (tokens[idx].nesting === 1) {
        // opening tag
        return `<div class="prose__callout prose__callout--${tokens[idx].info.trim()}">\n`;
      } else {
        // closing tag
        return '</div>\n';
      }
    },
  });

  return md.render(markdown);
}

onMounted(() => {
  const highlights = root.value?.querySelectorAll('.prose pre > code');

  highlights?.forEach((div) => {
    if (div.parentElement?.querySelector('[data-copy-to-clipboard]')) return;

    if (!div.textContent) return;

    const source = ref<string>(div.textContent);
    const { copy, isSupported } = useClipboard({ source });

    if (!isSupported.value) return;

    const copyAction = document.createElement('span');
    copyAction.className = 'prose__copy';
    copyAction.setAttribute('data-copy-to-clipboard', 'true');

    const svgNS = 'http://www.w3.org/2000/svg';
    const copyIconSvg = document.createElementNS(svgNS, 'svg');
    const copyIconUse = document.createElementNS(svgNS, 'use');
    copyIconUse.setAttribute('href', '#DuplicateOutline');
    copyIconUse.setAttribute('xlink:href', '#DuplicateOutline');
    copyIconSvg.appendChild(copyIconUse);
    copyAction.appendChild(copyIconSvg);

    const copiedTooltip = document.createElement('div');
    copiedTooltip.className = 'prose__copied-tooltip';
    copiedTooltip.innerHTML = 'Copied';

    copyAction.addEventListener('click', () => {
      copy(source.value);
      emit('copied', source.value);

      copyAction.className = 'prose__copy prose__copy--copied';
      copiedTooltip.className =
        'prose__copied-tooltip prose__copied-tooltip--show';

      setTimeout(() => {
        copyAction.className = 'prose__copy';
        copiedTooltip.className = 'prose__copied-tooltip';
      }, 1000);
    });

    div.parentElement?.append(copyAction);
    div.parentElement?.append(copiedTooltip);
  });
});
</script>

<template>
  <component
    ref="root"
    :is="wrapper"
    class="prose"
    :class="classes({ variant, size, weight, family, alignment })"
  >
    <slot :markdown-to-html="markdownToHtml" />
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.prose {
  $self: &;

  color: $color-text-secondary;
  font-family: $font-family-default;
  font-style: normal;
  line-height: $line-height-default;
  width: 100%;

  > :deep(*:first-child) {
    margin-top: 0;
  }

  > :deep(*:last-child) {
    margin-bottom: 0;
  }

  :deep(p) {
    margin: 0.75rem 0;
  }

  :deep(a) {
    color: $color-text-secondary;
    outline-color: $color-border-brand;
  }
  :deep(a:hover) {
    background-color: $color-bg-surface;
    color: $color-text-default;
  }
  :deep(a:visited) {
    color: $color-text-secondary;
  }

  :deep(strong) {
    font-weight: $font-weight-semibold;
  }

  :deep(em) {
    font-style: italic;
  }

  :deep(ul),
  :deep(ol) {
    margin: 0.75rem 0;
    padding: 0 0 0 0.75rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
  :deep(li) {
    margin: 0.5rem 0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  :deep(h1) {
    color: $color-text-secondary;
    font-size: $font-size-xlarge;
    font-weight: $font-weight-bolder;
    line-height: $line-height-heading;
  }
  :deep(h1:first-child) {
    margin-top: 0;
  }
  :deep(h2) {
    color: $color-text-secondary;
    font-size: $font-size-large;
    font-weight: $font-weight-bolder;
    line-height: $line-height-heading;
  }
  :deep(h2:first-child) {
    margin-top: 0;
  }
  :deep(h3) {
    color: $color-text-secondary;
    font-size: $font-size-medium;
    font-weight: $font-weight-regular;
    line-height: $line-height-heading;
  }
  :deep(h3:first-child) {
    margin-top: 0;
  }
  :deep(h4) {
    color: $color-text-secondary;
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
    line-height: $line-height-heading;
  }
  :deep(h4:first-child) {
    margin-top: 0;
  }
  :deep(h5) {
    color: $color-text-secondary;
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    line-height: $line-height-heading;
    margin-bottom: $space-xxsmall;
  }
  :deep(h5:first-child) {
    margin-top: 0;
  }
  :deep(h6) {
    color: $color-text-secondary;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-regular;
    line-height: $line-height-heading;
  }
  :deep(h6:first-child) {
    margin-top: 0;
  }

  /**
   * Highlight.js overrides
   */
  :deep(code) {
    background-color: $color-bg-surface;
    border: 1px solid $color-border-secondary;
    border-radius: $space-xxxsmall;
    color: $color-text-secondary;
    width: 100%;
    padding: $space-xxxxsmall $space-xxxsmall;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  :deep(pre) {
    position: relative;

    &:hover {
      .prose__copy {
        opacity: 1;
      }
    }
  }
  :deep(pre code) {
    display: block;
    margin-bottom: 0.75rem;
    overflow-y: auto;
    padding: $space-xsmall $space-xlarge $space-xsmall $space-xsmall;
  }

  /**
   * Copy to clipboard button
   */
  :deep(pre #{$self}__copy) {
    opacity: 0;
    cursor: pointer;
    height: $scale-small;
    padding: $space-xxxsmall;
    position: absolute;
    right: $space-xxsmall;
    top: $space-xxsmall;
    transition: opacity 0.25s ease-in-out;
    width: $scale-small;

    svg {
      height: 1rem;
      width: 1rem;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px $color-border-default;
      border-radius: $space-xxxsmall;
      color: $color-text-default;
    }
  }
  :deep(pre #{$self}__copy--copied) {
    opacity: 1;
  }
  :deep(pre #{$self}__copied-tooltip) {
    background-color: $color-bg-default;
    border: 1px solid $color-border-default;
    border-radius: $space-xxsmall;
    box-shadow: $elevation-small;
    opacity: 0;
    padding: $space-xxsmall $space-xsmall;
    position: absolute;
    right: $space-xxxlarge;
    top: $space-xxsmall - $space-xxxxsmall;
    transition: opacity 0.25s ease-in-out;
    user-select: none;
  }
  :deep(pre #{$self}__copied-tooltip--show) {
    left: auto;
    opacity: 1;
  }

  :deep(#{$self}__callout) {
    border-left: $space-xxxsmall solid transparent;
    border-radius: 0 $space-xxsmall $space-xxsmall 0;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
    padding: $space-small $space-small;
    margin: 0.75rem 0;

    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    strong {
      font-weight: $font-weight-bolder;
    }

    p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &#{$self}__callout--success {
      background-color: $color-bg-success-xsubtle;
      border-left-color: $color-border-success;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $color-text-success;
      }
    }

    &#{$self}__callout--info {
      background-color: $color-bg-info-xsubtle;
      border-left-color: $color-border-info;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $color-text-info;
      }
    }

    &#{$self}__callout--warning {
      background-color: $color-bg-warning-xsubtle;
      border-left-color: $color-border-warning;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $color-text-warning;
      }
    }

    &#{$self}__callout--danger {
      background-color: $color-bg-danger-xsubtle;
      border-left-color: $color-border-danger;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $color-text-danger;
      }
    }
  }

  /**
   * Weight modifiers
   */

  &--semibold {
    font-weight: $font-weight-semibold;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  /**
   * Variant modifiers
   */
  &--secondary {
    color: $color-text-tertiary;
  }
  &--danger {
    color: $color-text-danger;
  }
  &--tertiary {
    color: $color-text-tertiary;
  }

  /**
   * Size modifiers
   */
  &--xsmall {
    font-size: $font-size-xxsmall;
  }
  &--small {
    font-size: $font-size-xsmall;
  }
  &--default {
    font-size: $font-size-small;
  }

  /**
   * Family modifiers
   */
  &--lato {
    font-family: $font-family-secondary;
  }

  /**
   * Alignment modifiers
   */
  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}
</style>
