import type { User } from '@auth0/auth0-vue';
import {
  type ColorfulIcon,
  colorfulIcons,
} from './components/BaseColorfulIcon/BaseColorfulIcon.vue';
import type { PasswordStrengthMetrics } from './types';
import { useFetch } from './use/fetch';
import {
  UserRole,
  type OrganizationDAO,
  type UserWithStatusWithApiKeysDAO,
} from '@monorepo/shared-model/src/user';

/**
 * Regex for a valid path params string including curly braces.
 */
export const validPathParamsRegex = /^[-a-zA-Z0-9@:%._+~#?&=*]+$/;

/**
 * Helper function for getting an asset URL.
 */
export function getAsset(path: string) {
  if (!import.meta.env.VITE_ASSETS_URL) {
    return path;
  }

  return import.meta.env.VITE_ASSETS_URL + path;
}

/**
 * Convert a fraction into a percentage string.
 */
export function toPercentageString(fraction: number = 0) {
  const value =
    typeof fraction === 'number' && fraction !== null ? fraction : 0;

  return parseFloat((value * 100).toFixed(2)).toString() + '%';
}

/**
 * Convert a millisecond into a milliseconds string.
 */
export function toMillisecondsString(ms: number = 0) {
  const value = ms !== null ? ms : 0;

  return parseFloat(value.toFixed(2)).toString() + 'ms';
}

/**
 * Calculate the change ratio between two numbers.
 */
export function calculateChangeRatio(
  newNumber: number,
  oldNumber: number
): number {
  if (oldNumber === 0) {
    return 0;
  }

  return ((newNumber - oldNumber) / oldNumber) * 1;
}

/**
 * Helper function for finding a ColorfulIcon by host url.
 */
export function findColorfulIconByHost(host: string) {
  return colorfulIcons.find((icon: ColorfulIcon) => {
    if (icon.hosts && icon.hosts.includes(host)) {
      return icon;
    }
  });
}

/**
 * Validate the dimensions of an virtually instantiated image.
 */
export function validateImageByDimensions(
  src: string,
  width: number,
  height: number
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      const isInvalid = img.width < width || img.height < height;
      resolve(!isInvalid);
    };

    img.onerror = function () {
      reject(new Error('Image failed to load'));
    };

    img.src = src;
  });
}

/**
 * Helper function for replacing placeholder strings in a content string.
 */
export function replacePlaceholders(
  originalString: string,
  replacements: { [key: string]: string }
) {
  let result = originalString;

  for (const placeholder in replacements) {
    const regex = new RegExp(placeholder, 'g');
    result = result.replace(regex, replacements[placeholder]);
  }

  return result;
}

/**
 * Helper function for checking if a user has dev mode access.
 */
export function hasDevToolsAccess(user: User): boolean {
  const isPlayground = import.meta.env.VITE_PLAYGROUND === 'true';
  if (isPlayground) return false;

  const devToolEmailSuffixes = import.meta.env.VITE_DEV_MODE;
  if (!user || !devToolEmailSuffixes) return false;

  return devToolEmailSuffixes
    .split(',')
    .some(
      (suffix: string) =>
        user.email?.endsWith(suffix) ||
        user.nameIdAttributes?.value?.endsWith(suffix)
    );
}

/**
 * Helper function for checking if a user is an admin.
 */
export function isAdmin(
  extendedUser: UserWithStatusWithApiKeysDAO | undefined,
  org: OrganizationDAO | undefined
) {
  // If user is not part of an organization they're an admin, and can create an organization.
  if (!org) return true;

  // If user is part of an organization, and there status is owner or admin, they're an admin.
  return !!(
    org &&
    extendedUser &&
    [UserRole.Owner, UserRole.Admin].includes(extendedUser.role)
  );
}

/**
 * Helper function to validate the strength of a password.
 */
export function validatePasswordStrength(
  password: string
): PasswordStrengthMetrics {
  const strength = {
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /[0-9]/.test(password),
    special: /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/.test(password),
    length: password?.length > 7,
  };

  return strength;
}

/**
 * Helper function to check if a password meets the strength requirements.
 */
export function isPasswordStrengthValid(password: string) {
  const strength = validatePasswordStrength(password);
  return Object.values(strength).every((value) => value);
}

/**
 * Helper function for checking if a user should be included in PostHog recordings.
 */
export function isUserEligibleForPostHogRecording(user?: User): boolean {
  const rawExcludeList = import.meta.env.VITE_SYNTHETIC_TEST_USERS_EMAILS;
  if (!user || !rawExcludeList) return true;

  return !rawExcludeList
    .split(',')
    .some((email: string) => user.email === email);
}

/**
 * Helper function for retrieving the user's IP address.
 */
export async function getIpAddress() {
  const { data } = await useFetch('https://checkip.amazonaws.com/').text();

  return data.value;
}
