<script setup lang="ts">
import { ref } from 'vue';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { toPercentageString, toMillisecondsString } from '../../utils';

import type {
  PrimitiveAggregatedDiscovery,
  PrimitiveProvider,
} from '@monorepo/shared-model/src/aggregated-discovery';
import type { PrimitiveUrlTreeDAO } from '@monorepo/shared-model/src/url-tree';
import { useUserStore } from '../../stores/user';
import type { RemedyContent } from '../../types/content';

import AppPageDrawer from '../AppPageDrawer/AppPageDrawer.vue';
import AppProvider from '../AppProvider/AppProvider.vue';

import BaseTable from '../BaseTable/BaseTable.vue';
import BaseStatusChart from '../BaseStatusChart/BaseStatusChart.vue';
import BaseStatusChartLegend from '../BaseStatusChartLegend/BaseStatusChartLegend.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';
import BaseDuotoneIcon from '../BaseDuotoneIcon/BaseDuotoneIcon.vue';
import Column from 'primevue/column';

import DashboardOverviewErrorWarning from '../DashboardOverviewErrorWarning/DashboardOverviewErrorWarning.vue';
import DashboardAddRemediation from '../DashboardAddRemediation/DashboardAddRemediation.vue';
import DashboardOverviewTableDropdown from '../DashboardOverviewTableDropdown/DashboardOverviewTableDropdown.vue';
import DashboardPathParams from '../DashboardPathParams/DashboardPathParams.vue';

const props = defineProps<{
  traffic: PrimitiveAggregatedDiscovery['traffic'];
  urlTree?: PrimitiveUrlTreeDAO;
}>();

const emit = defineEmits<{
  applyRemediation: [provider: PrimitiveProvider, plugin: RemedyContent];
  removeRemediation: [provider: PrimitiveProvider, plugin: RemedyContent];
}>();

const userStore = useUserStore();

const openDropdowns = ref<number[]>([]);
const expandedRows = ref<
  PrimitiveAggregatedDiscovery['traffic']['providers']['instances'][]
>([]);
const errorRateThreshold = ref(0.05);

const formattedDate = (date: Date) => {
  return format(date, 'dd/MM/yy, HH:mm:ss');
};

const getMethodVariant = (method: string) => {
  switch (method) {
    case 'GET':
      return 'info';

    case 'DELETE':
      return 'danger';

    case 'OPTIONS':
      return 'warning';

    case 'HEAD':
      return 'warning';

    case 'CONNECT':
      return 'warning';

    case 'TRACE':
      return 'warning';

    default:
      return 'success';
  }
};

const showProviderLevelErrorWarning = (data: any) => {
  return (
    (data.errorRate > errorRateThreshold.value ||
      data.endpoints.some((endpoint: any) =>
        showEnpointLevelErrorWarning(endpoint)
      )) &&
    !expandedRows.value.includes(data)
  );
};

const highestEndpointErrorRate = (data: any) => {
  return Math.max(
    ...data.endpoints.map((endpoint: any) => endpointErrorRate(endpoint))
  );
};

const endpointErrorRate = (data: any) => {
  return (
    (data.statusCodes['4xx'].total + data.statusCodes['5xx'].total) / data.count
  );
};

const showEnpointLevelErrorWarning = (data: any) => {
  return endpointErrorRate(data) > errorRateThreshold.value;
};

const onManagePathParams = (provider: PrimitiveProvider) => {
  managePathParamsProvider.value = provider as PrimitiveProvider;
  showPathParams.value = true;
};

const onDropdownOpen = (index: number) => {
  openDropdowns.value.push(index);
};

const onDropdownClose = (index: number) => {
  openDropdowns.value = openDropdowns.value.filter((i) => i !== index);
};

// TEMPORARY: for demo purposes. It's undecided at this stage where this data will come from.
// ----------------------------------------------------------------------------------------------------
const showAddRemediation = ref(false);
const showRemediationSuggestions = ref(false);
const showPathParams = ref(false);
const addRemediationProvider = ref<PrimitiveProvider>();
const managePathParamsProvider = ref<PrimitiveProvider>();

const getRemediations = (data: any, suggestions: boolean = false) => {
  const traffic = cloneDeep(props.traffic);
  const provider = traffic.providers.instances.find((provider) => {
    provider.endpoints = provider.endpoints.filter(
      (endpoint) =>
        endpoint.path === data.path && endpoint.maxTime === data.maxTime
    );
    return provider.endpoints.length > 0;
  });

  showRemediationSuggestions.value = suggestions;
  addRemediationProvider.value = provider;
  showAddRemediation.value = true;
};

const onApplyRemediation = (
  provider: PrimitiveProvider,
  remedy: RemedyContent
) => {
  emit('applyRemediation', provider, remedy);
  showAddRemediation.value = false;
};

const onRemoveRemediation = (
  provider: PrimitiveProvider,
  remedy: RemedyContent
) => {
  emit('removeRemediation', provider, remedy);
  showAddRemediation.value = false;
};
// ----------------------------------------------------------------------------------------------------
</script>

<template>
  <BaseTable
    class="dashboard-overview-table"
    v-model:expanded-rows="expandedRows"
    :value="traffic.providers.instances"
    removable-sort
    row-expander
    row-key="host"
  >
    <Column class="dashboard-overview-table__provider-error-warning">
      <template #body="slotProps"
        ><DashboardOverviewErrorWarning
          v-if="showProviderLevelErrorWarning(slotProps.data)"
          context="provider"
          :error-rate="slotProps.data.errorRate"
          :error-rate-threshold="errorRateThreshold"
          :highest-endpoint-error-rate="
            highestEndpointErrorRate(slotProps.data)
          "
      /></template>
    </Column>
    <Column field="id" class="dashboard-overview-table__expander">
      <template #body="slotProps">
        <BaseIcon
          v-show="expandedRows.includes(slotProps.data)"
          name="chevron-down"
          class="dashboard-overview-table__expander-handle"
        />
        <BaseIcon
          v-show="!expandedRows.includes(slotProps.data)"
          name="chevron-right"
          class="dashboard-overview-table__expander-handle"
        />
      </template>
    </Column>
    <Column
      field="host"
      header="API provider"
      sortable
      class="dashboard-overview-table__column"
      ><template #body="slotProps"
        ><AppProvider
          :host="slotProps.data.host"
          :name="slotProps.data.name" /></template
    ></Column>
    <Column
      field="endpointCount"
      header="Total endpoints"
      sortable
      class="dashboard-overview-table__column"
    ></Column>
    <Column
      field="callCount"
      header="Total API calls"
      sortable
      class="dashboard-overview-table__column"
    ></Column>
    <Column
      field="errorRate"
      header="Error rate (%)"
      sortable
      class="dashboard-overview-table__column"
      ><template #body="slotProps">{{
        toPercentageString(slotProps.data.errorRate)
      }}</template></Column
    >
    <Column
      field="runtimeMs"
      header="Avg. runtime"
      sortable
      class="dashboard-overview-table__column"
      ><template #body="slotProps">{{
        toMillisecondsString(slotProps.data.runtimeMs.avg)
      }}</template></Column
    >
    <Column>
      <template #body="{ data, index }">
        <BaseLayoutGap alignment="right" size="xlarge">
          <DashboardOverviewTableDropdown
            class="dashboard-overview-table__provider-dropdown"
            :class="{
              'dashboard-overview-table__provider-dropdown--open':
                openDropdowns.includes(index),
            }"
            :provider="data"
            @opened="onDropdownOpen(index)"
            @closed="onDropdownClose(index)"
            @manage-path-params="onManagePathParams(data)"
          />
        </BaseLayoutGap>
      </template>
    </Column>
    <template #expansion="slotProps">
      <BaseTable
        :value="slotProps.data.endpoints"
        removableSort
        class="dashboard-overview-table__expanded-table"
      >
        <Column class="dashboard-overview-table__endpoint-error-warning">
          <template #body="slotProps"
            ><DashboardOverviewErrorWarning
              v-if="showEnpointLevelErrorWarning(slotProps.data)"
              :error-rate="endpointErrorRate(slotProps.data)"
              :error-rate-threshold="errorRateThreshold"
          /></template>
        </Column>
        <Column
          field="method"
          header="Method"
          sortable
          class="dashboard-overview-table__column"
        >
          <template #body="slotProps">
            <span
              class="dashboard-overview-table__method"
              :class="{
                [`dashboard-overview-table__method--${getMethodVariant(slotProps.data.method)}`]: true,
              }"
              >{{ slotProps.data.method }}</span
            >
          </template>
        </Column>
        <Column
          class="dashboard-overview-table__nested-column dashboard-overview-table__nested-column--path"
          field="path"
          header="Path"
          sortable
        >
          <template #body="slotProps">
            <span
              class="dashboard-overview-table__path"
              v-text="slotProps.data.path"
            /> </template
        ></Column>
        <Column field="maxTime" header="Last transaction time" sortable>
          <template #body="slotProps">{{
            formattedDate(slotProps.data.maxTime)
          }}</template>
        </Column>
        <Column field="count" header="Total API calls" sortable></Column>
        <Column field="statusCodes" header="Status code dist.">
          <template #body="slotProps">
            <div class="dashboard-overview-table__error-rates">
              <BaseStatusChart
                variant="bar"
                :status-codes="slotProps.data.statusCodes"
                class="dashboard-overview-table__status-chart"
                ><BaseStatusChartLegend
                  :status-codes="slotProps.data.statusCodes"
              /></BaseStatusChart>
            </div>
          </template>
        </Column>
        <Column field="runtimeMs.avg" header="Avg. duration" sortable>
          <template #body="slotProps">
            {{ toMillisecondsString(slotProps.data.runtimeMs.avg) }}
          </template>
        </Column>
        <Column
          v-if="userStore.permissions.featureFlag.pluginSuggestions"
          field="remedies"
          header="Plugins & Policies"
          class="dashboard-overview-table__remedies"
        >
          <template #body="slotProps">
            <BaseLayoutGap size="xlarge">
              <BaseLayoutGap wrap>
                <template
                  v-if="
                    slotProps.data.remedies &&
                    slotProps.data.remedies.length > 0
                  "
                >
                  <BaseBadge
                    v-for="remedy in slotProps.data.remedies"
                    :key="remedy.name"
                    chip
                    ><template v-if="remedy.icon" #leftIcon
                      ><BaseDuotoneIcon :name="remedy.icon" /></template
                    >{{ remedy.name }}</BaseBadge
                  >
                </template>
                <span v-else>-</span>
              </BaseLayoutGap>

              <BaseButton
                v-if="showEnpointLevelErrorWarning(slotProps.data)"
                size="small"
                variant="primary"
                @click="getRemediations(slotProps.data, true)"
                ><template #leftIcon><BaseIcon name="flow" /></template>Flow
                recommendations</BaseButton
              >
              <div v-else class="dashboard-overview-table__add-plugin">
                <BaseButton
                  size="small"
                  variant="secondary"
                  @click="getRemediations(slotProps.data)"
                  ><template #leftIcon><BaseIcon name="flow" /></template>Add
                  flows</BaseButton
                >
              </div>
            </BaseLayoutGap>
          </template>
        </Column>
      </BaseTable>
    </template>
  </BaseTable>

  <Teleport to="body">
    <AppPageDrawer
      class="dashboard-overview-table__add-remediation"
      v-model="showAddRemediation"
      :widths="{ startWidth: 560 }"
    >
      <DashboardAddRemediation
        v-if="addRemediationProvider"
        :provider="addRemediationProvider"
        :suggestions="showRemediationSuggestions"
        @apply-remediation="onApplyRemediation"
        @remove-remediation="onRemoveRemediation"
      />
    </AppPageDrawer>

    <AppPageDrawer
      v-if="managePathParamsProvider"
      class="dashboard-overview-table__path-params"
      v-model="showPathParams"
      :widths="{ startWidth: 560 }"
    >
      <DashboardPathParams :provider="managePathParamsProvider" />
    </AppPageDrawer>
  </Teleport>
</template>

<style lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-overview-table {
  $self: &;

  &__provider-error-warning {
    left: $space-small;
    position: absolute;
    padding: 0 !important;
    transform: translateY(-50%);
    top: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__provider {
    line-height: $line-height-default;
  }

  &__endpoint-error-warning {
    box-sizing: content-box;
    padding: 0 0 0 $space-small !important;
    width: 21px;
  }

  &__expander {
    padding-left: $space-xlarge !important;
    padding-right: 0 !important;
    width: 32px;
  }

  &__expander-handle {
    color: $color-text-tertiary !important;
    font-size: $scale-xxsmall;
    margin: 0 $space-xxxsmall;
    position: relative;
    top: 3px;
  }

  &__error-rates {
    align-items: center;
    display: flex;
  }

  &__method {
    font-weight: $font-weight-semibold;

    &--info {
      color: $color-text-info;
    }

    &--danger {
      color: $color-text-danger;
    }

    &--success {
      color: $color-text-success;
    }
  }

  &__path {
    word-break: break-all;
  }

  &__remedies {
    padding-top: $space-small;
    padding-bottom: $space-small;
    width: 25%;
  }

  &__provider-dropdown {
    position: relative;
    top: 3px;

    &:not(#{$self}__provider-dropdown--open) {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__add-plugin {
    opacity: 0;
    visibility: hidden;
  }
}
</style>

<style lang="scss">
.dashboard-overview-table {
  [data-pc-section='bodyrow']:hover {
    .dashboard-overview-table__provider-dropdown {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s ease-out;
    }
  }
  [data-pc-section='rowexpansioncell'] [data-pc-section='bodyrow']:hover {
    .dashboard-overview-table__add-plugin {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s ease-out;
    }
  }
}
</style>
