import { type PolicyContent, RemedyType } from '../../../types/content';
import harExporter from '../plugin/har-exporter';
import smartCaching from '../plugin/smart-caching';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Anomaly Detection',
  comingSoon: true,
  summary:
    'Detects anomalies in API usage patterns based on collected HAR logs and optimizes performance with intelligent caching.',
  plugins: [harExporter, smartCaching],
  docsUrl: undefined,
  details: `
By analyzing collected HAR logs for abnormal usage patterns and leveraging Smart Caching to optimize performance, this policy detects and addresses anomalies in API usage, ensuring stability and reliability in API operations.
`,
};

export default content;
