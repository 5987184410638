export const Spacing = [
  'xxxsmall',
  'xxsmall',
  'xsmall',
  'small',
  'medium',
  'large',
  'xlarge',
  'xxlarge',
  'xxxlarge',
] as const;
export type Spacing = (typeof Spacing)[number];

export const Scales = [
  'small',
  'medium',
  'large',
  'xlarge',
  'xxlarge',
  'xxxlarge',
] as const;
export type Scales = (typeof Scales)[number];

export const FontSizes = [
  'xsmall',
  'small',
  'default',
  'medium',
  'large',
  'xlarge',
  'xxlarge',
  'xxxlarge',
  'mega',
  'xmega',
] as const;
export type FontSizes = (typeof FontSizes)[number];

export const Statuses = [
  'none',
  'success',
  'warning',
  'info',
  'danger',
] as const;
export type Statuses = (typeof Statuses)[number];

export const FontWeights = ['regular', 'bold', 'bolder'] as const;
export type FontWeights = (typeof FontWeights)[number];

export interface UserMetadata {
  given_name?: string;
  family_name?: string;
  organization_name?: string;
  dev_mode?: {
    demo_mode?: boolean;
    demo_update_interval?: number;
    demo_providers?: (number | undefined)[];
    feature_flags?: {
      [K in FeatureFlag]?: boolean;
    };
  };
  completed_onboarding?: string[];
}

export enum FeatureFlag {
  PluginSuggestions = 'pluginSuggestions',
  AltNavigation = 'altNavigation',
  ApiProviderView = 'apiProviderView',
}

export interface UserPermissions {
  canAccessDevTools: boolean;
  isAdmin: boolean;
  isPlayground: boolean;
  featureFlag: {
    [K in FeatureFlag]: boolean;
  };
}

export type PasswordStrengthMetric =
  | 'uppercase'
  | 'lowercase'
  | 'number'
  | 'special'
  | 'length';

export type PasswordStrengthMetrics = {
  [key in PasswordStrengthMetric]: boolean;
};

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
