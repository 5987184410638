<script setup lang="ts">
import { computed } from 'vue';
import { toPercentageString } from '../../utils';

import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';

const props = withDefaults(
  defineProps<{
    context?: 'provider' | 'endpoint';
    errorRate: number;
    errorRateThreshold: number;
    highestEndpointErrorRate?: number;
  }>(),
  {
    context: 'endpoint',
  }
);

// computed property for Endpoint error rate is above 5%
const tooltipContent = computed(() => {
  if (
    props.highestEndpointErrorRate &&
    props.errorRate < props.errorRateThreshold
  ) {
    return `This ${props.context} has an endpoint with an error rate of ${toPercentageString(
      props.highestEndpointErrorRate
    )}, <br />which exceeds the recommended threshold of ${toPercentageString(
      props.errorRateThreshold
    )}.`;
  }

  return `This ${props.context}'s error rate is ${toPercentageString(
    props.errorRate
  )}, which <br />exceeds the recommended threshold of ${toPercentageString(
    props.errorRateThreshold
  )}`;
});
</script>

<template>
  <BaseTooltip class="dashboard-overview-error-warning" placement="top-start">
    <template #content>
      <span v-html="tooltipContent" />
    </template>
    <BaseIcon
      name="alert-triangle"
      class="dashboard-overview-error-warning__icon"
    />
  </BaseTooltip>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-overview-error-warning {
  $self: &;

  &__icon {
    color: $color-text-danger;
    font-size: $font-size-default;
    position: relative;
    top: 0;
  }
}
</style>
