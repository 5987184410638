<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { onClickOutside } from '@vueuse/core';
import interact from 'interactjs';

import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

import { usePageDrawer } from './store';

type Widths = {
  minWidth?: number;
  maxWidth?: number;
  startWidth?: number;
};

const props = defineProps<{
  resize?: boolean;
  widths?: Widths;
}>();

const root = ref<HTMLElement>();
const pageDrawer = usePageDrawer();

const id = uuidv4();
const open = defineModel<boolean>({ default: false });
const defaultWidths = {
  minWidth: 480,
  maxWidth: 480,
  startWidth: 480,
};

const onClose = () => {
  open.value = false;
};

const computedWidths = computed(() => ({
  ...defaultWidths,
  ...props.widths,
}));

const isReziable = computed(
  () =>
    props.resize &&
    computedWidths.value.maxWidth > computedWidths.value.minWidth
);

const computedClasses = computed(() => ({
  'app-page-drawer--open': open.value,
  'app-page-drawer--resize': isReziable.value,
}));

const computedStyleVariables = computed(() => ({
  '--app-page-drawer-width':
    computedWidths.value.startWidth > computedWidths.value.minWidth
      ? `${computedWidths.value.startWidth}px`
      : `${computedWidths.value.minWidth}px`,
}));

onClickOutside(
  root,
  () => {
    open.value = false;
  },
  {
    ignore: ['[data-action="toggle-page-drawer"]'],
  }
);

watch(
  () => root.value,
  () => {
    if (root.value && isReziable.value) {
      interact(root.value).resizable({
        edges: { top: false, left: true, bottom: false, right: false },
        listeners: {
          move: function (event) {
            let { x } = event.target.dataset;

            if (
              event.rect.width < computedWidths.value.maxWidth &&
              event.rect.width > computedWidths.value.minWidth
            ) {
              x = (parseFloat(x) || 0) + event.deltaRect.left;

              Object.assign(event.target.style, {
                width: `${event.rect.width}px`,
              });

              Object.assign(event.target.dataset, { x });
            }
          },
        },
      });
    }
  },
  { immediate: true }
);

watch(
  () => open.value,
  async () => {
    if (open.value) {
      pageDrawer.openInstance = id;

      // Wait for the drawer to be rendered before shifting focus. Removes focus from trigger elements.
      await nextTick();
      root.value?.focus();
    }
  }
);

watch(
  () => pageDrawer.openInstance,
  () => {
    if (pageDrawer.openInstance !== id) {
      open.value = false;
    }
  }
);
</script>

<template>
  <Transition>
    <div
      v-if="open"
      ref="root"
      class="app-page-drawer"
      :class="computedClasses"
      :style="computedStyleVariables"
      tabindex="0"
      @keydown.esc="onClose"
    >
      <div v-if="isReziable" ref="gutter" class="app-page-drawer__gutter">
        <BaseIcon class="app-page-drawer__handle" name="handle" />
      </div>
      <div class="app-page-drawer__container">
        <div class="app-page-drawer__close">
          <BaseButton
            variant="ghost"
            size="small"
            aria-label="Close page drawer"
            @click="onClose"
            @keydown.enter="onClose"
          >
            <template #leftIcon>
              <BaseIcon name="close" title="Close this dialog" />
            </template>
          </BaseButton>
        </div>
        <slot />
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-page-drawer {
  $self: &;

  background-color: $white;
  border-left: 1px solid $color-border-default;
  box-shadow: $elevation-xsmall-right;
  height: 100%;
  outline: none;
  position: fixed;
  top: 0;
  right: -100%;
  width: var(--app-page-drawer-width);
  z-index: layer('drawer');

  &__gutter {
    position: absolute;
    width: $space-large;
    cursor: grab;
    height: calc(100vh - $app-header-height - $space-large);
    user-select: none;
    left: 0;
    top: 0;
    z-index: layer('drawer') + 1;

    &:hover {
      #{$self}__handle {
        color: $color-text-success;
      }
    }
  }

  &__handle {
    color: $color-border-default-strong;
    font-size: $scale-small;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__container {
    height: 100%;
    overflow-y: auto;
    padding: $space-large $space-large $space-xlarge;
    position: relative;
  }

  &__close {
    cursor: pointer;
    font-size: $font-size-xsmall;
    position: absolute;
    right: $space-large;
    top: $space-large;
    z-index: calc(layer('drawer') + 1);

    &:hover {
      color: $color-text-brand;
    }
  }

  &--open {
    right: 0;
  }

  &.v-enter-active,
  &.v-leave-active {
    transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  }

  &.v-enter-from,
  &.v-leave-to {
    right: -100%;
  }

  &.v-enter-to,
  &.v-leave-from {
    right: 0;
  }
}
</style>
