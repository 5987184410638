<script setup lang="ts">
import { computed, onMounted } from 'vue';
import proxyInstallationContent from '../../content/en/proxy-installation';
import { replacePlaceholders } from '../../utils';
import { useForm, useIsFormDirty, useIsFormValid } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';
import posthog from 'posthog-js';

import { type PlaygroundVisitor } from '../../stores/demo/types';

import AppPageDrawerHeading from '../AppPageDrawerHeading/AppPageDrawerHeading.vue';

import BaseHeading from '../BaseHeading/BaseHeading.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseLoader from '../BaseLoader/BaseLoader.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseMessage from '../BaseMessage/BaseMessage.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseStepper from '../BaseStepper/BaseStepper.vue';
import BaseDivider from '../BaseDivider/BaseDivider.vue';
import BaseTabs from '../BaseTabs/BaseTabs.vue';
import BaseTabsPanel from '../BaseTabsPanel/BaseTabsPanel.vue';
import BaseForm from '../BaseForm/BaseForm.vue';

import { useUserStore } from '../../stores/user';
import { useToast } from '../../use/toast';
import BaseInput from '../BaseInput/BaseInput.vue';
import { useStorage } from '@vueuse/core';

defineProps<{
  installed?: boolean;
  onboarding?: boolean;
}>();

const playgroundVisitor = useStorage<PlaygroundVisitor>(
  'playground-visitor',
  {}
);
const hasSubmitted = useStorage('managed-proxy-interest-submitted', false);

const toast = useToast();
const userStore = useUserStore();

const { defineField, handleSubmit, isSubmitting } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      email: yup.string().email().required(),
    })
  ),
});

const isFormValid = useIsFormValid();
const isFormDirty = useIsFormDirty();

const [email] = defineField('email');

const replacements = computed(() => {
  const userMetaData = userStore.user?.user_metadata;
  const extendedUser = userStore.extendedUser;
  const org = userStore.org;

  let apiKey = 'API_KEY';

  if (org && org.apiKeys.length) {
    apiKey = org.apiKeys[0].key;
  } else if (extendedUser && extendedUser?.apiKeys.length) {
    apiKey = extendedUser?.apiKeys[0].key;
  }

  return {
    '#API_KEY#': apiKey,
    '#TENANT_NAME#': userMetaData?.organization_name
      ? `"${userMetaData?.organization_name}"`
      : '"ORGANIZATION"',
  };
});

const saveChanges = handleSubmit(async (_, { resetForm }) => {
  if (!email.value) return;

  const result = posthog.capture('user_managed_proxy_interest', {
    email: email.value,
  });

  if (!result) {
    hasSubmitted.value = false;
    toast.add({
      severity: 'error',
      summary: 'Failed to register your interest.',
      life: 3000,
    });
  } else {
    hasSubmitted.value = true;

    resetForm();
  }
});

onMounted(() => {
  if (userStore.permissions.isPlayground && playgroundVisitor.value.email) {
    email.value = playgroundVisitor.value.email;
  } else if (userStore.user?.email) {
    email.value = userStore.user.email;
  }
});
</script>

<template>
  <div class="dashboard-installation-proxy">
    <AppPageDrawerHeading>{{
      proxyInstallationContent.title
    }}</AppPageDrawerHeading>

    <BaseTabs>
      <BaseTabsPanel
        v-for="platform in proxyInstallationContent.platform"
        :key="platform.name"
        :header="platform.name"
      >
        <BaseStepper :steps="platform.steps">
          <template
            v-for="(step, index) in platform.steps"
            :key="index"
            v-slot:[`${index+1}`]
          >
            <BaseHeading :level="5" :semantic-level="3" weight="semibold">{{
              step.title
            }}</BaseHeading>

            <BaseProse size="small">
              <template #default="{ markdownToHtml }">
                <div
                  v-html="
                    markdownToHtml(
                      replacePlaceholders(step.details, replacements)
                    )
                  "
                />
              </template>
            </BaseProse>
          </template>
        </BaseStepper>

        <div v-if="onboarding" class="dashboard-installation-proxy__footer">
          <BaseMessage v-if="installed" variant="success" alignment="center">
            <template #leftIcon><BaseIcon name="check" /></template>Proxy
            installed.
          </BaseMessage>
          <BaseMessage v-else alignment="center">
            <template #leftIcon><BaseLoader /></template>Waiting for the first
            signal…
          </BaseMessage>
        </div>
      </BaseTabsPanel>

      <BaseTabsPanel header="Cloud">
        <BaseHeading :level="4" :semantic-level="3"
          ><BaseLayoutGap wrapper="span"
            >Cloud managed installation
            <BaseBadge variant="brand-secondary" size="small"
              >Coming Soon</BaseBadge
            ></BaseLayoutGap
          ></BaseHeading
        >

        <BaseLayoutGap direction="column" size="small">
          <BaseProse size="small">
            <p>
              Looking to streamline your API management without the hassle of
              infrastructure setup? Our gateway Cloud solution, hosted by
              lunar.dev offers a fully managed service, allowing you to focus on
              your applications, not infrastructure. With gateway Cloud, you'll
              enjoy:
            </p>

            <ul>
              <li>
                <strong>Hassle-free setup:</strong> No need to manage servers or
                infrastructure.
              </li>

              <li>
                <strong>Scalability:</strong> Our managed solution automatically
                scales with your needs, ensuring optimal performance under any
                workload.
              </li>

              <li>
                <strong>Reliability:</strong> Rest assured knowing that your API
                management is in the hands of experts. Our team at lunar.dev
                ensures reliable uptime and performance.
              </li>

              <li>
                <strong>Security:</strong> Benefit from built-in security
                measures to protect your API traffic and data.
              </li>
            </ul>

            <p>
              Stay tuned for updates on our gateway Cloud option. Get notified
              when this is available!
            </p></BaseProse
          >
        </BaseLayoutGap>

        <template v-if="hasSubmitted">
          <BaseMessage
            class="dashboard-installation-proxy__success"
            variant="success"
            alignment="center"
          >
            <template #leftIcon><BaseIcon name="check" /></template>
            Great. We'll keep you notified.
          </BaseMessage>
        </template>
        <template v-else>
          <BaseDivider size="large" />

          <BaseForm
            class="dashboard-installation-proxy__form"
            compact
            submit-label="Notify me"
            submitting-label="Saving…"
            v-model:valid="isFormValid"
            v-model:dirty="isFormDirty"
            v-model:submitting="isSubmitting"
            @submit="saveChanges"
          >
            <BaseInput
              class="dashboard-installation-proxy__field"
              v-model="email"
              label="Want to be known when this is available?"
              placeholder="Enter your email address…"
              type="email"
              required
            />
          </BaseForm>
        </template>
      </BaseTabsPanel>
    </BaseTabs>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-installation-proxy {
  $self: &;

  &__title {
    margin-bottom: $space-large;
  }

  &__item {
    margin-bottom: $space-large;
  }

  &__field {
    padding-right: 25%;
  }

  &__success {
    margin-top: $space-xlarge;
  }
}
</style>
