<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const iconVariants = {
  name: {
    'ai-magic': 'AiMagic',
    'alert-circle': 'AlertCircle',
    'alert-circle-outline': 'AlertCircleOutline',
    'alert-cross': 'AlertCross',
    'alert-triangle': 'AlertTriangle',
    'arrow-left': 'ArrowLeft',
    'arrow-right': 'ArrowRight',
    'book-open-text': 'BookOpenText',
    bin: 'Bin',
    bulb: 'Bulb',
    'bulb-outline': 'BulbOutline',
    'calendar-check-outline': 'CalendarCheckOutline',
    call: 'Call',
    check: 'Check',
    'check-lg': 'CheckLg',
    'check-circle': 'CheckCircle',
    'chevron-down': 'ChevronDown',
    'chevron-right': 'ChevronRight',
    'chevron-up': 'ChevronUp',
    close: 'Close',
    'cloud-data-transfer-outline': 'CloudDataTransferOutline',
    code: 'Code',
    'common-file-search-outline': 'CommonFileSearchOutline',
    'currency-dollar-circle': 'CurrencyDollarCircle',
    'data-file-warning-outline': 'DataFileWarningOutline',
    'data-transfer-circle-outline': 'DataTransferCircleOutline',
    'date-range': 'DateRange',
    discord: 'Discord',
    'duplicate-outline': 'DuplicateOutline',
    endpoint: 'Endpoint',
    'eye-cross-outline': 'EyeCrossOutline',
    'eye-outline': 'EyeOutline',
    envelope: 'Envelope',
    expand: 'Expand',
    flow: 'Flow',
    'four-squares-outline': 'FourSquaresOutline',
    github: 'Github',
    'github-outline': 'GithubOutline',
    google: 'Google',
    'graph-stats-outline': 'GraphStatsOutline',
    handle: 'Handle',
    'handle-left': 'HandleLeft',
    'handle-right': 'HandleRight',
    'handle-thick-default': 'HandleThickDefault',
    'handle-thin-default': 'HandleThinDefault',
    insights: 'Insights',
    layers: 'Layers',
    more: 'More',
    'network-outline': 'NetworkOutline',
    neutral: 'Neutral',
    'performance-increase-outline': 'PerformanceIncreaseOutline',
    'pie-line-graph-outline': 'PieLineGraphOutline',
    plus: 'Plus',
    puzzle: 'Puzzle',
    'puzzle-outline': 'PuzzleOutline',
    'question-circle': 'QuestionCircle',
    'question-circle-outline': 'QuestionCircleOutline',
    search: 'Search',
    'server-line-outline': 'ServerLineOutline',
    'server-outline': 'ServerOutline',
    'server-warning500-outline': 'ServerWarning500Outline',
    sleep: 'Sleep',
    'space-science-outline': 'SpaceScienceOutline',
    star: 'Star',
    'stopwatch-outline': 'StopwatchOutline',
    sync: 'Sync',
    'trend-down': 'TrendDown',
    'trend-up': 'TrendUp',
    'triangle-down': 'TriangleDown',
    'triangle-up': 'TriangleUp',
  },
};

const classes = cva({ variants: iconVariants });

export type IconProps = VariantProps<typeof classes> & { name: string };
</script>

<script setup lang="ts">
import { computed } from 'vue';

/**
 * Important note:
 * BaseIcon uses a sprite that lives in ../../public/sprite.svg and is generated from the icons
 * in ./icons using https://svgsprit.es. The sprite is embed in ../../index.html.
 */

const props = defineProps<{
  /**
   * The name of the icon.
   */
  name: IconProps['name'];
  /**
   * An aria-label is automatically applied to the icon based on the icon name. You can override this using the label prop.
   */
  label?: string;
}>();

const computedAriaLabel = computed(() => {
  const name = props.name
    .split('-')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return props.label ? props.label : `${name} icon`;
});
</script>

<template>
  <span class="icon">
    <svg
      class="icon__img"
      role="img"
      :aria-label="computedAriaLabel"
      height="1em"
      width="1em"
    >
      <use
        :xlink:href="`#${iconVariants.name[props.name]}`"
        :href="`#${iconVariants.name[props.name]}`"
      />
    </svg>
  </span>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.icon {
  align-items: center;
  color: inherit;
  display: inline-flex;
  height: 1em;
  fill: currentColor;
  justify-content: center;
  line-height: inherit;
  width: 1em;

  &::before {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    text-rendering: auto;
  }
}
</style>
