<script lang="ts">
export type ApiProvidersMetric = {
  label: string;
  counter: number;
};
</script>

<script setup lang="ts">
import { computed } from 'vue';

import DashboardConnection from '../DashboardConnection/DashboardConnection.vue';
import DashboardConnectionItem from '../DashboardConnectionItem/DashboardConnectionItem.vue';
import DashboardConnectionItemSkeleton from '../DashboardConnectionItemSkeleton/DashboardConnectionItemSkeleton.vue';

const props = defineProps<{
  metrics?: ApiProvidersMetric[];
  placeholder?: boolean;
  skeleton?: boolean;
  compact?: boolean;
}>();

const isConnected = computed(() => {
  return !!(props.metrics && props.metrics.length);
});
</script>

<template>
  <DashboardConnection
    icon="cloud-data-transfer-outline"
    hide-connection
    :placeholder="placeholder"
    :compact="compact"
  >
    <template #title>API providers</template>

    <template v-if="isConnected && !skeleton">
      <DashboardConnectionItem v-for="(metric, index) in metrics" :key="index">
        {{ metric.label }}
        <template #additional>{{ metric.counter }}</template>
      </DashboardConnectionItem>
    </template>

    <template v-else-if="!compact">
      <DashboardConnectionItemSkeleton
        v-for="n in 2"
        :key="n"
        :placeholder="!skeleton"
      />
    </template>
  </DashboardConnection>
</template>
