import { type PolicyContent, RemedyType } from '../../../types/content';
import authenticationMechanism from '../plugin/authentication-mechanism';
import smartCaching from '../plugin/smart-caching';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Secure Access Auth',
  comingSoon: false,
  summary:
    'Secures API access with authentication mechanisms and optimizes data retrieval by caching responses.',
  plugins: [authenticationMechanism, smartCaching],
  docsUrl: undefined,
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: "example.com"
    method: "GET"
    remedies:
      - name: "AuthenticationMechanism"
        enabled: true
        config:
          authentication:
            basic:
              username: "user1"
              password: "password1"
      - name: "SmartCaching"
        enabled: true
        config:
          caching:
            request_payload_paths:
              - payload_type: "path_param"
                path: "resourceID"
            ttl_seconds: 600
            max_record_size_bytes: 5120
            max_cache_size_megabytes: 200
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
