<script setup lang="ts">
import { computed, useAttrs } from 'vue';

const props = defineProps<{
  compact?: boolean;
}>();

const attrs = useAttrs();

const computedClasses = computed(() => ({
  'menu-item--clickable': !!attrs.onClick,
  'menu-item--compact': props.compact,
}));
</script>

<template>
  <li class="menu-item" :class="computedClasses" role="menuitem">
    <slot />
  </li>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu-item {
  $self: &;

  color: $color-text-secondary;
  margin-bottom: $space-xxxsmall;
  padding: $space-xxsmall $space-xsmall;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: $color-bg-surface;
    }
  }

  &--compact {
    padding: $space-xxxsmall $space-xsmall;
  }
}
</style>
