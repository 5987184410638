import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useUserStore } from '../stores/user';

export async function useRedirectToOnboarding(callback: () => void) {
  // If in playground mode, skip onboarding
  const isPlayground = import.meta.env.VITE_PLAYGROUND === 'true';
  if (isPlayground) {
    return callback();
  }

  const route = useRoute();
  const userStore = useUserStore();
  await userStore.waitForInitialization();

  const filloutId = 'xxrRmaUu2zus';

  const completedOnboarding = computed(
    () => userStore.user.user_metadata?.completed_onboarding || []
  );
  const hasCompletedOnboarding = computed(() =>
    completedOnboarding.value.includes(filloutId)
  );

  // If user has logged in before, proceed
  if (userStore.user.logins_count > 1) {
    return callback();
  }

  // If completed param existings, add to the user metadata and proceed to route
  if (route.query.completed === filloutId && !hasCompletedOnboarding.value) {
    const result = await userStore.updateUser({
      user_metadata: {
        ...userStore.user.user_metadata,
        completed_onboarding: [
          ...(userStore.user.user_metadata?.completed_onboarding || []),
          filloutId,
        ],
      },
    });

    if (result) {
      userStore.user = result;
    }
  }

  // If reset_completed param exists, remove from user metadata and proceed to route
  if (
    route.query.reset_completed === filloutId &&
    hasCompletedOnboarding.value
  ) {
    const result = await userStore.updateUser({
      user_metadata: {
        ...userStore.user.user_metadata,
        completed_onboarding:
          userStore.user.user_metadata?.completed_onboarding.filter(
            (id: string) => id !== filloutId
          ),
      },
    });

    if (result) {
      userStore.user = result;
    }
  }

  // If use has completed onboarding, proceed to route
  if (hasCompletedOnboarding.value) {
    return callback();

    // If user is logging in for the first time, redirect to onboarding
  } else {
    const protocol = import.meta.env.VITE_BASE_URL.includes('https')
      ? 'https'
      : 'http';
    const source = import.meta.env.VITE_BASE_URL
      ? import.meta.env.VITE_BASE_URL.replace(/^https?:\/\//, '')
      : 'app.lunar.dev';
    const email = encodeURIComponent(userStore.user.email || '');

    window.location.href = `https://form.fillout.com/t/${filloutId}?protocol=${protocol}&source=${source}&email=${email}`;
  }
}
