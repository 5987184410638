<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const badgeVariants = {
  variant: {
    default: 'badge--default',
    success: 'badge--success',
    ['success-subtle']: 'badge--success-subtle',
    danger: 'badge--danger',
    ['danger-subtle']: 'badge--danger-subtle',
    warning: 'badge--warning',
    ['warning-subtle']: 'badge--warning-subtle',
    info: 'badge--info',
    ['info-subtle']: 'badge--info-subtle',
    brand: 'badge--brand',
    ['brand-subtle']: 'badge--brand-subtle',
    ['brand-secondary']: 'badge--brand-secondary',
    ['brand-secondary-subtle']: 'badge--brand-secondary-subtle',
  },
  size: {
    xsmall: 'badge--xsmall',
    small: 'badge--small',
    medium: 'badge--medium',
  },
  chip: {
    true: 'badge--chip',
  },
};

const classes = cva({ variants: badgeVariants });

export type BlockProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

withDefaults(
  defineProps<{
    label?: string;
    variant?: BlockProps['variant'];
    size?: BlockProps['size'];
    chip?: boolean;
  }>(),
  {
    variant: 'default',
    size: 'medium',
  }
);

const slots = useSlots();

const hasLeftIcon = computed(() => !!slots.leftIcon);
const hasRightIcon = computed(() => !!slots.rightIcon);
</script>

<template>
  <span class="badge" :class="classes({ variant, size, chip })">
    <BaseLayoutIcons size="small" :icon-size="16">
      <template v-if="hasLeftIcon" #leftIcon>
        <slot name="leftIcon" />
      </template>
      <template #default>
        <span class="badge__text">
          <slot name="default">{{ label }}</slot>
        </span>
      </template>
      <template v-if="hasRightIcon" #rightIcon>
        <slot name="rightIcon" />
      </template>
    </BaseLayoutIcons>
  </span>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.badge {
  align-items: center;
  border-radius: $space-xxxsmall;
  color: $color-text-inverted;
  cursor: default;
  font-weight: $font-weight-bold;
  display: inline-flex;
  line-height: 1;
  text-wrap: nowrap;

  /**
   * Variant modifiers
   */
  &--default {
    background-color: $white;
    box-shadow: inset 0 0 0 1px $color-border-default;
    color: $color-text-secondary;
  }
  &--success {
    background-color: $color-bg-success;
    color: $color-text-inverted;
  }
  &--success-subtle {
    background-color: $color-bg-success-subtle;
    color: $color-text-success;
  }
  &--danger {
    background-color: $color-bg-danger;
    color: $color-text-inverted;
  }
  &--danger-subtle {
    background-color: $color-bg-danger-subtle;
    color: $color-text-danger;
  }
  &--warning {
    background-color: $color-bg-warning;
    color: $color-text-inverted;
  }
  &--warning-subtle {
    background-color: $color-bg-warning-subtle;
    color: $color-text-warning;
  }
  &--info {
    background-color: $color-bg-info;
    color: $color-text-inverted;
  }
  &--info-subtle {
    background-color: $color-bg-info-subtle;
    color: $color-text-info;
  }
  &--brand {
    background-color: $color-bg-brand;
    color: $color-text-inverted;
  }
  &--brand-subtle {
    background-color: $color-bg-brand-subtle;
    color: $color-text-brand;
  }
  &--brand-secondary {
    background-color: $color-bg-brand-secondary;
    color: $color-text-inverted;
  }
  &--brand-secondary-subtle {
    background-color: $color-bg-brand-secondary-subtle;
    color: $color-text-brand-secondary;
  }

  /**
   * Chip modifier
   */
  &--chip {
    border-radius: $space-small;
  }

  /**
   * Size modifiers
   */
  &--xsmall {
    font-size: $font-size-xxxsmall;
    height: $space-small - $space-xxxsmall;
    padding: $space-xxsmall $space-xxsmall;
  }
  &--small {
    font-size: $font-size-xxsmall;
    height: $scale-xsmall;
    padding: $space-xxxsmall $space-xxsmall;
  }
  &--medium {
    font-size: $font-size-xsmall;
    height: $scale-xsmall;
    padding: $space-xxxxsmall $space-xxsmall;
  }
}
</style>
