<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const loaderVariants = {
  size: {
    xsmall: 'loader--xsmall',
    small: 'loader--small',
    medium: 'loader--medium',
    large: 'loader--large',
    xlarge: 'loader--xlarge',
  },
};

const classes = cva({ variants: loaderVariants });

export type LoaderProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    size?: LoaderProps['size'];
  }>(),
  {
    size: 'xsmall',
  }
);
</script>

<template>
  <span class="loader" :class="classes({ size })">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </span>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.loader {
  display: inline-block;
  position: relative;

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-style: solid;
    border-color: currentColor;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &--xsmall {
    height: $scale-xsmall;
    width: $scale-xsmall;

    span {
      height: $scale-xsmall;
      width: $scale-xsmall;
      border-width: 2px;
    }
  }

  &--small {
    height: $scale-small;
    width: $scale-small;

    span {
      height: $scale-small;
      width: $scale-small;
      border-width: 2px;
    }
  }

  &--medium {
    height: $scale-medium;
    width: $scale-medium;

    span {
      height: $scale-medium;
      width: $scale-medium;
      border-width: 3px;
    }
  }

  &--large {
    height: $scale-large;
    width: $scale-large;

    span {
      height: $scale-large;
      width: $scale-large;
      border-width: 4px;
    }
  }

  &--xlarge {
    height: $scale-xlarge;
    width: $scale-xlarge;

    span {
      height: $scale-xlarge;
      width: $scale-xlarge;
      border-width: 6px;
    }
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
