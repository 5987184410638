import { type PolicyContent, RemedyType } from '../../../types/content';
import harExporter from '../plugin/har-exporter';
import authenticationMechanism from '../plugin/authentication-mechanism';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Participated PIIs Audits',
  comingSoon: true,
  summary:
    'Audits personally identifiable information (PII) in API requests and responses for compliance and security purposes.',
  plugins: [authenticationMechanism, harExporter],
  docsUrl: undefined,
  details: `
By auditing PIIs in API requests and responses using the Authentication Mechanism and HAR Exporter, this policy ensures compliance with data privacy regulations and enhances security by monitoring and tracking the usage of sensitive information.
`,
};

export default content;
