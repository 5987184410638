<script setup lang="ts">
import { computed, ref, useSlots, watch, defineAsyncComponent } from 'vue';

const Calendar = defineAsyncComponent(() => import('primevue/calendar'));

import BaseFormField, {
  type BaseFormFieldProps,
} from '../BaseFormField/BaseFormField.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';

type CalendarModelValue = string | Date | string[] | Date[] | undefined | null;

const props = withDefaults(
  defineProps<
    BaseFormFieldProps & {
      /**
       * Used for v-model.
       */
      modelValue?: CalendarModelValue;
      /**
       * Defines the quantity of the selection.
       * @defaultValue single
       */
      selectionMode?: 'single' | 'multiple' | 'range';
      /**
       * Whether to allow entering the date manually via typing.
       * @defaultValue true
       */
      manualInput?: boolean;
      /**
       * Placeholder text for the input.
       */
      placeholder?: string;
      /**
       * Format of the date.
       */
      dateFormat?: string;
      /**
       * The minimum selectable date.
       */
      minDate?: Date | undefined;
      /**
       * The maximum selectable date.
       */
      maxDate?: Date | undefined;
    }
  >(),
  {
    dateFormat: 'dd/mm/yy',
  }
);

const emit = defineEmits<{
  'update:modelValue': [value: CalendarModelValue];
  show: [];
  hide: [];
}>();

const value = ref(props.modelValue);

const slots = useSlots();

const hasInvalidMessage = computed(() => !!slots.invalidMessage);
const hasNote = computed(() => !!slots.default);
const hasLabel = computed(() => !!slots.label);

watch(
  () => value.value,
  () => {
    emit('update:modelValue', value.value);
  },
  { immediate: true }
);

watch(
  () => props.modelValue,
  () => {
    value.value = props.modelValue;
  }
);
</script>

<template>
  <BaseFormField
    class="calendar"
    :label="label"
    :required="required"
    :invalid="invalid"
    :invalid-message="invalidMessage"
    :validator="validator"
    :disabled="disabled"
    :note="note"
  >
    <template v-if="hasLabel" #label><slot name="label" /></template>
    <template #default="{ formFieldId, passthroughAttrs }">
      <div class="calendar__wrapper">
        <span class="calendar__icon" aria-hidden="true">
          <BaseIcon name="date-range" />
        </span>
        <Calendar
          :id="formFieldId"
          :selection-mode="selectionMode"
          :manual-input="manualInput"
          :placeholder="placeholder"
          :date-format="dateFormat"
          :min-date="minDate"
          :max-date="maxDate"
          v-bind="passthroughAttrs"
          v-model="value"
          class="calendar__root"
          :pt="{
            input: {
              class: 'calendar__input',
            },
            panel: {
              class: 'calendar__panel',
            },
            header: {
              class: 'calendar__header',
            },
            container: {
              class: 'calendar__container',
            },
          }"
          @show="emit('show')"
          @hide="emit('hide')"
        />
      </div>
    </template>
    <template v-if="hasInvalidMessage" #invalidMessage
      ><slot name="invalidMessage"
    /></template>
    <template v-if="hasNote" #note><slot /></template>
  </BaseFormField>
</template>

<style lang="scss">
@use '../../assets/styles/primevue/themes/lunar/theme' as *;
@use '../../assets/styles/utils' as *;

.calendar {
  &__wrapper {
    position: relative;
    width: 100%;
  }

  &__icon {
    color: $color-text-tertiary;
    height: $font-size-medium;
    font-size: $font-size-medium;
    line-height: $font-size-small;
    left: $space-xxsmall;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: $font-size-medium;
  }

  &__root {
    width: 100%;
  }

  &__input {
    appearance: none;
    background-color: $color-bg-default;
    border: 1px solid $color-border-default;
    color: $color-text-default;
    border-radius: $space-xxsmall;
    box-shadow: none;
    font-size: $font-size-small;
    line-height: $font-size-default;
    outline: none;
    padding: $space-xxsmall $space-small;
    text-indent: $space-small + 2px;
    width: 100%;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      appearance: none;
    }

    &::placeholder {
      color: $color-text-tertiary;
    }

    &:hover {
      border-color: $color-border-default-strong;
    }

    &:focus {
      border-color: $color-border-brand;
      box-shadow: none;
    }

    &[disabled] {
      background-color: $color-bg-disabled;
      color: $color-text-disabled;

      &:hover,
      &:focus {
        border-color: $color-border-default;
      }
    }
  }

  &__panel {
    border: 1px solid $color-border-default;
    border-radius: $space-xxsmall;
    box-shadow: $elevation-xsmall;
    padding: 0;

    table {
      font-size: $font-size-small;
      margin: 0;

      td {
        span[aria-disabled='true'] {
          color: $color-text-disabled;
          opacity: 0.5;
        }
      }
    }
  }

  &__header {
    border-radius: $space-xxsmall $space-xxsmall 0 0;
    font-size: $font-size-small;
    margin-bottom: $space-xxsmall;
  }

  &__container {
    padding: $space-xxsmall;
  }
}
</style>
