<script lang="ts">
import { type Placement } from '@floating-ui/vue';

export type ActionsPlacement = Placement;
</script>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { onClickOutside, type MaybeElement } from '@vueuse/core';
import { useFloating, flip, shift, offset, autoUpdate } from '@floating-ui/vue';

import BaseHeading from '../../components/BaseHeading/BaseHeading.vue';
import BaseButton from '../../components/BaseButton/BaseButton.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    content?: string;
    placement?: ActionsPlacement;
    disabled?: boolean;
    interactive?: boolean;
  }>(),
  {
    trigger: 'hover',
    placement: 'right',
  }
);

const reference = ref<MaybeElement>();
const floating = ref<HTMLElement>();
const isOpen = defineModel({
  default: false,
});

const { floatingStyles, placement: floatingPlacement } = useFloating(
  reference,
  floating,
  {
    placement: props.placement,
    middleware: [offset(4), flip(), shift({ padding: 16 })],
    whileElementsMounted: autoUpdate,
  }
);

const place = computed(() => floatingPlacement.value.split('-')[0]);

const computedClasses = computed(() => ({
  [`actions--${place.value}`]: true,
  'actions--open': isOpen.value,
  'actions--disabled': props.disabled,
  'actions--interactive': props.interactive,
}));

const toggleActions = () => {
  if (props.interactive) {
    isOpen.value = !isOpen.value;
  }
};

const closeActions = () => {
  isOpen.value = false;
};

onClickOutside(
  floating,
  () => {
    isOpen.value = false;
  },
  { ignore: [reference] }
);
</script>

<template>
  <div class="actions" :class="computedClasses">
    <div
      ref="reference"
      class="actions__trigger"
      aria-describedby="tooltip"
      @click.stop="toggleActions"
      @keydown.esc="closeActions"
    >
      <slot />
    </div>
    <div
      ref="floating"
      class="actions__content"
      role="tooltip"
      :style="floatingStyles"
    >
      <slot name="title"
        ><BaseHeading v-if="title" class="actions__title" :level="5">{{
          title
        }}</BaseHeading></slot
      >
      <slot name="content">{{ content }}</slot>
      <div v-if="interactive" class="actions__close">
        <BaseButton
          variant="ghost"
          size="small"
          aria-label="Close"
          @click="closeActions"
          @keydown.enter="closeActions"
        >
          <template #leftIcon>
            <BaseIcon name="close" title="Close" />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.actions {
  $self: &;

  display: inline-flex;
  position: relative;
  width: 100%;

  &:not(&--disabled):not(&--interactive):hover,
  &--interactive#{$self}--open {
    #{$self} {
      &__content {
        opacity: 1;
        top: 0;
        visibility: visible;
        z-index: layer('tooltip');
      }
    }
  }

  &__trigger {
    display: flex;
    width: 100%;
  }

  &__title {
    margin-bottom: $space-medium;
  }

  &__content {
    background-color: $white;
    border-radius: $space-xxxsmall;
    border: 1px solid $color-border-default;
    box-shadow: $elevation-small;
    color: $color-text-default;
    font-weight: $font-weight-regular;
    min-height: 48px;
    left: 0;
    opacity: 0;
    padding: $space-small $space-small;
    position: absolute;
    top: 0;
    transition: all 0.25s ease-in-out;
    width: max-content;
    visibility: hidden;
    z-index: layer('tooltip');
  }

  &__close {
    cursor: pointer;
    font-size: $font-size-xsmall;
    position: absolute;
    right: $space-xxsmall;
    top: $space-xxsmall;
    z-index: calc(layer('base') + 1);

    &:hover {
      color: $color-text-brand;
    }
  }
}
</style>
