<script setup lang="ts">
import { type User } from '@auth0/auth0-vue';
import type { UserPermissions } from '../../types';
import type { OrganizationDAO } from '@monorepo/shared-model/src/user';
import type { PlaygroundVisitor } from '../../stores/demo/types';

import AppNavigation from '../AppNavigation/AppNavigation.beta.vue';
import AppAccountDropdown from '../AppAccountDropdown/AppAccountDropdown.beta.vue';
import AppCommunity from '../AppCommunity/AppCommunity.beta.vue';
import AppConnectionStatus from '../AppConnectionStatus/AppConnectionStatus.vue';
import AppPlaygroundPricing from '../AppPlaygroundPricing/AppPlaygroundPricing.vue';
import AppScheduleCall from '../AppScheduleCall/AppScheduleCall.vue';

import BaseLogo from '../BaseLogo/BaseLogo.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';
import BaseDivider from '../BaseDivider/BaseDivider.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';

import { useUserStore } from '../../stores/user';

defineProps<{
  user: User;
  org?: OrganizationDAO;
  permissions?: UserPermissions;
  playgroundVisitor?: PlaygroundVisitor;
  collapsed?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  logout: [];
}>();

const userStore = useUserStore();
</script>

<template>
  <div class="app-sidebar">
    <div class="app-sidebar__body">
      <div class="app-sidebar__logo-container">
        <RouterLink to="/" custom v-slot="{ navigate }">
          <BaseLogo
            class="app-sidebar__logo"
            mode="dark"
            :compact="collapsed"
            @click="navigate"
          />
          <BaseBadge
            v-if="!collapsed"
            class="app-sidebar__beta"
            variant="brand-secondary"
            size="xsmall"
            chip
            >Beta</BaseBadge
          >
        </RouterLink>
      </div>

      <AppNavigation :collapsed="collapsed" />
    </div>

    <div class="app-sidebar__footer">
      <AppCommunity
        v-if="!userStore.permissions?.isPlayground"
        :collapsed="collapsed"
      />
      <template v-else>
        <BaseLayoutGap direction="column" size="xsmall">
          <AppPlaygroundPricing :compact="collapsed" />
          <AppScheduleCall
            :playground-visitor="playgroundVisitor"
            :compact="collapsed"
          />
        </BaseLayoutGap>
      </template>

      <BaseDivider mode="dark" size="small" />

      <BaseLayoutGap
        size="small"
        alignment="space-between"
        :direction="collapsed ? 'column' : 'row'"
      >
        <template v-if="!userStore.permissions?.isPlayground">
          <AppAccountDropdown
            :user="user"
            :org="org"
            :permissions="permissions"
            :disabled="disabled"
            mode="dark"
            :compact="collapsed"
            @logout="emit('logout')"
          ></AppAccountDropdown>

          <BaseDivider
            v-if="!collapsed"
            mode="dark"
            size="small"
            direction="vertical"
          />
        </template>

        <AppConnectionStatus :compact="collapsed" />
      </BaseLayoutGap>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-sidebar {
  $self: &;

  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $space-xxlarge 0 $space-large $space-large;

  &__logo-container {
    align-items: center;
    display: flex;
    margin-bottom: $space-xlarge;
    padding: 0 $space-xxxsmall;
    position: relative;
    width: 166px;
  }

  &__logo {
    cursor: pointer;
    width: 130px;
  }

  &__beta {
    position: absolute;
    right: -$space-xxsmall;
    top: 0;
  }
}
</style>
