<script setup lang="ts">
import { computed, ref } from 'vue';

import type { PlaygroundVisitor } from '../../stores/demo/types';

import BaseBlock from '../BaseBlock/BaseBlock.vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';
import BaseDialog from '../BaseDialog/BaseDialog.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseHeading from '../BaseHeading/BaseHeading.vue';
import { useStorage } from '@vueuse/core';

const props = defineProps<{
  compact?: boolean;
  skeleton?: boolean;
}>();

const playgroundVisitor = useStorage<PlaygroundVisitor>(
  'playground-visitor',
  {}
);
const open = ref(false);

const computedClasses = computed(() => ({
  'app-playground-pricing--compact': props.compact,
}));

const onShowPricing = () => {
  open.value = !open.value;
};

const onSignUp = () => {
  window.location.href = 'https://app.lunar.dev';
};

const onBookDemo = () => {
  if (!playgroundVisitor.value.redirectUri) return;

  window.open(playgroundVisitor.value.redirectUri, '_blank');
};
</script>

<template>
  <BaseBlock class="app-playground-pricing" :class="computedClasses">
    <div class="app-playground-pricing__item app-playground-pricing__item--cta">
      <BaseTooltip
        class="app-playground-pricing__tooltip"
        content="How our pricing works"
        placement="right"
        :disabled="!compact"
      >
        <div class="app-playground-pricing__container" @click="onShowPricing">
          <BaseLayoutIcons size="small" :icon-size="16">
            <template #leftIcon>
              <BaseIcon class="app-playground-pricing__icon" name="insights" />
            </template>
            <span v-if="!compact">Try with your data</span>
          </BaseLayoutIcons>
        </div>
      </BaseTooltip>
    </div>
  </BaseBlock>
  <BaseDialog v-model:open="open" closeable no-padding>
    <div class="app-playground-pricing__plans">
      <BaseLayoutGap
        class="app-playground-pricing-plan app-playground-pricing-plan--secondary"
        direction="column"
        size="large"
      >
        <header class="app-playground-pricing-plan__header">
          <div class="app-playground-pricing-plan__plan">Free</div>
          <BaseHeading
            class="app-playground-pricing-plan__price"
            level="title"
            family="lato"
            weight="regular"
            >$0 <span>/month</span></BaseHeading
          >
        </header>

        <ul class="app-playground-pricing-plan__list">
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              Up to <strong>10</strong> connected API providers
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              Unlimited API traffic monitoring
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              <strong>10,000</strong> managed calls with Lunar flows per month
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              <strong>30</strong> days metrics retention
            </BaseLayoutIcons>
          </li>
        </ul>

        <BaseButton
          class="app-playground-pricing-plan__secondary-cta"
          variant="secondary"
          fill-width
          @click="onSignUp"
          >Sign up</BaseButton
        >

        <ul class="app-playground-pricing-plan__list">
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Control plane access
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Single Lunar gateway instance
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Standard Lunar flows
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Community support
            </BaseLayoutIcons>
          </li>
        </ul>
      </BaseLayoutGap>
      <BaseLayoutGap
        class="app-playground-pricing-plan"
        direction="column"
        size="large"
      >
        <header class="app-playground-pricing-plan__header">
          <div class="app-playground-pricing-plan__plan">Team</div>
          <BaseHeading
            class="app-playground-pricing-plan__price"
            level="title"
            family="lato"
            weight="regular"
            >$500 <span>/month</span></BaseHeading
          >
        </header>

        <ul class="app-playground-pricing-plan__list">
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              Up to <strong>25</strong> connected API providers
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              Unlimited API traffic monitoring
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              <strong>300,000</strong> managed calls with Lunar flows per month
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              <strong>90</strong> days metrics retention
            </BaseLayoutIcons>
          </li>
        </ul>

        <BaseButton
          class="app-playground-pricing-plan__cta"
          variant="primary"
          fill-width
          @click="onBookDemo"
          >Schedule a call</BaseButton
        >

        <ul class="app-playground-pricing-plan__list">
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Control plane access
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Multi-gateways across environments
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Lunar gateway redundancy and failover
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Standard & premium Lunar flows
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Production support
            </BaseLayoutIcons>
          </li>
        </ul>
      </BaseLayoutGap>
      <BaseLayoutGap
        class="app-playground-pricing-plan"
        direction="column"
        size="large"
      >
        <header class="app-playground-pricing-plan__header">
          <div class="app-playground-pricing-plan__plan">Pro</div>
          <BaseHeading
            class="app-playground-pricing-plan__price"
            level="title"
            family="lato"
            weight="regular"
            >Custom</BaseHeading
          >
        </header>

        <ul class="app-playground-pricing-plan__list">
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              Up to <strong>100</strong> connected API providers
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              Unlimited API traffic monitoring
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              <strong>5,000,000</strong> managed calls with Lunar flows per
              month
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon app-playground-pricing-plan__icon--check"
                  name="check"
                />
              </template>
              <strong>120</strong> days metrics retention
            </BaseLayoutIcons>
          </li>
        </ul>

        <BaseButton
          class="app-playground-pricing-plan__cta"
          variant="primary"
          fill-width
          @click="onBookDemo"
          >Get a quote</BaseButton
        >

        <ul class="app-playground-pricing-plan__list">
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Control plane access
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Multi-gateways across environments
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Lunar gateway redundancy and failover
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Standard, premium & custom Lunar flows
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Version control
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              SSO
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              RBAC
            </BaseLayoutIcons>
          </li>
          <li class="app-playground-pricing-plan__item">
            <BaseLayoutIcons size="small" :icon-size="16">
              <template #leftIcon>
                <BaseIcon
                  class="app-playground-pricing-plan__icon"
                  name="plus"
                />
              </template>
              Premium support
            </BaseLayoutIcons>
          </li>
        </ul>
      </BaseLayoutGap>
    </div>
  </BaseDialog>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-playground-pricing {
  $self: &;

  &__item {
    border-radius: $space-xxsmall;
    font-size: $font-size-xsmall;
    width: 100%;

    &--cta {
      #{$self} {
        &__container {
          color: $navy-600;

          &:hover {
            background-color: rgba($navy-100, 0.8);
          }
        }
        &__icon {
          color: $navy-500;
        }
      }
    }
  }

  &__tooltip {
    font-size: $font-size-small;
    width: 100%;
  }

  &__container {
    align-items: center;
    border-radius: $space-xxsmall;
    color: $color-text-secondary;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: $space-xxsmall $space-large;
    text-decoration: none;
    width: 100%;

    &:hover {
      background-color: $color-bg-surface;
    }
  }

  &__plans {
    display: flex;
  }

  &--compact {
    align-items: center;
    display: flex;
    height: $scale-large + $scale-xtiny;
    width: $scale-large + $scale-xtiny;

    #{$self} {
      &__item {
        &--cta {
          #{$self} {
            &__container {
              padding: $space-xxsmall $space-xxsmall;
            }
          }
        }
      }
    }
  }
}

.app-playground-pricing-plan {
  $self: &;

  padding: $space-xlarge;

  &__plan {
    color: $color-text-secondary;
    font-size: $font-size-xxsmall;
    text-transform: uppercase;
  }

  &__price {
    span {
      font-size: $font-size-default;
      line-height: 1;
    }
  }

  &__secondary-cta {
    border: 1px solid $color-border-brand;

    &:hover {
      background-color: $pinko-50 !important;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    font-size: $font-size-xsmall;
    margin-bottom: $space-xxxsmall;
    text-wrap: nowrap;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__icon {
    color: $neutral-350;

    &--check {
      color: $pine-500;
    }
  }

  &--secondary {
    background-color: $neutral-100;
    border-right: 1px solid $color-border-brand;
    border-radius: $space-xxsmall 0 0 $space-xxsmall;
  }
}
</style>
