<script lang="ts">
import { cva, cx, type VariantProps } from 'cva';
import { FeatureFlag } from '../../types';

export const appLayoutSidebarVariants = {
  size: {
    default: undefined,
    small: 'app-layout-sidebar--small',
  },
  collapsible: {
    true: 'app-layout-sidebar--collapsible',
  },
  mode: {
    dark: 'app-layout-sidebar--mode-dark',
  },
};

const classes = cva({ variants: appLayoutSidebarVariants });

export type AppLayoutSidebarProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStorage } from '@vueuse/core';
import { useUserStore } from '../../stores/user';

import BaseIcon from '../BaseIcon/BaseIcon.vue';

const props = defineProps<{
  id?: string;
  size?: AppLayoutSidebarProps['size'];
  collapsible?: boolean;
  sticky?: boolean;
  fixed?: boolean;
  mode?: AppLayoutSidebarProps['mode'];
}>();

const userStore = useUserStore();

const isCollapsed = props.id ? useStorage(props.id, false) : ref(false);
const gutter = ref<HTMLElement>();

const computedClasses = computed(() => ({
  'app-layout-sidebar--collapsed': isCollapsed.value,
  'app-layout-sidebar--sticky': props.sticky,
  'app-layout-sidebar--fixed': props.fixed,
  'app-layout-sidebar--alt': userStore.hasFeatureFlag(
    FeatureFlag.AltNavigation
  ),
}));

const onClick = () => {
  isCollapsed.value = !isCollapsed.value;
};
</script>

<template>
  <div
    ref="root"
    class="app-layout-sidebar"
    :class="cx(classes({ size, collapsible, mode }), computedClasses)"
  >
    <div ref="sidebar" class="app-layout-sidebar__sidebar">
      <slot name="sidebar" :collapsed="isCollapsed" />

      <div
        v-if="props.collapsible"
        ref="gutter"
        class="app-layout-sidebar__gutter"
        @click="onClick"
      >
        <BaseIcon
          class="app-layout-sidebar__handle app-layout-sidebar__handle--neutral"
          name="handle"
        />
        <BaseIcon
          class="app-layout-sidebar__handle app-layout-sidebar__handle--left"
          name="handle-left"
        />
        <BaseIcon
          class="app-layout-sidebar__handle app-layout-sidebar__handle--right"
          name="handle-right"
        />
      </div>
    </div>
    <div ref="main" class="app-layout-sidebar__main">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

$sidebar-width-small: 240px;
$sidebar-width: 320px;
$collapsed-sidebar-width: 62px;
$gutter-width: $space-large;

.app-layout-sidebar {
  $self: &;

  flex: 1;
  align-items: flex-start;
  display: flex;
  gap: $space-large;
  flex-direction: row;
  justify-content: flex-start;

  &__sidebar {
    flex-shrink: 0;
    position: relative;
    width: $sidebar-width;
    z-index: layer('overlap');
  }

  &__main {
    display: flex;
    height: 100%;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
  }

  &__gutter {
    position: absolute;
    width: $space-large;
    cursor: pointer;
    height: calc(100vh - $app-header-height - $space-large);
    user-select: none;
    right: -$space-large;
    top: 0;

    &:hover {
      #{$self}__handle {
        &--neutral {
          display: none;
        }
        &--left {
          display: block;
        }
        &--right {
          display: none;
        }
      }
    }
  }

  &__handle {
    color: $color-border-default-strong;
    font-size: $scale-small;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &--left,
    &--right {
      color: $color-text-success;
      display: none;
    }
  }

  &--small {
    > #{$self}__sidebar {
      width: $sidebar-width-small;
    }
  }

  &--collapsed {
    > #{$self} {
      &__sidebar {
        width: $collapsed-sidebar-width;
      }
    }

    #{$self}__gutter {
      &:hover {
        #{$self}__handle {
          &--neutral {
            display: none;
          }
          &--left {
            display: none;
          }
          &--right {
            display: block;
          }
        }
      }
    }
  }

  &--sticky {
    > #{$self} {
      &__sidebar {
        position: sticky;
        top: calc($app-header-height + $space-large);
        z-index: layer('menu');
      }
    }
  }

  &--alt {
    &#{$self}--fixed {
      > #{$self} {
        &__sidebar {
          position: fixed;
          top: 0;
          z-index: layer('menu');
        }
        &__main {
          margin-left: $sidebar-width-small + $gutter-width;
        }
      }

      &#{$self}--collapsed {
        > #{$self} {
          &__main {
            margin-left: $collapsed-sidebar-width + $gutter-width;
          }
        }
      }
    }
  }

  &--mode-dark {
    #{$self} {
      &__handle {
        color: rgba($color-dark-border-default, 0.2);

        &--left,
        &--right {
          color: $color-bg-brand-secondary;
        }
      }
    }
  }
}
</style>
