<script setup lang="ts">
import { ref } from 'vue';

import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseSkeleton from '../BaseSkeleton/BaseSkeleton.vue';
import BaseTable from '../BaseTable/BaseTable.vue';
import Column from 'primevue/column';

const props = withDefaults(
  defineProps<{
    placeholder?: boolean;
    rows?: number;
  }>(),
  {
    rows: 2,
  }
);

const skeletonValues = ref(
  Array(props.rows).fill({
    name: '',
    totalEndpoints: '',
    totalCalls: '',
    errorRate: '',
    avgRuntime: '',
  })
);
</script>

<template>
  <BaseTable :value="skeletonValues">
    <Column style="padding-right: 32px" />
    <Column field="name" header="API provider" style="width: 20%"
      ><template #body
        ><BaseLayoutGap
          ><BaseSkeleton
            variant="round"
            height="24px"
            :placeholder="placeholder" /><BaseSkeleton
            height="12px"
            width="33%"
            :placeholder="placeholder" /></BaseLayoutGap></template
    ></Column>
    <Column field="totalEndpoints" header="Total endpoints" style="width: 20%"
      ><template #body
        ><BaseSkeleton
          height="12px"
          width="33%"
          :placeholder="placeholder" /></template
    ></Column>
    <Column field="totalCalls" header="Total API calls" style="width: 20%"
      ><template #body
        ><BaseSkeleton
          height="12px"
          width="33%"
          :placeholder="placeholder" /></template
    ></Column>
    <Column field="errorRate" header="Error rate (%)" style="width: 20%"
      ><template #body
        ><BaseSkeleton
          height="12px"
          width="33%"
          :placeholder="placeholder" /></template
    ></Column>
    <Column field="avgRuntime" header="Avg. runtime" style="width: 20%"
      ><template #body
        ><BaseSkeleton
          height="12px"
          width="33%"
          :placeholder="placeholder" /></template
    ></Column>
  </BaseTable>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-overview-table-skeleton {
  $self: &;

  /* Component styles goes here… */
}
</style>
