<script lang="ts">
import { cva, type VariantProps } from 'cva';
import { Spacing } from '../../types';

export const layoutGapVariants = {
  size: Spacing.reduce((obj: Record<string, string>, space: string) => {
    obj[space] = `layout-gap--${space}`;
    return obj;
  }, {}) as Record<string, string>,
  direction: {
    row: 'layout-gap--row',
    column: 'layout-gap--column',
  },
  alignment: {
    center: 'layout-gap--center',
    left: 'layout-gap--left',
    right: 'layout-gap--right',
    top: 'layout-gap--top',
    bottom: 'layout-gap--bottom',
    stretch: 'layout-gap--stretch',
    'space-between': 'layout-gap--space-between',
  },
  wrap: {
    true: 'layout-gap--wrap',
  },
};

const classes = cva({
  variants: layoutGapVariants,
  defaultVariants: {
    size: 'xsmall',
    direction: 'row',
    alignment: 'left',
  },
});

export type LayoutGapProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    /**
     * The size of the gap.
     */
    size?: LayoutGapProps['size'];
    /**
     * The direction of the elements.
     */
    direction?: LayoutGapProps['direction'];
    /**
     * The alignment of the elements.
     */
    alignment?: LayoutGapProps['alignment'];
    /**
     * Should the elements wrap the container?
     */
    wrap?: boolean;
    /**
     * The root element defaults to a `div`, however you can
     * use this component for spacing lists of elements so this
     * prop allows you to change the root element to a `ul` or `ol`.
     */
    wrapper?: string;
  }>(),
  {
    wrapper: 'div',
  }
);
</script>

<template>
  <component
    :is="wrapper"
    class="layout-gap"
    :class="classes({ size, direction, alignment, wrap })"
  >
    <!-- @slot Any elements supplied through this slot will be evenly spaced based on the `size` prop. -->
    <slot />
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.layout-gap {
  $self: &;

  flex: 1;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;

  &--xxxsmall {
    gap: $space-xxxsmall;
  }

  &--xxsmall {
    gap: $space-xxsmall;
  }

  &--xsmall {
    gap: $space-xxsmall;
  }

  &--small {
    gap: $space-small;
  }

  &--medium {
    gap: $space-medium;
  }

  &--large {
    gap: $space-large;
  }

  &--xlarge {
    gap: $space-xlarge;
  }

  &--xxlarge {
    gap: $space-xxlarge;
  }

  &--xxxlarge {
    gap: $space-xxxlarge;
  }

  &--row {
    flex-direction: row;

    &#{$self}--center {
      align-items: center;
    }

    &#{$self}--stretch {
      align-items: stretch;
    }

    &#{$self}--left {
      justify-content: flex-start;
    }

    &#{$self}--top {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &#{$self}--right {
      justify-content: flex-end;
    }

    &#{$self}--bottom {
      align-items: flex-end;
      justify-content: flex-start;
    }

    &#{$self}--space-between {
      justify-content: space-between;
      width: 100%;
    }
  }

  &--column {
    flex-direction: column;

    &#{$self}--center {
      align-items: center;
      width: 100%;
    }

    &#{$self}--stretch {
      height: 100%;
      align-items: stretch;
      width: 100%;
    }

    &#{$self}--left,
    &#{$self}--top {
      align-items: flex-start;
    }

    &#{$self}--right,
    &#{$self}--bottom {
      align-items: flex-end;
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }
}
</style>
