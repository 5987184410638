import type { ProxyContent } from '../../../../types/content';

const content: ProxyContent = {
  name: 'EKS',
  steps: [
    {
      title: 'Clone Lunar Repository to access Terraform Script',
      details: `
\`\`\`powershell
git clone https://github.com/TheLunarCompany/lunar.git
\`\`\`

Navigate to the deploy AWS EKS directory:

\`\`\`powershell
cd lunar/deploy/aws-eks
\`\`\`
`,
    },
    {
      title: 'Initialize Terraform',
      details: `
\`\`\`powershell
terraform init
\`\`\`
`,
    },
    {
      title: 'Plan the deployment',
      details: `
Now you can plan the deployment by running the following command (replace <eksName> with the name of your EKS cluster):

\`\`\`powershell
terraform plan -input=true -var 'input_eks_name=exampleEksName' -var 'input_tenant_name=#TENANT_NAME#' -var 'lunar_api_key=#API_KEY#'
\`\`\`

There are other variables with default values which you can override. You can find the full list of variables in the \`variables.tf\` file.
`,
    },
    {
      title: 'Apply the deployment',
      details: `
If the plan looks good, you can apply the deployment by running the following command:

\`\`\`powershell
terraform apply
\`\`\`
`,
    },
  ],
};

export default content;
