<script setup lang="ts">
import { useForm, useIsFormDirty } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';

import BaseForm from '../BaseForm/BaseForm.vue';
import BaseTextarea from '../BaseTextarea/BaseTextarea.vue';

import { useUserStore } from '../../stores/user';
import { useToast } from '../../use/toast';
import {
  DiscordChannel,
  FeedbackType,
} from '@monorepo/shared-model/src/webserver-payloads';

const props = withDefaults(
  defineProps<{
    submitLabel?: string;
    submittingLabel?: string;
    textareaPlaceholder?: string;
    channel?: DiscordChannel;
  }>(),
  {
    submitLabel: 'Submit feeback',
    subbmittingLabel: 'Sending feeback…',
    textareaLabel: 'Have a feedback? Tell us!',
    textareaPlaceholder: 'Let us know what you think…',
    channel: DiscordChannel.InternalFeatureRequest,
  }
);

const emits = defineEmits<{
  submitted: [];
}>();

const toast = useToast();
const userStore = useUserStore();

const { defineField, handleSubmit, isSubmitting } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      feedback: yup.string().default('').required(),
    })
  ),
});

const isFormDirty = useIsFormDirty();

const [feedback] = defineField('feedback');

const saveChanges = handleSubmit(async (values, { resetForm }) => {
  if (!feedback.value) return;

  const result = await userStore.sendFeedback({
    target: {
      _type: FeedbackType.Discord,
      channel: props.channel,
    },
    message: feedback.value,
  });

  if (!result) {
    toast.add({
      severity: 'error',
      summary: 'Failed to send feedback.',
      life: 3000,
    });
  } else {
    toast.add({
      severity: 'success',
      summary: 'Feedback sent.',
      life: 3000,
    });

    emits('submitted');

    // Reset form after a short delay which improves the visual
    // experience if the submitted event is being acted upon, e.g.
    // the feeback form is being hidden after submission which a
    // transition. Resetting the form mid-transition causes jank.
    setTimeout(() => {
      resetForm();
    }, 500);
  }
});
</script>

<template>
  <BaseForm
    compact
    :submit-label="submitLabel"
    :submitting-label="submittingLabel"
    v-model:dirty="isFormDirty"
    v-model:submitting="isSubmitting"
    @submit="saveChanges"
  >
    <BaseTextarea
      :placeholder="textareaPlaceholder"
      v-model="feedback"
      rows="3"
    />
  </BaseForm>
</template>
