import { type PluginContent, RemedyType } from '../../../types/content';

const content: PluginContent = {
  type: RemedyType.Plugin,
  icon: 'auth-mechanism',
  name: 'Authentication Mechanism',
  summary:
    'Securely adjust authentication requests and protect sensitive information.',
  category: ['Security'],
  docsUrl:
    'https://docs.lunar.dev/product-features/remedy-plugins/authentication-mechanism',
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: "api.com/login"
    method: "GET"
    remedies:
      - enabled: true
        name: "AuthManagement"
        config:
          authentication:
            account: "auth_1" # The type of authentication to apply

accounts:
  auth_3:
    authentication:
      basic:
        username: "user1"
        password: "password1"

  auth_4:
    authentication:
      o_auth:
        tokens:
            - name: \${{O_AUTH_SECRET_KEY}} # This configuration allows users to load values from the environment variables.
              value: \${{O_AUTH_SECRET_VALUE}}
            - name: \${{O_AUTH_SECRET_KEY2}}
              value: \${{O_AUTH_SECRET_VALUE2}}
  auth_5:
    authentication:
      api_key:
        tokens:
          - name: "user1"
            value: "password1"
          - name: "user2"
            value: "password2"
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
