<script setup lang="ts">
import { format, formatDistanceToNow } from 'date-fns';

import type { PolicyContent } from '../../types/content';

import AppPageDrawerHeading from '../AppPageDrawerHeading/AppPageDrawerHeading.vue';

import BaseHeading from '../BaseHeading/BaseHeading.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseAnchor from '../BaseAnchor/BaseAnchor.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseAccordion from '../BaseAccordion/BaseAccordion.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

import DashboardMetric from '../DashboardMetric/DashboardMetric.vue';
import PluginsInstallationPluginBadge from '../PluginsInstallationPluginBadge/PluginsInstallationPluginBadge.vue';

import { useUserStore } from '../../stores/user';

const props = defineProps<{
  policy: PolicyContent;
  installed?: boolean;
  autoApply?: boolean;
}>();

const emit = defineEmits<{
  applyRemediation: [remedy: PolicyContent];
  removeRemediation: [remedy: PolicyContent];
}>();

const userStore = useUserStore();
const now = new Date();

const onApplyRemediation = () => {
  emit('applyRemediation', props.policy);
};

const onRemoveRemediation = () => {
  emit('removeRemediation', props.policy);
};
</script>

<template>
  <div class="plugins-installation-policy">
    <AppPageDrawerHeading
      ><BaseLayoutGap
        class="plugins-installation-policy__header"
        wrapper="span"
        alignment="center"
        ><span>{{ policy.name }}</span
        ><BaseBadge
          v-if="policy.comingSoon"
          variant="brand-secondary"
          size="small"
          chip
          >Coming Soon</BaseBadge
        ></BaseLayoutGap
      ></AppPageDrawerHeading
    >

    <BaseLayoutGap
      class="plugins-installation-policy__header"
      direction="column"
      alignment="center"
      size="large"
    >
      <BaseLayoutGap size="xlarge" alignment="stretch">
        <PluginsInstallationPluginBadge
          v-for="plugin in policy.plugins"
          :key="plugin.name"
          :plugin="plugin"
          class="plugins-installation-policy__plugin-badge"
        />
      </BaseLayoutGap>

      <BaseProse size="small" alignment="center">
        <BaseAnchor
          v-if="policy.docsUrl"
          class="plugins-installation-policy__docs-link"
          :href="policy.docsUrl"
          size="small"
          target="_blank"
          rel="noopener noreferrer"
          ><template #leftIcon><BaseIcon name="book-open-text" /></template
          >Docs</BaseAnchor
        >
      </BaseProse>
    </BaseLayoutGap>

    <template
      v-if="
        userStore.permissions.featureFlag.pluginSuggestions &&
        !policy.comingSoon &&
        autoApply
      "
    >
      <template v-if="installed">
        <BaseLayoutGap direction="column" alignment="center" size="xlarge">
          <BaseProse size="small" alignment="center">{{
            policy.summary
          }}</BaseProse>
          <BaseLayoutGap alignment="top" style="width: 100%">
            <DashboardMetric
              icon="calendar-check-outline"
              title="Installed"
              style="width: 50%"
            >
              <BaseLayoutGap direction="column" alignment="center">
                <BaseHeading
                  :level="3"
                  variant="secondary"
                  weight="semibold"
                  no-margin
                  style="text-align: center; margin-bottom: 6px"
                  >{{
                    formatDistanceToNow(now.setMinutes(now.getMinutes() - 3), {
                      addSuffix: true,
                    })
                  }}</BaseHeading
                >

                <BaseBadge>{{ format(now, 'MMM io, yyyy') }}</BaseBadge>
              </BaseLayoutGap>
            </DashboardMetric>

            <DashboardMetric
              icon="performance-increase-outline"
              title="API calls optimized"
              style="width: 50%"
            >
              <BaseLayoutGap direction="column" alignment="center">
                <BaseHeading
                  :level="2"
                  variant="secondary"
                  weight="semibold"
                  no-margin
                  >78</BaseHeading
                >

                <BaseBadge variant="success-subtle"
                  ><template #leftIcon><BaseIcon name="triangle-up" /></template
                  >20%</BaseBadge
                >
              </BaseLayoutGap>
            </DashboardMetric>
          </BaseLayoutGap>
          <BaseButton variant="link-danger" @click="onRemoveRemediation"
            ><template #leftIcon><BaseIcon name="bin" /></template>Remove
            {{ policy.name }} policy</BaseButton
          >
        </BaseLayoutGap>
      </template>
      <template v-else>
        <BaseLayoutGap direction="column" size="large">
          <BaseLayoutGap direction="column" alignment="center">
            <BaseProse size="small" alignment="center">
              <template #default="{ markdownToHtml }">
                <div v-html="markdownToHtml(policy.summary)" />
              </template>
            </BaseProse>

            <BaseButton @click="onApplyRemediation">Apply policy</BaseButton>
          </BaseLayoutGap>

          <BaseAccordion>
            <template #heading> Manual installation </template>
            <BaseProse size="small">
              <template #default="{ markdownToHtml }">
                <div v-html="markdownToHtml(policy.details)" />
              </template>
            </BaseProse>
          </BaseAccordion>
        </BaseLayoutGap>
      </template>
    </template>
    <template v-else>
      <BaseProse class="plugins-installation-policy__body" size="small">
        <template #default="{ markdownToHtml }">
          <div v-html="markdownToHtml(policy.details)" />
        </template>
      </BaseProse>

      <BaseLayoutGap
        v-if="policy.comingSoon"
        direction="column"
        alignment="center"
        size="xxsmall"
      >
        <BaseHeading :level="5" :semantic-level="3" weight="semibold"
          >Want to be know more about {{ policy.name }} policy?</BaseHeading
        >
        <BaseAnchor
          v-if="policy.comingSoon"
          class="plugins-installation-policy__contact-us"
          href="mailto:info@lunar.dev"
          ><template #leftIcon><BaseIcon name="envelope" /></template>Contact
          Us</BaseAnchor
        >
      </BaseLayoutGap>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use 'sass:math';
@use '../../assets/styles/utils' as *;

.plugins-installation-policy {
  $self: &;

  &__header {
    margin-bottom: $space-small;
  }

  &__title {
    margin-bottom: $space-large;
  }

  &__body {
    margin-bottom: $space-large;
  }

  &__plugin-badge {
    position: relative;

    &:after {
      content: '+';
      color: $color-text-tertiary;
      display: block;
      font-size: $font-size-medium;
      position: absolute;
      right: -(math.div($space-xlarge, 2));
      top: 50%;
      transform: translate(50%, -50%);
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}
</style>
