import { type DuotoneIconProps } from '../components/BaseDuotoneIcon/BaseDuotoneIcon.vue';

export interface StepContent {
  title: string;
  details: string;
}

export enum RemedyType {
  Plugin = 'plugin',
  Policy = 'policy',
  Flow = 'flow',
}

export interface PluginContent {
  type: RemedyType.Plugin;
  icon: DuotoneIconProps['name'];
  name: string;
  summary: string;
  category: string[];
  docsUrl?: string;
  details: string;
}

export interface PolicyContent
  extends Omit<PluginContent, 'icon' | 'category' | 'type'> {
  type: RemedyType.Policy;
  comingSoon?: boolean;
  plugins: PluginContent[];
}

export interface FlowContent {
  type: RemedyType.Flow;
  name: string;
  videoUrl: string;
  summary: string;
}

export type RemedyContent = PluginContent | PolicyContent | FlowContent;

export interface InterceptorContent {
  name: string;
  steps: StepContent[];
}

export interface ProxyContent {
  name: string;
  steps: StepContent[];
}
