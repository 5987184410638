<script setup lang="ts">
import AppFeedbackForm from '../AppFeedbackForm/AppFeedbackForm.vue';

import BaseBlock from '../BaseBlock/BaseBlock.vue';
import BaseBlockHeader from '../BaseBlockHeader/BaseBlockHeader.vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';
import BaseActions from '../BaseActions/BaseActions.vue';
import BaseBlockFooter from '../BaseBlockFooter/BaseBlockFooter.vue';

import { DiscordChannel } from '@monorepo/shared-model/src/webserver-payloads';
import { computed } from 'vue';

const props = defineProps<{
  compact?: boolean;
  skeleton?: boolean;
}>();

const openFeedbackForm = defineModel<boolean>('openFeedbackForm', {
  default: false,
});

const computedClasses = computed(() => ({
  'app-community--compact': props.compact,
}));

const onSubmitted = () => {
  openFeedbackForm.value = false;
};

const toggleFeedbackForm = () => {
  if (props.skeleton) return;

  openFeedbackForm.value = !openFeedbackForm.value;
};
</script>

<template>
  <BaseBlock class="app-community" :class="computedClasses">
    <BaseBlockHeader v-if="!compact" compact
      ><template #title
        >Have an idea? Share it with us!</template
      ></BaseBlockHeader
    >
    <div class="app-community__item">
      <BaseActions
        placement="right"
        v-model="openFeedbackForm"
        title="Feature request"
        interactive
      >
        <template #content>
          <div class="app-community__suggestion-form">
            <AppFeedbackForm
              submit-label="Submit suggestion"
              submitting-label="Suggesting…"
              textarea-placeholder="Describe your idea or feature request in as much detail as you can…"
              :channel="DiscordChannel.InternalFeatureRequest"
              @submitted="onSubmitted"
            />
          </div>
        </template>
        <BaseTooltip
          class="app-community__tooltip"
          content="Make a feature request"
          placement="right"
          :disabled="!compact || openFeedbackForm"
          @click.stop
        >
          <div class="app-community__container" @click="toggleFeedbackForm">
            <BaseLayoutIcons :icon-size="16">
              <template #leftIcon>
                <BaseIcon name="bulb" />
              </template>
              <span v-if="!compact">Feature request</span>
            </BaseLayoutIcons>
          </div>
        </BaseTooltip>
      </BaseActions>
    </div>
    <div class="app-community__item">
      <BaseTooltip
        class="app-community__tooltip"
        content="Join our Discord"
        placement="right"
        :disabled="!compact"
      >
        <a
          class="app-community__container"
          href="https://discord.gg/Kgqu4XQprN"
          target="_blank"
          rel="nofollow noreferrer"
        >
          <BaseLayoutIcons :icon-size="16">
            <template #leftIcon>
              <BaseIcon name="discord" />
            </template>
            <span v-if="!compact">Join our Discord</span>
          </BaseLayoutIcons>
        </a>
      </BaseTooltip>
    </div>
    <BaseBlockFooter v-if="!compact" />
  </BaseBlock>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-community {
  $self: &;

  &__item {
    border-bottom: 1px solid $color-border-default;
    font-size: $font-size-xsmall;
    width: 100%;

    &:first-of-type {
      border-top: 1px solid $color-border-default;
    }
  }

  &__tooltip {
    width: 100%;
  }

  &__container {
    align-items: center;
    color: $color-text-secondary;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: $space-xxsmall $space-large;
    text-decoration: none;
    width: 100%;

    &:hover {
      background-color: $color-bg-surface;
    }
  }

  &__suggestion-form {
    min-width: 360px;
  }

  &--compact {
    #{$self}__item {
      &:first-of-type {
        border-top: none;

        #{$self}__container:hover {
          border-radius: $space-xxsmall $space-xxsmall 0 0;
        }
      }

      &:last-of-type {
        #{$self}__container:hover {
          border-radius: 0 0 $space-xxsmall $space-xxsmall;
        }
      }
    }
  }
}
</style>
