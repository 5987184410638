import { type FlowContent, RemedyType } from '../../../types/content';

const content: FlowContent = {
  type: RemedyType.Flow,
  name: 'OpenAI User Rate Limiting',
  videoUrl: '/videos/openai-user-rate-limiting.mp4',
  summary: 'Rate limit per user for OpenAI API, based on tokens used.',
};

export default content;
