<script setup lang="ts">
import { computed, ref } from 'vue';
import { capitalize } from 'lodash-es';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useForm, useIsFormValid } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';

import BaseDialog from '../BaseDialog/BaseDialog.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseInput from '../BaseInput/BaseInput.vue';
import BaseForm from '../BaseForm/BaseForm.vue';
import BaseHeading from '../BaseHeading/BaseHeading.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';

import { getAsset, getIpAddress } from '../../utils';
import { useFetch } from '../../use/fetch';
import BaseLogo from '../BaseLogo/BaseLogo.vue';
import type { PlaygroundVisitor } from '../../stores/demo/types';

import { useUserStore } from '../../stores/user';
import { FeatureFlag } from '../../types';

const emit = defineEmits<{
  submit: [data: PlaygroundVisitor];
}>();

type RequestDemoPayload = {
  fields: {
    name: string;
    value: string | undefined;
  }[];
  context: {
    hutk?: string;
    pageUri: string;
    pageName: string;
    ipAddress?: string;
  };
};

const userStore = useUserStore();

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      email: yup.string().email().required(),
    })
  ),
});

const [firstname] = defineField('firstname');
const [lastname] = defineField('lastname');
const [email] = defineField('email');
const company = ref('');

const isFormValid = useIsFormValid();

const { get: getCookie } = useCookies();
const hutk = getCookie('hubspotutk');

const payload = ref<RequestDemoPayload>({
  fields: [
    {
      name: 'firstname',
      value: '',
    },
    {
      name: 'lastname',
      value: '',
    },
    {
      name: 'email',
      value: '',
    },
    {
      name: 'company',
      value: '',
    },
  ],
  context: {
    pageUri: 'app.lunar.dev',
    pageName: 'Control Pane - Playground',
  },
});

const backgroundScreenshot = computed(() =>
  userStore.hasFeatureFlag(FeatureFlag.AltNavigation)
    ? getAsset('/images/demo-fog-alt.png')
    : getAsset('/images/demo-fog.png')
);

const onSubmit = handleSubmit(async (values) => {
  // If we have a hubspot cookie, add it to the payload
  if (hutk) {
    payload.value.context.hutk = hutk;
  }

  // Get the IP address and add it to the payload
  const ipAddress = await getIpAddress();
  if (ipAddress) {
    payload.value.context.ipAddress = ipAddress;
  }

  // Populate payload fields
  payload.value.fields = [
    {
      name: 'firstname',
      value: values.firstname,
    },
    {
      name: 'lastname',
      value: values.lastname,
    },
    {
      name: 'email',
      value: values.email,
    },
    {
      name: 'company',
      value: company.value,
    },
  ];

  // Post the payload to the HubSpot API
  const { data } = await useFetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/27005918/297c54b4-709c-464f-b1e2-74a40d7437ec'
  )
    .post(payload.value)
    .json();

  if (data.value.redirectUri) {
    const url = `${data.value.redirectUri}?firstname=${values.firstname}&lastname=${values.lastname}&email=${encodeURI(values.email)}`;

    emit('submit', {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      company: company.value,
      redirectUri: url,
    });
  }
});
</script>

<template>
  <BaseDialog open>
    <BaseLayoutGap
      class="app-playground-access__container"
      direction="column"
      size="xlarge"
      alignment="center"
    >
      <img
        class="app-playground-access__soc2"
        :src="getAsset('/images/badge-soc2.svg')"
        alt="AICPA SOC2 Certified"
        title="We are AICPA SOC2 Certified!"
      />
      <BaseLogo class="app-playground-access__logo" />
      <div class="app-playground-access__content">
        <BaseHeading class="app-playground-access__heading" :level="3"
          >Welcome to our demo app!</BaseHeading
        >
        <BaseProse class="app-playground-access__lead"
          ><p>Start here to see Lunar.dev in action.</p></BaseProse
        >
      </div>
      <BaseForm
        class="app-playground-access__form"
        v-model:valid="isFormValid"
        @submit="onSubmit"
      >
        <BaseLayoutGap direction="column" size="small" alignment="stretch">
          <BaseInput
            class="app-playground-access__field"
            label="Firstname"
            placeholder="Jane"
            v-model="firstname"
            required
            :invalid="!!errors['firstname']"
            :invalid-message="capitalize(errors['firstname'])"
          />
          <BaseInput
            class="app-playground-access__field"
            label="Lastname"
            placeholder="Doe"
            v-model="lastname"
            required
            :invalid="!!errors['lastname']"
            :invalid-message="capitalize(errors['lastname'])"
          />
          <BaseInput
            class="app-playground-access__field"
            label="Email"
            placeholder="jane@example.com"
            v-model="email"
            required
            :invalid="!!errors['email']"
            :invalid-message="capitalize(errors['email'])"
          />
          <BaseInput
            class="app-playground-access__field"
            label="Company name"
            placeholder="Acme Technologies"
            v-model="company"
          />
        </BaseLayoutGap>

        <template #actions="{ disabled }">
          <div class="app-playground-access__actions">
            <BaseButton
              class="app-playground-access__action"
              type="submit"
              fill-width
              :disabled="disabled"
              >Let's go</BaseButton
            >
          </div>
        </template>
      </BaseForm>

      <BaseProse variant="tertiary" size="xsmall" alignment="center"
        >By providing you details, you agree to our
        <a href="https://www.lunar.dev/terms-of-use" target="_blank"
          >terms of use</a
        >
        and
        <a href="https://www.lunar.dev/privacy-policy" target="_blank"
          >privacy policy</a
        >.</BaseProse
      >
    </BaseLayoutGap>

    <template #customMask>
      <img
        class="app-playground-access__background"
        :class="{
          'app-playground-access__background--alt': userStore.hasFeatureFlag(
            FeatureFlag.AltNavigation
          ),
        }"
        :src="backgroundScreenshot"
      />
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-playground-access {
  $self: &;

  &__container {
    padding-top: $space-medium;
  }

  &__soc2 {
    background-color: $color-bg-default;
    border-radius: 50%;
    position: absolute;
    right: -$space-xsmall;
    top: -$space-xsmall;
    width: 80px;
  }

  &__background {
    width: 100%;

    &--alt {
      height: 100%;
    }
  }

  &__heading {
    text-align: center;
    margin-bottom: $space-xxsmall;
  }

  &__logo {
    max-width: 200px;
  }

  &__form {
    max-width: 496px;
  }

  &__field {
    width: 100%;
  }
}
</style>
