import { type PolicyContent, RemedyType } from '../../../types/content';
import apiAcountOrchestration from '../plugin/api-account-orchestration';
import strategyBasedThrottling from '../plugin/strategy-based-throttling';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Accounts Load Balancing',
  comingSoon: false,
  summary:
    'Distributes API requests across multiple accounts while regulating request rates to maintain compliance with API limits.',
  plugins: [apiAcountOrchestration, strategyBasedThrottling],
  docsUrl: undefined,
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: "example.com"
    method: "GET"
    remedies:
      - name: "APIAccountOrchestration"
        enabled: true
        config:
          account_orchestration:
            round_robin:
              - "account1"
              - "account2"
      - name: "StrategyBasedThrottling"
        enabled: true
        config:
          strategy_based_throttling:
            allowed_request_count: 200
            window_size_in_seconds: 120
            response_status_code: 429

accounts:
  <account_identifier1>: # This can be any string, but it must be unique
    tokens:
      - header:
          name: <header_name>
          value: <header_value1>
  <account_identifier2>: # This can be any string, but it must be unique
    tokens:
      - header:
          name: <header_name>
          value: <header_value2>
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
