import type { ProxyContent } from '../../../../types/content';

const content: ProxyContent = {
  name: 'Kubernetes',
  steps: [
    {
      title: 'Add and update Lunar repository',
      details: `
\`\`\`powershell
helm repo add lunar https://thelunarcompany.github.io/proxy-helm-chart/
helm repo update
\`\`\`

To start the installation process, add and update our Lunar Gateway repository for helm charts.
`,
    },
    {
      title: 'Install Lunar Gateway Helm Chart',
      details: `
\`\`\`powershell
helm install lunar-proxy lunar/lunar-proxy --set tenantName=#TENANT_NAME# --set lunarAPIKey=#API_KEY# --namespace lunar-proxy --create-namespace
\`\`\`

Before installing Lunar's Gateway, ensure that the tenantName is set to the name of your organization.
`,
    },
  ],
};

export default content;
