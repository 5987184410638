<script setup lang="ts">
import { toPercentageString, toMillisecondsString } from '../../utils';
import type { Metric } from '@monorepo/shared-model/src/aggregated-discovery';

import { type IconProps } from '../BaseIcon/BaseIcon.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseSkeleton from '../BaseSkeleton/BaseSkeleton.vue';
import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';
import { computed } from 'vue';

const props = defineProps<{
  icon: IconProps['name'];
  title: string;
  metric?: Metric;
  unit?: 'percentage' | 'milliseconds';
  neutralStatus?: boolean;
  placeholder?: boolean;
  skeleton?: boolean;
  disabledChangeStatus?: boolean;
}>();

const displayValue = computed(() => {
  if (!props.metric) return;

  if (props.unit === 'percentage') {
    return toPercentageString(props.metric.current);
  }

  if (props.unit === 'milliseconds') {
    return toMillisecondsString(props.metric.current);
  }

  return `${props.metric.current}`;
});

const displayPreviousValue = computed(() => {
  if (props.unit === 'percentage') {
    return toPercentageString(props.metric?.previous);
  }

  if (props.unit === 'milliseconds') {
    return toMillisecondsString(props.metric?.previous);
  }

  return `${props.metric?.previous}`;
});

const displayChangeRatio = computed(() => {
  if (!props.metric || !props.metric.changeRatio) return '0%';

  return toPercentageString(Math.abs(props.metric.changeRatio));
});

const isChangeRatioPositive = computed(
  () => props.metric?.changeRatio && Math.sign(props.metric.changeRatio) === -1
);

const changeIndicatorIcon = computed(() => {
  if (displayChangeRatio.value === '0%') return 'neutral';

  if (isChangeRatioPositive.value) {
    return 'triangle-down';
  }

  return 'triangle-up';
});

const badgeStatus = computed(() => {
  if (props.neutralStatus || displayChangeRatio.value === '0%') return;

  if (isChangeRatioPositive.value) {
    return 'success-subtle';
  }

  return 'danger-subtle';
});
</script>

<template>
  <BaseLayoutGap class="dashboard-metric" direction="column" alignment="center">
    <BaseLayoutIcons :icon-size="24"
      ><template #leftIcon><BaseIcon :name="icon" /></template
      >{{ title }}</BaseLayoutIcons
    >
    <div class="dashboard-metric__body">
      <BaseSkeleton
        v-if="placeholder || skeleton"
        class="dashboard-metric__placeholder"
        height="12px"
        width="25%"
        :placeholder="placeholder"
      />
      <template v-else>
        <slot>
          <BaseLayoutGap direction="column" alignment="center">
            <div class="dashboard-metric__value">{{ displayValue }}</div>
            <BaseTooltip v-if="!disabledChangeStatus" placement="bottom">
              <template #content>
                Previous value for this period was {{ displayPreviousValue }}
              </template>
              <BaseBadge :variant="badgeStatus">
                <template #leftIcon
                  ><BaseIcon :name="changeIndicatorIcon" /></template
                >{{ displayChangeRatio }}</BaseBadge
              >
            </BaseTooltip>
          </BaseLayoutGap>
        </slot>
      </template>
    </div>
  </BaseLayoutGap>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;
.dashboard-metric {
  display: flex;
  flex-direction: column;

  &__placeholder {
    margin: 0 auto $space-large;
  }

  &__body {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    padding: $space-xxsmall $space-large;
    width: 100%;
  }

  &__value {
    color: $color-text-secondary;
    font-size: $font-size-xlarge;
    line-height: $line-height-heading;
    font-weight: $font-weight-semibold;
    font-family: $font-family-secondary;
  }
}
</style>
