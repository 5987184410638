<script setup lang="ts">
import BaseDropdown from '../BaseDropdown/BaseDropdown.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseMenu from '../BaseMenu/BaseMenu.vue';
import BaseMenuItem from '../BaseMenuItem/BaseMenuItem.vue';
import BaseMenuRouterLink from '../BaseMenuRouterLink/BaseMenuRouterLink.vue';
import type { Provider } from '@monorepo/shared-model/src/aggregated-discovery';
import { computed } from 'vue';
import { useUserStore } from '../../stores/user';
import { FeatureFlag } from '../../types';

const props = defineProps<{
  provider: Provider;
}>();

const emit = defineEmits<{
  opened: [];
  closed: [];
  managePathParams: [];
}>();

const userStore = useUserStore();

const providerRoutePath = computed(() => `/provider/${props.provider.host}`);

function onManagePathParams() {
  emit('managePathParams');
}
</script>

<template>
  <div class="dashboard-overview-table-dropdown">
    <BaseDropdown
      ref="dropdown"
      placement="bottom-end"
      @opened="() => emit('opened')"
      @closed="() => emit('closed')"
    >
      <template #trigger>
        <BaseButton variant="secondary" size="small"
          ><template #leftIcon><BaseIcon name="more" /></template
        ></BaseButton>
      </template>

      <BaseMenu>
        <BaseMenuRouterLink
          v-if="userStore.hasFeatureFlag(FeatureFlag.ApiProviderView)"
          :to="providerRoutePath"
          >Go to provider</BaseMenuRouterLink
        >
        <BaseMenuItem @click.stop="onManagePathParams"
          >Manage path params</BaseMenuItem
        >
      </BaseMenu>
    </BaseDropdown>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-overview-table-dropdown {
  $self: &;

  /* Component styles goes here… */
}
</style>
