<script setup lang="ts">
import { computed, ref, useSlots } from 'vue';

import BaseFormField, {
  type BaseFormFieldProps,
} from '../BaseFormField/BaseFormField.vue';

defineProps<
  BaseFormFieldProps & {
    /**
     * Input name.
     */
    name?: string;
    /**
     * Input placeholder text.
     */
    placeholder?: string;
  }
>();

const emit = defineEmits<{
  blur: [evt: Event];
  touched: [value: boolean];
  dirty: [value: boolean];
  click: [];
}>();

const model = defineModel<string>();
const slots = useSlots();

const field = ref<HTMLInputElement | null>(null);
const isTouched = ref(false);
const isDirty = ref(false);

const hasInvalidMessage = computed(() => !!slots.invalidMessage);
const hasNote = computed(() => !!slots.default);
const hasLabel = computed(() => !!slots.label);

const onBlur = (evt: Event) => {
  emit('blur', evt);
  if (!isTouched.value) {
    isTouched.value = true;
    emit('touched', true);
  }
};

const onInput = () => {
  if (!isDirty.value) {
    emit('dirty', true);
    isDirty.value = true;
  }
};

defineExpose({
  field,
});
</script>

<template>
  <BaseFormField
    :label="label"
    :required="required"
    :invalid="invalid"
    :invalid-message="invalidMessage"
    :validator="validator"
    :disabled="disabled"
    :note="note"
  >
    <template v-if="hasLabel" #label><slot name="label" /></template>
    <template #default="{ formFieldId, passthroughAttrs }">
      <div class="textarea__container">
        <textarea
          :id="formFieldId"
          ref="field"
          class="textarea__field"
          :name="name"
          :placeholder="placeholder"
          v-bind="passthroughAttrs"
          v-model="model"
          @blur="onBlur"
          @click="emit('click')"
          @input="onInput"
        />
      </div>
    </template>

    <template v-if="hasInvalidMessage" #invalidMessage
      ><slot name="invalidMessage"
    /></template>
    <template v-if="hasNote" #note><slot /></template>
  </BaseFormField>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.textarea {
  $self: &;

  &__container {
    position: relative;
    width: 100%;
  }

  &__field {
    appearance: none;
    background-color: $color-bg-default;
    border: 1px solid $color-border-default;
    color: $color-text-default;
    border-radius: $space-xxsmall;
    box-sizing: border-box;
    font-size: $font-size-small;
    line-height: $line-height-compact;
    float: left;
    outline: none;
    padding: $space-xxsmall $space-xsmall;
    resize: vertical;
    width: 100%;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      appearance: none;
    }

    &::placeholder {
      color: $color-text-tertiary;
    }

    &:hover {
      border-color: $color-border-default-strong;
    }

    &:focus {
      border-color: $color-border-brand;
    }

    &[type='search'] {
      text-indent: calc($space-small) + 2px;
    }
  }
}
</style>
