import apiAcountOrchestration from './api-account-orchestration';
import authenticationMechanism from './authentication-mechanism';
import concurrencyBasedThrottling from './concurrency-based-throttling';
import harExporter from './har-exporter';
import responseBasedThrottling from './response-based-throttling';
import retry from './retry';
import smartCaching from './smart-caching';
import strategyBasedQueue from './strategy-based-queue';
import strategyBasedThrottling from './strategy-based-throttling';

export const plugins = [
  apiAcountOrchestration,
  authenticationMechanism,
  concurrencyBasedThrottling,
  harExporter,
  responseBasedThrottling,
  retry,
  smartCaching,
  strategyBasedQueue,
  strategyBasedThrottling,
];
