import { HttpMethod } from './http-methods';

export interface Feedback {
  target: FeedbackTarget;
  message: string;
}

export enum FeedbackType {
  Discord = 'discord',
}

export type FeedbackTarget = DiscordFeedback;

export interface DiscordFeedback {
  _type: FeedbackType.Discord;
  channel: DiscordChannel;
}

export enum DiscordChannel {
  InternalFeatureRequest = 'internal-feature-request',
  InternalPluginRequest = 'internal-plugin-request',
  InternalDevTest = 'internal-dev-test',
}

export interface OrganizationRequest {
  name: string;
  emails?: string[];
}

export interface OrganizationInvitationRequest {
  emails: string[];
}

export interface KnownEndpointRequest {
  method: HttpMethod;
  host: string;
  path: string;
}

export interface MappedPathParamRequest {
  value: string;
}
