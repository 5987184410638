import { type PluginContent, RemedyType } from '../../../types/content';

const content: PluginContent = {
  type: RemedyType.Plugin,
  icon: 'throttling',
  name: 'Strategy-Based Throttling',
  summary:
    'Enforces consumption limits based on pre-defined quotas rather than dynamic response data.',
  category: ['Traffic control'],
  docsUrl:
    'https://docs.lunar.dev/product-features/remedy-plugins/strategy-based-throttling',
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: api.com/resource/{id}
    method: GET
    remedies:
      - name: Strategy-Based Throttling
        enabled: true
        config:
          strategy_based_throttling:
            allowed_request_count: 100
            window_size_in_seconds: 60
            response_status_code: 429
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
