import type { ToRaw } from '@monorepo/toolkit-core/types';
import type { CurrentDateRange, PreviousDateRange } from './date-range';

export interface AggregatedDiscovery {
  interceptors: Interceptors; // In deprecation
  consumers: ConsumerInstance[];
  proxies: Proxies;
  traffic: Traffic;
}

export type AggregatedDiscoveryWithDateRange = AggregatedDiscovery & {
  dateRange: CurrentDateRange & PreviousDateRange;
};

export interface Interceptors {
  lastTransactionDate?: Date;
  instances: InterceptorInstance[];
}

export interface InterceptorInstance {
  type: InterceptorType;
  name: string;
  version: string;
  lastTransactionDate: Date;
}

export enum InterceptorType {
  LunarPyInterceptor = 'lunar-py-interceptor',
  LunarJavaInterceptor = 'lunar-java-interceptor',
  LunarTsInterceptor = 'lunar-ts-interceptor',
  Unknown = 'unknown',
}

export interface ConsumerInstance {
  tag: string;
  lastTransactionDate?: Date;
}

export interface Proxies {
  lastTransactionDate?: Date;
  instances: ProxyInstance[];
}

export interface ProxyInstance {
  type: 'lunar-proxy';
  id: string;
  name: string;
  version: string;
  lastTransactionDate?: Date;
}

export interface Traffic {
  count: Metric;
  errorRate: Metric;
  runtimeMs: {
    avg: Metric;
  };
  statusCodes: StatusCodeGroups;
  providers: Providers;
}

export interface Metric {
  current: number;
  previous?: number;
  changeRatio?: number;
}

export enum StatusCodeGroup {
  '1xx' = '1xx',
  '2xx' = '2xx',
  '3xx' = '3xx',
  '4xx' = '4xx',
  '5xx' = '5xx',
  'irregular' = 'irregular',
}

export type StatusCodeGroups = {
  [K in keyof typeof StatusCodeGroup]: StatusCodeGroupData;
};

export interface StatusCodeGroupData {
  total: number;
  codes: {
    [key: string]: number;
  };
}

export interface Providers {
  count: number;
  endpointCount: number;
  instances: Provider[];
}

export interface Provider {
  host: string;
  name: string;
  endpointCount: Metric;
  callCount: Metric;
  errorRate: Metric;
  runtimeMs: {
    avg: Metric;
  };
  statusCodes: StatusCodeGroups;
  endpoints: Endpoint[];
}

export interface Endpoint {
  method: string;
  path: string;
  minTime: Date;
  maxTime: Date;
  count: number;
  statusCodes: StatusCodeGroups;
  runtimeMs: {
    avg: number;
  };
  // TEMPORARY: for demo purposes. To be replaced with proper, production grade remediation data model.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remedies?: any[];
}

export type PrimitiveAggregatedDiscovery = ToRaw<AggregatedDiscovery>;
export type PrimitiveProvider = ToRaw<Provider>;
export type PrimativeEndpoint = ToRaw<Endpoint>;

// returns an empty StatusCodeGroups instance to aggregate into
export function emptyStatusCodeGroups(): StatusCodeGroups {
  return {
    '1xx': { total: 0, codes: {} },
    '2xx': { total: 0, codes: {} },
    '3xx': { total: 0, codes: {} },
    '4xx': { total: 0, codes: {} },
    '5xx': { total: 0, codes: {} },
    irregular: { total: 0, codes: {} },
  };
}

export function getStatusCodeGroup(code: number): StatusCodeGroup {
  if (code >= 100 && code < 200) {
    return StatusCodeGroup['1xx'];
  }
  if (code >= 200 && code < 300) {
    return StatusCodeGroup['2xx'];
  }
  if (code >= 300 && code < 400) {
    return StatusCodeGroup['3xx'];
  }
  if (code >= 400 && code < 500) {
    return StatusCodeGroup['4xx'];
  }
  if (code >= 500 && code < 600) {
    return StatusCodeGroup['5xx'];
  }
  return StatusCodeGroup['irregular'];
}

export function getInterceptorNameFromType(
  interceptorType: InterceptorType
): string {
  switch (interceptorType) {
    case InterceptorType.LunarJavaInterceptor:
      return 'Lunar Java Interceptor';
    case InterceptorType.LunarPyInterceptor:
      return 'Lunar Python Interceptor';
    case InterceptorType.LunarTsInterceptor:
      return 'Lunar NodeJS Interceptor';
    case InterceptorType.Unknown:
      return 'Unknown';
  }
}
