<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { type User } from '@auth0/auth0-vue';
import type { UserPermissions } from '../../types';
import type { OrganizationDAO } from '@monorepo/shared-model/src/user';

import BaseLogo from '../BaseLogo/BaseLogo.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';

import AppNavigation from '../AppNavigation/AppNavigation.vue';
import AppAccountDropdown from '../AppAccountDropdown/AppAccountDropdown.vue';
import type { PlaygroundVisitor } from '../../stores/demo/types';

const props = defineProps<{
  user: User;
  org?: OrganizationDAO;
  permissions?: UserPermissions;
  playgroundVisitor?: PlaygroundVisitor;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  logout: [];
}>();

const onBookDemo = () => {
  if (!props.playgroundVisitor) return;

  window.open(props.playgroundVisitor.redirectUri, '_blank');
};
</script>

<template>
  <BaseLayoutGap wrapper="header" alignment="space-between" class="app-header">
    <div class="app-header__logo-container">
      <RouterLink to="/" custom v-slot="{ navigate }">
        <BaseLogo class="app-header__logo" @click="navigate" />
        <BaseBadge
          class="app-header__beta"
          variant="brand-subtle"
          size="xsmall"
          chip
          >Beta</BaseBadge
        >
      </RouterLink>
    </div>

    <AppNavigation :permissions="permissions" :disabled="disabled" />

    <div class="app-header__actions">
      <BaseLayoutGap alignment="right">
        <template v-if="permissions?.isPlayground">
          <BaseButton
            v-if="playgroundVisitor"
            variant="primary"
            @click="onBookDemo"
            >Schedule a call</BaseButton
          >
        </template>
        <AppAccountDropdown
          v-else
          :user="user"
          :org="org"
          :permissions="permissions"
          :disabled="disabled"
          @logout="emit('logout')"
        ></AppAccountDropdown>
      </BaseLayoutGap>
    </div>
  </BaseLayoutGap>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-header {
  background-color: $color-bg-default;
  border-bottom: 1px solid $color-border-default;
  padding: 0 $space-xlarge;
  position: sticky;
  top: 0;
  z-index: layer('menu') + 1;

  &__logo-container {
    align-items: center;
    display: flex;
    position: relative;
    width: 166px;
  }

  &__logo {
    cursor: pointer;
    width: 130px;
  }

  &__beta {
    position: absolute;
    right: -$space-xxxxsmall;
    top: 0;
  }
}
</style>
