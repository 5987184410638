<script setup lang="ts">
import { computed } from 'vue';
import { type RouterLinkProps, useLink } from 'vue-router';

import BaseMenuItem from '../BaseMenuItem/BaseMenuItem.vue';

const props = defineProps<
  RouterLinkProps & {
    disableActiveState?: boolean;
  }
>();

const emit = defineEmits<{
  navigated: [];
}>();

const { navigate, href, isExactActive } = useLink(props);

const computedClasses = computed(() => {
  return {
    'menu-router-link--active':
      isExactActive.value && !props.disableActiveState,
  };
});

const onNavigate = () => {
  emit('navigated');
  navigate();
};
</script>

<template>
  <BaseMenuItem
    class="menu-router-link"
    :class="computedClasses"
    :href="href"
    tabindex="0"
    @click.stop="onNavigate"
    @keydown.enter.stop="onNavigate"
    ><slot
  /></BaseMenuItem>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu-router-link {
  $self: &;

  cursor: pointer;

  &--active,
  &:hover,
  &:focus {
    background-color: $color-bg-surface;
    border-radius: $space-xxxxsmall;
    outline: none;
  }
}
</style>
