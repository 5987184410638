<script setup lang="ts">
import { format, formatDistanceToNow } from 'date-fns';
import type { PluginContent } from '../../types/content';

import AppPageDrawerHeading from '../AppPageDrawerHeading/AppPageDrawerHeading.vue';

import BaseHeading from '../BaseHeading/BaseHeading.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseAnchor from '../BaseAnchor/BaseAnchor.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseAccordion from '../BaseAccordion/BaseAccordion.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';

import DashboardMetric from '../DashboardMetric/DashboardMetric.vue';
import PluginsInstallationPluginBadge from '../PluginsInstallationPluginBadge/PluginsInstallationPluginBadge.vue';

import { useUserStore } from '../../stores/user';

const props = defineProps<{
  plugin: PluginContent;
  installed?: boolean;
  autoApply?: boolean;
}>();

const emit = defineEmits<{
  applyRemediation: [remedy: PluginContent];
  removeRemediation: [remedy: PluginContent];
}>();

const userStore = useUserStore();
const now = new Date();

const onApplyRemediation = () => {
  emit('applyRemediation', props.plugin);
};

const onRemoveRemediation = () => {
  emit('removeRemediation', props.plugin);
};
</script>

<template>
  <div class="plugins-installation-plugin">
    <AppPageDrawerHeading>{{ plugin.name }}</AppPageDrawerHeading>

    <BaseLayoutGap
      class="plugins-installation-plugin__header"
      direction="column"
      alignment="center"
      size="large"
    >
      <PluginsInstallationPluginBadge :plugin="plugin" />

      <BaseProse size="small" alignment="center"
        ><BaseAnchor
          v-if="plugin.docsUrl"
          class="plugins-installation-plugin__docs-link"
          :href="plugin.docsUrl"
          size="small"
          target="_blank"
          rel="noopener noreferrer"
          ><template #leftIcon><BaseIcon name="book-open-text" /></template
          >Docs</BaseAnchor
        ></BaseProse
      >
    </BaseLayoutGap>

    <template
      v-if="userStore.permissions.featureFlag.pluginSuggestions && autoApply"
    >
      <template v-if="installed">
        <BaseLayoutGap direction="column" alignment="center" size="xlarge">
          <BaseProse size="small" alignment="center">{{
            plugin.summary
          }}</BaseProse>
          <BaseLayoutGap alignment="top" style="width: 100%">
            <DashboardMetric
              icon="calendar-check-outline"
              title="Installed"
              style="width: 50%"
            >
              <BaseLayoutGap direction="column" alignment="center">
                <BaseHeading
                  :level="3"
                  variant="secondary"
                  weight="semibold"
                  no-margin
                  style="text-align: center; margin-bottom: 6px"
                  >{{
                    formatDistanceToNow(now.setMinutes(now.getMinutes() - 3), {
                      addSuffix: true,
                    })
                  }}</BaseHeading
                >

                <BaseBadge>{{ format(now, 'MMM io, yyyy') }}</BaseBadge>
              </BaseLayoutGap>
            </DashboardMetric>

            <DashboardMetric
              icon="performance-increase-outline"
              title="API calls optimized"
              style="width: 50%"
            >
              <BaseLayoutGap direction="column" alignment="center">
                <BaseHeading
                  :level="2"
                  variant="secondary"
                  weight="semibold"
                  no-margin
                  >78</BaseHeading
                >

                <BaseBadge variant="success-subtle"
                  ><template #leftIcon><BaseIcon name="triangle-up" /></template
                  >20%</BaseBadge
                >
              </BaseLayoutGap>
            </DashboardMetric>
          </BaseLayoutGap>
          <BaseButton variant="link-danger" @click="onRemoveRemediation"
            ><template #leftIcon><BaseIcon name="bin" /></template>Remove
            {{ plugin.name }} plugin</BaseButton
          ></BaseLayoutGap
        ></template
      >
      <template v-else>
        <BaseLayoutGap direction="column" size="large">
          <BaseLayoutGap direction="column" alignment="center">
            <BaseProse size="small" alignment="center">
              <template #default="{ markdownToHtml }">
                <div v-html="markdownToHtml(plugin.summary)" />
              </template>
            </BaseProse>

            <BaseButton @click="onApplyRemediation">Apply Plugin</BaseButton>
          </BaseLayoutGap>

          <BaseAccordion>
            <template #heading> Manual installation </template>
            <BaseProse size="small">
              <template #default="{ markdownToHtml }">
                <div v-html="markdownToHtml(plugin.details)" />
              </template>
            </BaseProse>
          </BaseAccordion>
        </BaseLayoutGap>
      </template>
    </template>
    <template v-else>
      <BaseProse size="small">
        <template #default="{ markdownToHtml }">
          <div v-html="markdownToHtml(plugin.details)" />
        </template>
      </BaseProse>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.plugins-installation-plugin {
  $self: &;

  &__header {
    margin-bottom: $space-small;
  }

  &__title {
    margin-bottom: $space-large;
  }

  &__item {
    margin-bottom: $space-large;
  }

  &__footer {
    padding-top: $space-small;
  }
}
</style>
