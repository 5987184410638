<script setup lang="ts">
import { capitalize } from 'lodash-es';
import Toast, { type ToastMessageOptions } from 'primevue/toast';

import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseIcon, { type IconProps } from '../BaseIcon/BaseIcon.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

type ValidSeverity = Exclude<ToastMessageOptions['severity'], undefined>;

type SeverityIconMap = {
  [K in ValidSeverity]: IconProps['name'];
};

const severityIconMap: SeverityIconMap = {
  success: 'check-circle',
  info: 'alert-circle',
  warn: 'alert-triangle',
  error: 'alert-cross',
};

withDefaults(defineProps<ToastMessageOptions>(), {
  severity: undefined,
  summary: undefined,
  detail: undefined,
  life: undefined,
  closable: true,
});
</script>

<template>
  <Toast
    position="bottom-right"
    :pt="{
      transition: {
        duration: {
          enter: 2000,
          leave: 2000,
        },
        enterFromClass: 'toast--enter-from',
        enterActiveClass: 'toast--enter-active',
        enterToClass: 'toast--enter-to',
        leaveFromClass: 'toast--leave-from',
        leaveActiveClass: 'toast--leave-active',
        leaveToClass: 'toast--leave-to',
      },
    }"
    unstyled
  >
    <template #container="{ message, closeCallback }">
      <div
        class="toast"
        :class="{ [`toast--${message.severity}`]: message.severity }"
      >
        <BaseLayoutGap class="toast__container" alignment="top">
          <BaseIcon
            v-if="message.severity"
            :name="severityIconMap[message.severity as ValidSeverity]"
            class="toast__severity-icon"
            :class="{ [`toast__severity-icon--${message.severity}`]: true }"
            :label="`${capitalize(message.severity)} icon`"
          />
          <div class="toast__content">
            <BaseProse weight="semibold">{{ message.summary }}</BaseProse>
            <BaseProse v-if="message.detail" size="small">{{
              message.detail
            }}</BaseProse>
          </div>

          <div v-if="message.closable" class="toast__close">
            <BaseButton
              size="small"
              variant="ghost"
              aria-label="Close"
              @click="closeCallback()"
            >
              <template #leftIcon><BaseIcon name="close" /></template>
            </BaseButton>
          </div>
        </BaseLayoutGap>
      </div>
    </template>
  </Toast>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.toast {
  $self: &;

  &__container {
    background-color: $white;
    border: 1px solid $color-border-default;
    border-radius: $space-xxsmall;
    box-shadow: $elevation-xlarge;
    margin: $space-small;
    min-width: 240px;
    max-width: 360px;
    opacity: 1;
    padding: $space-small $space-small $space-small $space-small;
    position: relative;
  }

  &__severity-icon {
    font-size: $font-size-medium;
    position: relative;
    top: 1px;

    &--success {
      color: $color-text-success;
    }
    &--warn {
      color: $color-text-warning;
    }
    &--info {
      color: $color-text-info;
    }
    &--error {
      color: $color-text-danger;
    }
  }

  &__close {
    font-size: $font-size-xxsmall;
    position: absolute;
    right: 0;
    top: $space-xxxxsmall;
  }

  &--enter-active {
    #{$self}__container {
      transition: all 0.8s ease-out;
    }
  }

  &--leave-active {
    #{$self}__container {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }
  }

  &--enter-from,
  &--leave-to {
    #{$self}__container {
      transform: translateX(40px);
      opacity: 0;
    }
  }
}
</style>
