import type { InterceptorContent } from '../../../../types/content';

const content: InterceptorContent = {
  name: 'Java',
  steps: [
    {
      title: 'Installation',
      details: `
\`\`\`bash
wget -O lunarInterceptor.jar https://s01.oss.sonatype.org/content/repositories/releases/dev/lunar/interceptor/lunar-interceptor/0.1.1/lunar-interceptor-0.1.1.jar
\`\`\`
`,
    },
    {
      title: 'Usage',
      details: `
\`\`\`bash
export JAVA_TOOL_OPTIONS="-javaagent:PATH/TO/lunarInterceptor.jar"
\`\`\`

Or

\`\`\`bash
java -javaagent:PATH/TO/lunarInterceptor.jar -jar PATH/TO/YOURS.jar
\`\`\`

Import Lunar Interceptor into the application entry point at the beginning of the file.
`,
    },
    {
      title: 'Configuration',
      details: `
\`\`\`bash
export LUNAR_PROXY_HOST="localhost:8000"
\`\`\`

The only required configuration is the LUNAR_PROXY_HOST environment variable. This variable should be set to the host or IP of Lunar Proxy, including the port it is listening on. 
The value assigned to \`LUNAR_PROXY_HOST\` should only include the hostname and port, without the \`HTTP\` prefix. For example, use \`localhost:8000\` and not \`http://localhost:8000\`.

[Optional] Add the \`x-lunar-consumer-tag\` header to your requests with a value indicating the originating application or service. This header will be removed before the request is forwarded to third-party providers.
`,
    },
    {
      title: 'Run your app',
      details: `
Once you start running your app we'll get the signals and immediately you'll see Lunar's magic on the dashboard. 
You can also install our [Example App](https://github.com/TheLunarCompany/lunar/blob/main/example-consumer-app/java) and play with it.
      `,
    },
  ],
};

export default content;
