import accountsLoadBalancing from './accounts-load-balancing';
import quotaGroupsPrioritization from './quota-groups-prioritization';
import prioritizedQueue from './prioritized-queue';
import quotaManagement from './quota-management';
import retryResponseOptimization from './rety-response-optimization';
import secureAccessAuth from './secure-access-auth';
import llmApiOrchestration from './llm-api-orchestration';
import paymentsApiFallback from './payments-api-fallback';
import anomalyDetection from './anomaly-detection';
import costLimitsManagement from './cost-limits-management';
import participatedPiiAudits from './participated-pii-audits';

export const policies = [
  accountsLoadBalancing,
  quotaGroupsPrioritization,
  prioritizedQueue,
  quotaManagement,
  retryResponseOptimization,
  secureAccessAuth,
  llmApiOrchestration, // Coming soon
  paymentsApiFallback, // Coming soon
  anomalyDetection, // Coming soon
  costLimitsManagement, // Coming soon
  participatedPiiAudits, // Coming soon
];
