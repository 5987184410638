<script lang="ts">
import { type Placement } from '@floating-ui/vue';

export type DropdownPlacement = Placement;
</script>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { onClickOutside, type MaybeElement } from '@vueuse/core';
import { useFloating, flip, shift, offset, autoUpdate } from '@floating-ui/vue';

import BaseBlock from '../BaseBlock/BaseBlock.vue';

const props = withDefaults(
  defineProps<{
    placement?: DropdownPlacement;
    disableClickOutside?: boolean;
    disabled?: boolean;
  }>(),
  {
    placement: 'bottom',
  }
);

const emit = defineEmits<{
  opened: [];
  closed: [];
}>();

const reference = ref<MaybeElement>();
const floating = ref<HTMLElement>();
const isOpen = ref(false);

const { floatingStyles } = useFloating(reference, floating, {
  placement: props.placement,
  middleware: [offset(4), flip(), shift({ padding: 16 })],
  whileElementsMounted: autoUpdate,
});

const computedClasses = computed(() => ({
  'dropdown--open': isOpen.value,
}));

const toggle = () => {
  if (props.disabled) return;

  isOpen.value = !isOpen.value;

  if (isOpen.value) {
    emit('opened');
  } else {
    emit('closed');
  }
};

const open = () => {
  if (props.disabled) return;

  isOpen.value = true;

  emit('opened');
};

const close = () => {
  isOpen.value = false;

  emit('closed');
};

onClickOutside(
  floating,
  () => {
    if (props.disableClickOutside) return;

    isOpen.value = false;

    emit('closed');
  },
  { ignore: [reference] }
);

defineExpose({
  isOpen,
  open,
  close,
  toggle,
});
</script>

<template>
  <div class="dropdown" :class="computedClasses" data-testid="dropdown">
    <div ref="reference" class="dropdown__trigger" @click.stop="toggle">
      <slot name="trigger" />
    </div>
    <BaseBlock
      ref="floating"
      class="dropdown__popover"
      elevation="large"
      :style="floatingStyles"
    >
      <slot />
    </BaseBlock>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dropdown {
  $self: &;

  position: relative;

  &__trigger {
    cursor: pointer;
    display: inline-flex;
  }

  &__popover {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: max-content;
    z-index: layer('dropdown');
  }

  &--open {
    #{$self} {
      &__popover {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
</style>
