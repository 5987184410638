import { type FlowContent, RemedyType } from '../../../types/content';

const content: FlowContent = {
  type: RemedyType.Flow,
  name: 'Concurrent Rate Limiting ElevenLabs API',
  videoUrl: '/videos/elevenlabs-concurrent-rate-limiting.mp4',
  summary:
    'Queue your requests to the API to govern the overall number of concurrent requests you are making.',
};

export default content;
