export enum ServerBoundWebSocketEvents {
  WaitForProxy = 'wait-for-proxy',
  WaitForInterceptor = 'wait-for-interceptor',
  GetAggregatedDiscovery = 'get-aggregated-discovery',
}

export enum ClientBoundWebSocketEvents {
  ProxyInstalled = 'proxy-installed',
  InterceptorInstalled = 'interceptor-installed',
  AggregatedDiscovery = 'aggregated-discovery',
  WaitForProxyFailed = 'wait-for-proxy-failed',
  WaitForInterceptorFailed = 'wait-for-interceptor-failed',
  GetAggregatedDiscoveryFailed = 'get-aggregated-discovery-failed',
}
export interface GetAggregatedDiscoveryPayload {
  dateRange: DateRange;
  filteredConsumerTags: string[];
}

export type DateRange = DateRangeAbsolute | DateRangeRelative;
export interface DateRangeAbsolute {
  dateRangeType: 'absolute';
  startDate: Date;
  endDate: Date;
}

export interface DateRangeRelative {
  dateRangeType: 'relative';
  length: number;
  unit: 'month' | 'week' | 'day' | 'hour' | 'minute';
}

export const DefaultDateRange: DateRangeRelative = {
  dateRangeType: 'relative',
  length: 1,
  unit: 'day',
};
