import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { authGuard as auth0AuthGuard } from '@auth0/auth0-vue';

type AuthGuardOptions = {
  playgroundAccessible?: boolean;
};

export function authGuard(
  options: AuthGuardOptions = {
    playgroundAccessible: true,
  }
) {
  return async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const isPlayground = import.meta.env.VITE_PLAYGROUND === 'true';

    if (isPlayground && !options.playgroundAccessible) {
      next({ name: 'dashboard' });
    }

    if (!isPlayground) {
      await auth0AuthGuard(to);
    }

    next();
  };
}
