<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const blockVariants = {
  variant: {
    'brand-dashed': 'block--brand-dashed',
  },
  elevation: {
    xsmall: 'block--elevation-xsmall',
    small: 'block--elevation-small',
    medium: 'block--elevation-medium',
    large: 'block--elevation-large',
    xlarge: 'block--elevation-xlarge',
  },
};

const classes = cva({ variants: blockVariants });

export type BlockProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
defineProps<{
  variant?: BlockProps['variant'];
  elevation?: BlockProps['elevation'];
}>();
</script>

<template>
  <div class="block" :class="classes({ variant, elevation })">
    <!-- @slot Any content or elements. -->
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.block {
  background-color: $color-bg-default;
  border: 1px solid $color-border-default;
  border-radius: $space-xxsmall;
  box-sizing: border-box;
  width: 100%;

  &--brand-dashed {
    border: 1px dashed $color-border-brand;
  }

  /**
   * Elevation modifiers
   */
  &--elevation-xsmall {
    box-shadow: $elevation-xsmall;
  }

  &--elevation-small {
    box-shadow: $elevation-small;
  }

  &--elevation-medium {
    box-shadow: $elevation-medium;
  }

  &--elevation-large {
    box-shadow: $elevation-large;
  }

  &--elevation-xlarge {
    box-shadow: $elevation-xlarge;
  }
}
</style>
