import { type PolicyContent, RemedyType } from '../../../types/content';
import apiAcountOrchestration from '../plugin/strategy-based-queue';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Prioritized Queue',
  comingSoon: false,
  summary: 'Prioritize critical API requests between consumers by headers.',
  plugins: [apiAcountOrchestration],
  docsUrl: undefined,
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: "example.com"
    method: "GET"
    remedies:
      - name: Prioritized Queue
        enabled: true
        config:
          strategy_based_queue:
            allowed_request_count: 1
            window_size_in_seconds: 1
            ttl_seconds: 12
            response_status_code: 429
            prioritization:
              group_by:
                header_name: X-Group
              groups:
                production:
                  priority: 1
                staging:
                  priority: 2
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
