<script setup lang="ts">
import type { PrimitiveAggregatedDiscovery } from '@monorepo/shared-model/src/aggregated-discovery.ts';

import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseStatusChart from '../BaseStatusChart/BaseStatusChart.vue';
import BaseStatusChartLegend from '../BaseStatusChartLegend/BaseStatusChartLegend.vue';

import DashboardMetric from '../DashboardMetric/DashboardMetric.vue';

const props = defineProps<{
  traffic: PrimitiveAggregatedDiscovery['traffic'];
  onboarding?: boolean;
  skeleton?: boolean;
}>();
</script>

<template>
  <div class="dashboard-metrics">
    <div class="dashboard-metrics__items">
      <DashboardMetric
        class="dashboard-metrics__item"
        icon="data-transfer-circle-outline"
        title="Total API calls"
        :metric="traffic.count"
        :placeholder="onboarding"
        :skeleton="skeleton"
        neutral-status
      />

      <DashboardMetric
        class="dashboard-metrics__item"
        icon="graph-stats-outline"
        title="Error rate"
        :metric="traffic.errorRate"
        unit="percentage"
        :placeholder="onboarding"
        :skeleton="skeleton"
      />

      <DashboardMetric
        class="dashboard-metrics__item"
        icon="stopwatch-outline"
        title="Avg. runtime"
        :metric="traffic.runtimeMs.avg"
        unit="milliseconds"
        :placeholder="onboarding"
        :skeleton="skeleton"
      />

      <DashboardMetric
        class="dashboard-metrics__item"
        icon="server-outline"
        title="Status code dist."
        :placeholder="onboarding"
        :skeleton="skeleton"
      >
        <BaseLayoutGap size="medium" alignment="center" direction="column">
          <BaseLayoutGap size="medium">
            <BaseStatusChart
              :status-codes="props.traffic.statusCodes"
              size="large"
            />
            <BaseStatusChartLegend :status-codes="props.traffic.statusCodes" />
          </BaseLayoutGap>
        </BaseLayoutGap>
      </DashboardMetric>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;
.dashboard-metrics {
  --cols: 1;
  --gap: #{$space-large};

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap);
  }

  &__item {
    flex-basis: calc(
      (100% / var(--cols)) - var(--gap) / var(--cols) * (var(--cols) - 1)
    );
  }

  @include media-query('tablet') {
    --cols: 2;
  }

  @include media-query('wide-desktop') {
    --cols: 4;
  }
}
</style>
