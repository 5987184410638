<script lang="ts">
import { type Placement } from '@floating-ui/vue';

export type TooltipPlacement = Placement;
</script>

<script setup lang="ts">
import { ref, computed, type StyleValue } from 'vue';
import { onClickOutside, type MaybeElement } from '@vueuse/core';
import {
  useFloating,
  flip,
  shift,
  offset,
  arrow,
  autoUpdate,
} from '@floating-ui/vue';
import { omit } from 'lodash-es';

const props = withDefaults(
  defineProps<{
    show?: boolean;
    content?: string;
    placement?: TooltipPlacement;
    interactive?: boolean;
    disabled?: boolean;
    size?: 'small';
  }>(),
  {
    placement: 'top',
  }
);

const reference = ref<MaybeElement>();
const floating = ref<HTMLElement>();
const floatingArrow = ref<HTMLElement>();
const isOpen = ref(false);

const floatingOffset = computed(() => {
  if (props.size === 'small') {
    return 9;
  }

  return 12;
});

const {
  floatingStyles,
  middlewareData,
  placement: floatingPlacement,
} = useFloating(reference, floating, {
  placement: props.placement,
  middleware: [
    offset(floatingOffset.value),
    flip(),
    shift({ padding: 16 }),
    arrow({ element: floatingArrow, padding: 4 }),
  ],
  whileElementsMounted: autoUpdate,
});

const place = computed(() => floatingPlacement.value.split('-')[0]);

const arrowStyles = computed<StyleValue>(() => {
  const arrowWidth =
    (floatingArrow.value && floatingArrow.value.offsetWidth) || 0;
  const horizontal =
    middlewareData.value.arrow?.x != null
      ? `${middlewareData.value.arrow.x}px`
      : '';
  const vertical =
    middlewareData.value.arrow?.y != null
      ? `${middlewareData.value.arrow.y}px`
      : '';
  let style = {};

  switch (place.value) {
    case 'top':
      style = {
        left: horizontal,
        top: `calc(100% - ${arrowWidth / 2 - 1}px)`,
      };
      break;
    case 'bottom':
      style = {
        left: horizontal,
        top: `-${arrowWidth / 2 + 1}px`,
      };
      break;
    case 'right':
      style = {
        left: `-${arrowWidth / 2 + 1}px`,
        top: vertical,
      };
      break;
    case 'left':
      style = {
        right: `-${arrowWidth / 2 + 1}px`,
        top: vertical,
      };
      break;
  }

  return style;
});

const computedClasses = computed(() => ({
  [`tooltip--${place.value}`]: true,
  [`tooltip--${props.size}`]: props.size,
  'tooltip--show': props.show,
  'tooltip--interactive': props.interactive,
  'tooltip--open': !props.disabled && isOpen.value,
  'tooltip--disabled': props.disabled,
}));

const toggleTooltip = (e: MouseEvent) => {
  if (props.interactive) {
    e.stopPropagation();
    isOpen.value = !isOpen.value;
  }
};

onClickOutside(
  floating,
  () => {
    isOpen.value = false;
  },
  { ignore: [reference] }
);
</script>

<template>
  <div class="tooltip" :class="computedClasses">
    <div
      ref="reference"
      class="tooltip__trigger"
      aria-describedby="tooltip"
      @click="toggleTooltip"
    >
      <slot />
    </div>
    <div
      ref="floating"
      class="tooltip__content"
      role="tooltip"
      :style="omit(floatingStyles, ['left'])"
    >
      <slot name="content">{{ content }}</slot>
      <div
        ref="floatingArrow"
        class="tooltip__arrow"
        :style="arrowStyles"
      ></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.tooltip {
  $self: &;

  display: inline-flex;
  position: relative;
  z-index: layer('tooltip');

  &--open,
  &:not(&--disabled):not(&--interactive):hover {
    #{$self} {
      &__content {
        opacity: 1;
        top: 0;
        left: 0;
      }
    }
  }

  &__trigger {
    display: flex;
    width: 100%;
  }

  &__content {
    opacity: 0;
    width: max-content;
    position: absolute;
    top: 0;
    left: -9999px;
    background-color: $color-bg-default;
    border: 1px solid $color-border-default;
    box-shadow: $elevation-medium;
    color: $color-text-default;
    font-weight: $font-weight-regular;
    padding: $space-xxsmall $space-small;
    border-radius: $space-xxxsmall;
    transition: opacity 0.25s ease-in-out;
    z-index: layer('tooltip');
  }

  &__arrow {
    border: 1px solid $color-border-default;
    position: absolute;
    background-color: $color-bg-default;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
  }

  &--top {
    #{$self} {
      &__arrow {
        border-left: none;
        border-top: none;
      }
    }
  }
  &--bottom {
    #{$self} {
      &__arrow {
        border-right: none;
        border-bottom: none;
      }
    }
  }
  &--left {
    #{$self} {
      &__arrow {
        border-left: none;
        border-bottom: none;
      }
    }
  }
  &--right {
    #{$self} {
      &__arrow {
        border-right: none;
        border-top: none;
      }
    }
  }

  &--show {
    #{$self} {
      &__content {
        opacity: 1;
        top: 0;
        left: 0;
      }
    }
  }

  &--small {
    #{$self} {
      &__content {
        font-size: $font-size-xsmall;
        padding: $space-xxxsmall $space-xsmall;
      }
    }
  }

  &--interactive {
    #{$self}__trigger {
      cursor: pointer;
    }
  }
}
</style>
