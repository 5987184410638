import { type PluginContent, RemedyType } from '../../../types/content';

const content: PluginContent = {
  type: RemedyType.Plugin,
  icon: 'throttling',
  name: 'Concurrent-Based Throttling',
  summary:
    'Limit the number of concurrent requests, preventing overload and ensuring optimal performance.',
  category: ['Traffic control'],
  docsUrl:
    'https://docs.lunar.dev/product-features/remedy-plugins/concurrency-based-throttling',
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: api.com/resource/{id}
    method: GET
    remedies:
      - name: Concurrency-Based Throttling Example
        enabled: true
        config:
          concurrency_based_throttling:
            max_concurrent_requests: 50
            response_status_code: 429
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
