<script setup lang="ts">
import BaseHeading from '../BaseHeading/BaseHeading.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseBlock from '../BaseBlock/BaseBlock.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import { computed, useSlots } from 'vue';

const props = defineProps<{
  title?: string;
  open?: boolean;
  closeable?: boolean;
  noPadding?: boolean;
}>();

const emit = defineEmits<{
  'update:open': [value: boolean];
  close: [];
}>();

const slots = useSlots();

const hasHeading = computed(
  () => !!slots.heading || !!slots.title || props.title
);
const hasActions = computed(() => !!slots.actions);
const hasCustomMask = computed(() => !!slots.customMask);

const computedClasses = computed(() => ({
  'dialog--no-padding': props.noPadding,
}));

function onClose() {
  emit('update:open', false);
  emit('close');
}
</script>

<template>
  <Teleport to="body">
    <div class="dialog" :class="computedClasses">
      <div v-if="hasCustomMask" class="dialog__custom-mask">
        <slot name="customMask" />
      </div>
      <div v-if="open" class="dialog__container">
        <BaseBlock class="dialog__block" elevation="xlarge">
          <BaseLayoutGap direction="column" size="large">
            <slot name="header">
              <header v-if="hasHeading" class="dialog__header">
                <BaseHeading :level="3"
                  ><slot name="title">{{ title }}</slot></BaseHeading
                >
              </header>
            </slot>
            <div class="dialog__body">
              <slot />
            </div>
            <div v-if="hasActions" class="dialog__actions">
              <slot name="actions" :close-callback="onClose" />
            </div>
          </BaseLayoutGap>
          <div v-if="closeable" class="dialog__close">
            <BaseButton
              variant="ghost"
              aria-label="Close dialog"
              size="small"
              @click="onClose"
              @keydown.enter="onClose"
            >
              <template #leftIcon>
                <BaseIcon name="close" title="Close this dialog" />
              </template>
            </BaseButton>
          </div>
        </BaseBlock>
      </div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dialog {
  $self: &;

  &__container {
    align-items: center;
    background-color: rgba($neutral-900, 0.3);
    bottom: 0;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    z-index: layer('modal');
  }

  &__block {
    min-width: 460px;
    padding: $space-large;
    position: relative;
    width: auto;
  }

  &__body {
    width: 100%;
  }

  &__actions {
    justify-content: flex-end;
    display: flex;
    width: 100%;
  }

  &__close {
    cursor: pointer;
    font-size: $font-size-xsmall;
    position: absolute;
    right: $space-small;
    top: $space-small;
    z-index: calc(layer('drawer') + 1);

    &:hover {
      color: $color-text-brand;
    }
  }

  &__custom-mask {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: layer('modal') - 2;
  }

  &--no-padding {
    #{$self}__block {
      padding: 0;
    }
  }
}
</style>
