<script setup lang="ts">
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

const props = defineProps<{
  name: string;
  to: string;
  disabled?: boolean;
  exact?: boolean;
  collapsed?: boolean;
}>();

const computedClasses = computed(() => ({
  'app-navigation-item--disabled': props.disabled,
}));
</script>

<template>
  <li class="app-navigation-item" :class="computedClasses">
    <BaseTooltip
      class="app-navigation-item__tooltip"
      :content="name"
      placement="right"
      :disabled="!collapsed"
      @click.stop
    >
      <span v-if="disabled" class="app-navigation-item__link"><slot /></span>
      <RouterLink
        v-else
        :to="to"
        custom
        v-slot="{ href, isExactActive, isActive, navigate }"
      >
        <a
          class="app-navigation-item__link"
          :class="{
            'app-navigation-item__link--active': exact
              ? isExactActive
              : isActive,
          }"
          :href="href"
          @click="navigate"
        >
          <BaseLayoutIcons :icon-size="16">
            <template #leftIcon>
              <slot name="leftIcon" />
            </template>
            {{ name }}
          </BaseLayoutIcons>
        </a>
      </RouterLink>
    </BaseTooltip>
  </li>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;
.app-navigation-item {
  $self: &;

  margin-bottom: $space-xxxsmall;
  width: 100%;

  &__tooltip {
    width: 100%;
  }

  &__link {
    border-radius: $space-xxxsmall;
    display: inline-flex;
    padding: $space-xxsmall $space-xsmall;
    width: 100%;
  }

  &:not(#{$self}--disabled) {
    #{$self} {
      &__link {
        color: $color-text-inverted;
        text-decoration: none;
        position: relative;

        &:hover,
        &:focus,
        &--active {
          outline: none;
          background-color: $navy-900;
          color: $color-text-inverted;
        }
      }
    }
  }

  &--disabled {
    #{$self}__link {
      cursor: default;
    }
  }
}
</style>
