import type { InterceptorContent } from '../../../../types/content';

const content: InterceptorContent = {
  name: 'Direct',
  steps: [
    {
      title: 'Installation',
      details: `
No installation is required for Direct Mode as it operates through simple modifications to your HTTP requests.
`,
    },
    {
      title: 'Add Specific Headers to Your Request',
      details: `
Before modifying the request URL, add the following headers to your HTTP request:

>**x-lunar-scheme**: This header should reflect the original scheme of your request. For HTTPS requests, set this header to \`\`\`https.\`\`\`

>**x-lunar-host**: This header should be set to the host you intend to communicate with. For example, if your original request was to \`\`\`example.com\`\`\`, set this header to \`\`\`example.com\`\`\`.

>**x-lunar-consumer-tag**: Add the \`\`\`x-lunar-consumer-tag\`\`\` header to your requests with a value indicating the originating application or service. This header will be removed before the request is forwarded to third-party providers.
`,
    },
    {
      title: 'Modify Your Request URL',
      details: `
After adding the necessary headers, modify the URL of your request to route it through the Lunar Proxy.

The modified URL should follow this format:      
\`\`\`bash
http://lunar_proxy_address:lunar_proxy_port/original/request/path
\`\`\`

Replace \`\`\`lunar_proxy_address\`\`\` with the DNS or IP address of the Lunar Proxy, and \`\`\`lunar_proxy_port\`\`\` with the port number on which the Lunar Proxy is listening.

Assuming the Lunar Proxy's address is \`\`\`localhost\`\`\` and it's listening on port \`\`\`8000\`\`\`, your modified request to \`\`\`https://catfact.ninja/fact\`\`\` would now be:
\`\`\`bash
curl -i -H "x-lunar-host: catfact.ninja" -H "x-lunar-scheme: https" http://localhost:8000/fact
\`\`\`
`,
    },
    {
      title: 'Make Your Request',
      details: `
Submit your request with added headers and the modified URL. The Lunar Proxy in Direct Mode will route it to the intended destination, maintaining the original scheme and host.
      `,
    },
  ],
};

export default content;
