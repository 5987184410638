<script setup lang="ts">
import { computed, useAttrs, useSlots } from 'vue';

import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

defineProps<{
  href: string;
  iconSize?: number;
}>();

const slot = useSlots();
const attrs = useAttrs();

const computedClasses = computed(() => ({
  'menu-item--clickable': !!attrs.onClick,
}));

const hasRightIcon = computed(() => !!slot.rightIcon);
</script>

<template>
  <li class="menu-link" :class="computedClasses" role="menuitem">
    <a
      class="menu-link__anchor"
      :href="href"
      target="_blank"
      rel="nofollow noreferrer"
      ><BaseLayoutIcons class="menu-link__icons" :icon-size="iconSize"
        ><slot /><template v-if="hasRightIcon" #rightIcon
          ><span class="menu-link__right-icon"
            ><slot name="rightIcon" /></span></template></BaseLayoutIcons
    ></a>
  </li>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu-link {
  $self: &;

  list-style: none;

  &__anchor {
    color: $color-text-default;
    cursor: pointer;
    display: block;
    padding: $space-xxsmall $space-xsmall;
    text-decoration: none;

    &:hover {
      background-color: $color-bg-surface;
    }
  }

  &__icons {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__right-icon {
    display: flex;
    margin-left: $space-large;
  }
}
</style>
