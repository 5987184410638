<script lang="ts">
import { cva, type VariantProps } from 'cva';
import { Spacing } from '../../types';

export const dividerVariants = {
  direction: {
    horizontal: 'divider--horizontal',
    vertical: 'divider--vertical',
  },
  size: Spacing.reduce((obj: Record<string, string>, space: string) => {
    obj[space] = `divider--${space}`;
    return obj;
  }, {}) as Record<string, string>,
  mode: {
    dark: 'divider--mode-dark',
  },
};

const classes = cva({ variants: dividerVariants });

export type DividerProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
withDefaults(
  defineProps<{
    direction?: DividerProps['direction'];
    size?: DividerProps['size'];
    wrapper?: string;
    mode?: DividerProps['mode'];
  }>(),
  {
    direction: 'horizontal',
    wrapper: 'hr',
  }
);
</script>

<template>
  <component
    :is="wrapper"
    class="divider"
    :class="classes({ direction, size, mode })"
    role="none"
  />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.divider {
  $self: &;

  border: none;
  list-style: none;
  margin: 0;
  padding: 0;

  &--xxxsmall {
    margin: $space-xxxsmall 0;
  }

  &--xxsmall {
    margin: $space-xxsmall 0;
  }

  &--xsmall {
    margin: $space-xxsmall 0;
  }

  &--small {
    margin: $space-small 0;
  }

  &--medium {
    margin: $space-small 0;
  }

  &--large {
    margin: $space-large 0;
  }

  &--xlarge {
    margin: $space-xlarge 0;
  }

  &--horizontal {
    border-top: 1px solid $color-border-default;
    width: 100%;
  }

  &--vertical {
    border-left: 1px solid $color-border-default;
    min-height: $line-height-default;
    height: 100%;
  }

  &--mode-dark {
    border-color: rgba($color-dark-border-default, 0.2);
  }
}
</style>
