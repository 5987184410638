import { type PolicyContent, RemedyType } from '../../../types/content';
import strategyBasedThrottling from '../plugin/strategy-based-throttling';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Quota Management',
  comingSoon: false,
  summary:
    'Groups requests by configurable headers to allocate distinct quotas for services or environments.',
  plugins: [strategyBasedThrottling],
  docsUrl: undefined,
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: "example.com"
    method: "GET"
    remedies:
      - name: Quota Management
        enabled: true
        config:
          strategy_based_throttling:
            allowed_request_count: 30 # The number of requests allowed each day
            window_size_in_seconds: 86400 # One day
            response_status_code: 429 # The HTTP status code to return when the limit is exceeded.
            spillover_config:
              enabled: true
              renew_on_day: 1
            group_quota_allocation:
              group_by:
                header_name: X-Group 
              groups:
                - group_header_value: "staging"
                  allocation_percentage: 20
                - group_header_value: "production"
                  allocation_percentage: 80
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
