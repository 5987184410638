<script setup lang="ts">
import { computed, ref } from 'vue';
import { compareAsc, subSeconds, formatDistanceToNow } from 'date-fns';
import { type ColorfulIconProps } from '../BaseColorfulIcon/BaseColorfulIcon.vue';
import {
  type PrimitiveAggregatedDiscovery,
  InterceptorType,
  type InterceptorInstance,
} from '@monorepo/shared-model/src/aggregated-discovery.js';
import type { ReplaceT1WithT2 } from '@monorepo/toolkit-core/types';

import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseAnchor from '../BaseAnchor/BaseAnchor.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';

import DashboardConnection from '../DashboardConnection/DashboardConnection.vue';
import DashboardConnectionItem from '../DashboardConnectionItem/DashboardConnectionItem.vue';
import DashboardConnectionItemSkeleton from '../DashboardConnectionItemSkeleton/DashboardConnectionItemSkeleton.vue';

type InterceptorIconMap = {
  [K in InterceptorType]: ColorfulIconProps['name'];
};

const interceptorIconMap: InterceptorIconMap = {
  'lunar-java-interceptor': 'java',
  'lunar-py-interceptor': 'python',
  'lunar-ts-interceptor': 'javascript',
  unknown: 'directtraffic',
};

type PrimitiveInterceptorInstance = ReplaceT1WithT2<
  InterceptorInstance,
  Date,
  string
>;

interface PreparedInterceptorInstance extends PrimitiveInterceptorInstance {
  icon: string;
}

const props = defineProps<{
  placeholder?: boolean;
  onboarding?: boolean;
  skeleton?: boolean;
  interceptors?: PrimitiveAggregatedDiscovery['interceptors'];
  consumers?: PrimitiveAggregatedDiscovery['consumers'];
  compact?: boolean;
}>();

const emit = defineEmits<{
  install: [];
}>();

const onInstall = () => {
  emit('install');
};

const idleThreshold = ref(60 * 60); // 60 minutes

const isConnected = computed(() => {
  return !!(
    props.interceptors &&
    props.interceptors.instances.length &&
    props.interceptors.lastTransactionDate
  );
});

const preparedInterceptors = computed<PreparedInterceptorInstance[]>(() => {
  return (
    props.interceptors?.instances.map(
      (interceptor: PrimitiveInterceptorInstance) => ({
        icon: interceptorIconMap[interceptor.type],
        ...interceptor,
      })
    ) || []
  );
});

const interceptorCounterLabel = computed(() => {
  const interceptorCounter = preparedInterceptors.value.filter(
    (interceptor) => interceptor.type !== InterceptorType.Unknown
  ).length;

  if (!interceptorCounter || interceptorCounter === 1) return;

  return `(${interceptorCounter})`;
});

const isIdle = (interceptor: PreparedInterceptorInstance) => {
  return (
    compareAsc(
      interceptor.lastTransactionDate,
      subSeconds(new Date(), idleThreshold.value)
    ) === -1
  );
};

const displayName = (interceptor: PreparedInterceptorInstance) => {
  return interceptor.type === InterceptorType.Unknown
    ? 'Direct Traffic'
    : interceptor.name;
};

const displayVersion = (interceptor: PreparedInterceptorInstance) => {
  return interceptor.type === InterceptorType.Unknown
    ? 'N/A'
    : `v. ${interceptor.version}`;
};
</script>

<template>
  <DashboardConnection
    class="dashboard-connection-interceptors"
    icon="space-science-outline"
    :connected="isConnected"
    :onboarding="onboarding"
    :placeholder="placeholder"
    :compact="compact"
  >
    <template #title
      >Interceptors
      <span
        v-if="interceptorCounterLabel"
        class="dashboard-connection-interceptors__counter"
        v-text="interceptorCounterLabel"
    /></template>
    <template #addAction><slot name="addAction" /></template>

    <template v-if="isConnected">
      <DashboardConnectionItem
        v-for="interceptor in preparedInterceptors"
        :key="interceptor.type"
        :icon="interceptor.icon"
        :idle="isIdle(interceptor)"
      >
        <template #actionsContent>
          <BaseProse size="small">
            "{{ interceptor.name }}" has been idle for<br />
            {{ formatDistanceToNow(interceptor.lastTransactionDate) }}.
            <BaseAnchor
              href="https://docs.lunar.dev/installation-configuration/interceptors/"
              >Learn more</BaseAnchor
            >.
          </BaseProse>
        </template>
        {{ displayName(interceptor) }}
        <template #additional>{{ displayVersion(interceptor) }}</template>
      </DashboardConnectionItem>
    </template>

    <template v-else-if="onboarding && !skeleton">
      <BaseLayoutGap
        class="dashboard-connection-interceptors__onboarding"
        direction="column"
        alignment="center"
      >
        <BaseProse alignment="center">Now let's install a Consumer.</BaseProse>
        <BaseButton @click="onInstall">Continue</BaseButton>
      </BaseLayoutGap>
    </template>

    <template v-else-if="!compact">
      <DashboardConnectionItemSkeleton
        v-for="n in 2"
        :key="n"
        :placeholder="!skeleton"
      />
    </template>

    <template v-if="isConnected" #footer>
      <BaseLayoutGap alignment="space-between">
        <BaseAnchor
          class="dashboard-connection-interceptors__docs-link"
          href="https://docs.lunar.dev/installation-configuration/interceptors"
          size="small"
          blank
          ><template #leftIcon><BaseIcon name="book-open-text" /></template
          >Docs</BaseAnchor
        >
      </BaseLayoutGap>
    </template>
  </DashboardConnection>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-connection-interceptors {
  &__onboarding {
    margin-bottom: $space-large;
  }

  &__counter {
    color: $color-text-tertiary;
    font-size: $font-size-xsmall;
  }

  &__docs-link {
    margin-left: auto;
  }
}
</style>
