import { type PluginContent, RemedyType } from '../../../types/content';

const content: PluginContent = {
  type: RemedyType.Plugin,
  icon: 'har-log-collector',
  name: 'HAR Exporter',
  summary:
    'Export requests and responses by collecting HTTP Archive (HAR) logs.',
  category: ['Analysis'],
  docsUrl:
    'https://docs.lunar.dev/product-features/remedy-plugins/har-log-collector',
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: api.com/resource/{id}
    method: GET
    diagnosis:
      - name: "api.com HAR Log Collector"
        enabled: true
        config:
          har_exporter:
            transaction_max_size: 5000
            obfuscate:
              enabled: true
        export: "file"
        
exporters:
  file:
    file_dir: "/var/log/lunar-proxy"
    file_name: "output.log"
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
