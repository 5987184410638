<script setup lang="ts">
import { ref, useSlots, type RendererNode, computed, watch } from 'vue';
import { has, uniqueId } from 'lodash-es';

const props = withDefaults(
  defineProps<{
    active?: number;
  }>(),
  {
    active: 0,
  }
);

const activeIndex = ref(props.active);
const componentId = ref(uniqueId());

const slots = useSlots();
const acceptableChildren = ['BaseTabsPanel'];
const slotContent = ref(slots.default?.() || []);

const isAcceptableChild = (child: RendererNode) => {
  return !!(
    acceptableChildren.includes(child.type.__name) &&
    (has(child.props, 'header') || has(child.children, 'header'))
  );
};

const setActiveIndex = (index: number) => {
  activeIndex.value = index;
};

const filteredSlotContent = computed(() => {
  const tabs: RendererNode[] = [];

  slotContent.value.filter((child: RendererNode) => {
    if (isAcceptableChild(child)) {
      tabs.push(child);
    } else if (child.children && child.children instanceof Array) {
      child.children.forEach((nestedChild) => {
        if (isAcceptableChild(nestedChild)) {
          tabs.push(nestedChild);
        }
      });
    }
  });

  return tabs;
});

watch(
  () => props.active,
  () => {
    activeIndex.value = props.active;
  }
);
</script>

<template>
  <div class="tabs">
    <ul class="tabs__items" role="tablist">
      <li
        class="tabs__item"
        :class="{
          'tabs__item--active': activeIndex === index,
          'tabs__item--primary': tab.props?.variant === 'primary',
        }"
        v-for="(tab, index) in filteredSlotContent"
        :key="index"
        tabindex="0"
        role="tab"
        :aria-selected="activeIndex === index"
        :id="`tab-${componentId}-${index}`"
        @click="setActiveIndex(index)"
        @keydown.enter="setActiveIndex(index)"
      >
        <span v-if="tab.props?.header" v-text="tab.props?.header" />
        <component
          v-else-if="(tab.children as any).header"
          :is="(tab.children as any).header"
        />
      </li>
    </ul>

    <div class="tabs__body">
      <template v-for="(pane, index) in filteredSlotContent" :key="index">
        <component
          v-show="activeIndex === index"
          :is="pane"
          role="tabpanel"
          :id="`panel-${componentId}-${index}`"
          :aria-labelledby="`tab-${componentId}-${index}`"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.tabs {
  $self: &;

  &__items {
    background-color: $color-bg-default-subtle;
    border-radius: $space-xxsmall;
    display: inline-flex;
    padding: $space-xxsmall;
    gap: $space-xxxsmall;
    list-style: none;
    margin: 0;
  }

  &__item {
    color: $color-text-secondary;
    font-weight: $font-weight-semibold;
    padding: $space-xxxsmall $space-xxsmall;
    cursor: pointer;

    &:hover,
    &:focus,
    &--active {
      color: $color-text-default;
      background-color: $color-bg-default;
      border-radius: $space-xxxsmall;
      box-shadow: $elevation-xsmall;
    }

    &--primary {
      color: $color-text-brand;

      &:hover,
      &:focus,
      &#{$self}__item--active {
        background-color: $color-bg-brand;
        color: $color-text-inverted;
      }
    }

    &:focus {
      outline-color: $color-border-brand;
    }
  }

  &__body {
    padding: $space-large 0 0;
  }
}
</style>
