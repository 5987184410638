<script setup lang="ts">
import { computed, ref } from 'vue';

import type { PrimitiveProvider } from '@monorepo/shared-model/src/aggregated-discovery';
import { RemedyType, type RemedyContent } from '../../types/content';

import strategyBasedThrottling from '../../content/en/plugin/strategy-based-throttling';
import smartCaching from '../../content/en/plugin/smart-caching';
import apiAccountOrchestration from '../../content/en/plugin/api-account-orchestration';

import { plugins } from '../../content/en/plugin';
import { policies } from '../../content/en/policy';
import quotaGroupsPrioritization from '../../content/en/policy/quota-groups-prioritization';
import openaiUserRateLimiting from '../../content/en/flow/openai-user-rate-limiting';
import cacheGoogleMapsApi from '../../content/en/flow/cache-google-maps-api';
import concurrentRateLimitingElevenlabsApi from '../../content/en/flow/concurrent-rate-limiting-elevenlabs-api';
import reroutingApiCallBetweenApis from '../../content/en/flow/rerouting-api-call-between-apis';

import AppPageDrawerHeading from '../AppPageDrawerHeading/AppPageDrawerHeading.vue';

import BaseHeading from '../BaseHeading/BaseHeading.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseBadge from '../BaseBadge/BaseBadge.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseDuotoneIcon from '../BaseDuotoneIcon/BaseDuotoneIcon.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseAccordion from '../BaseAccordion/BaseAccordion.vue';
import BaseTabs from '../BaseTabs/BaseTabs.vue';
import BaseTabsPanel from '../BaseTabsPanel/BaseTabsPanel.vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';

import PluginsInstallationPlugin from '../PluginsInstallationPlugin/PluginsInstallationPlugin.vue';
import PluginsInstallationPolicy from '../PluginsInstallationPolicy/PluginsInstallationPolicy.vue';
import PluginsInstallationFlow from '../PluginsInstallationFlow/PluginsInstallationFlow.vue';

const props = defineProps<{
  provider: PrimitiveProvider;
  suggestions?: boolean;
}>();

const emit = defineEmits<{
  applyRemediation: [provider: PrimitiveProvider, remedy: RemedyContent];
  removeRemediation: [provider: PrimitiveProvider, remedy: RemedyContent];
}>();

const selectedRemedy = ref<RemedyContent | null>();

const endpoint = computed(() => {
  return props.provider.endpoints[0];
});

const endpointPath = computed(() => {
  return `${props.provider.host}${endpoint.value.path}`;
});

const flows = computed(() => {
  if (props.provider.host === 'api.openai.com') {
    return [openaiUserRateLimiting, reroutingApiCallBetweenApis];
  }

  if (props.provider.host === 'google.com') {
    return [cacheGoogleMapsApi];
  }

  if (props.provider.host === 'api.elevenlabs.io') {
    return [concurrentRateLimitingElevenlabsApi];
  }

  if (props.provider.host === 'api.anthropic.com') {
    return [reroutingApiCallBetweenApis];
  }

  return [];
});

const recommendedRemedies = computed<RemedyContent[]>(() => {
  if (flows.value.length) return flows.value;

  return [
    strategyBasedThrottling,
    apiAccountOrchestration,
    smartCaching,
    quotaGroupsPrioritization,
  ];
});

const setSelectedRemedy = (remedy: RemedyContent | null) => {
  selectedRemedy.value = remedy;
};

const onApplyRemediation = (remedy: RemedyContent) => {
  emit('applyRemediation', props.provider, remedy);
};

const onRemoveRemediation = (remedy: RemedyContent) => {
  emit('removeRemediation', props.provider, remedy);
};

const isRemedyInstalled = (remedy: RemedyContent) => {
  return endpoint.value.remedies?.some(
    (installedRemedy) => installedRemedy.name === remedy.name
  );
};

const isRemedyRecommended = (remedy: RemedyContent) => {
  if (!props.suggestions) return;

  return recommendedRemedies.value.some(
    (recommendedRemedy) => recommendedRemedy.name === remedy.name
  );
};

const path = '`' + endpoint.value.path + '`';

const recommendedMessage = `
::: danger
##### Rate-limiting issue

Endpoint ${path} has more than 5% of status code 429 responses, indicating a rate-limiting issue.
:::
`;
</script>

<template>
  <div class="dashboard-add-remediation">
    <template v-if="selectedRemedy">
      <BaseButton
        class="dashboard-add-remediation__back"
        size="small"
        variant="ghost"
        @click="setSelectedRemedy(null)"
        ><template #leftIcon><BaseIcon name="arrow-left" /></template
        >Back</BaseButton
      >

      <PluginsInstallationPlugin
        v-if="selectedRemedy.type === RemedyType.Plugin"
        :plugin="selectedRemedy"
        :installed="isRemedyInstalled(selectedRemedy)"
        auto-apply
        @apply-remediation="onApplyRemediation"
        @remove-remediation="onRemoveRemediation"
      />

      <PluginsInstallationPolicy
        v-if="selectedRemedy.type === RemedyType.Policy"
        :policy="selectedRemedy"
        :installed="isRemedyInstalled(selectedRemedy)"
        auto-apply
        @apply-remediation="onApplyRemediation"
        @remove-remediation="onRemoveRemediation"
      />

      <PluginsInstallationFlow
        v-if="selectedRemedy.type === RemedyType.Flow"
        :flow="selectedRemedy"
        :installed="isRemedyInstalled(selectedRemedy)"
        @apply-remediation="onApplyRemediation"
        @remove-remediation="onRemoveRemediation"
      />
    </template>
    <template v-else>
      <AppPageDrawerHeading>Add plugins &amp; flows</AppPageDrawerHeading>

      <div class="dashboard-add-remediation__endpoint">
        <BaseHeading :level="5" :semantic-level="2" weight="semibold"
          >Endpoint path</BaseHeading
        >
        <BaseProse>
          <pre data-copy="false"><code>{{ endpointPath }}</code></pre>
        </BaseProse>
      </div>

      <BaseTabs>
        <BaseTabsPanel v-if="suggestions" header="Recommended">
          <BaseLayoutGap direction="column" size="large">
            <BaseProse size="small">
              <template #default="{ markdownToHtml }">
                <div v-html="markdownToHtml(recommendedMessage)" />
              </template>
            </BaseProse>

            <div class="dashboard-add-remediation__remedies">
              <div class="dashboard-add-remediation__remedies-lead">
                <BaseHeading :level="5" :semantic-level="2" weight="semibold"
                  >Recommended solutions</BaseHeading
                >
                <BaseProse size="small"
                  >These solutions will help you fix the rate-limiting
                  issue.</BaseProse
                >
              </div>

              <BaseAccordion
                v-for="remedy in recommendedRemedies"
                :key="remedy.name"
                navigate
                @click="setSelectedRemedy(remedy)"
              >
                <template #heading>
                  <BaseLayoutIcons v-if="remedy.type === RemedyType.Plugin">
                    <template #leftIcon>
                      <BaseDuotoneIcon :name="remedy.icon" />
                    </template>
                    {{ remedy.name }}
                  </BaseLayoutIcons>
                  <template v-else>{{ remedy.name }}</template>
                  <BaseBadge
                    v-if="isRemedyInstalled(remedy)"
                    variant="success-subtle"
                    size="small"
                    >Installed</BaseBadge
                  >
                </template>

                <BaseProse size="small">
                  <p>{{ remedy.summary }}</p>
                </BaseProse>
              </BaseAccordion>
            </div>
          </BaseLayoutGap>
        </BaseTabsPanel>
        <BaseTabsPanel header="Plugins">
          <BaseAccordion
            v-for="plugin in plugins"
            :key="plugin.name"
            navigate
            @click="setSelectedRemedy(plugin)"
          >
            <template #heading>
              <BaseLayoutIcons>
                <template #leftIcon>
                  <BaseDuotoneIcon :name="plugin.icon" />
                </template>
                {{ plugin.name }}
              </BaseLayoutIcons>
              <BaseBadge
                v-if="isRemedyInstalled(plugin)"
                variant="success-subtle"
                size="small"
                >Installed</BaseBadge
              >
              <BaseBadge
                v-else-if="isRemedyRecommended(plugin)"
                variant="brand"
                size="small"
                >Recommended</BaseBadge
              >
            </template>

            <BaseProse size="small">
              <p>{{ plugin.summary }}</p>
            </BaseProse>
          </BaseAccordion>
        </BaseTabsPanel>
        <BaseTabsPanel header="Flows">
          <BaseAccordion
            v-for="flow in flows"
            :key="flow.name"
            navigate
            @click="setSelectedRemedy(flow)"
          >
            <template #heading>
              {{ flow.name }}
              <BaseBadge
                v-if="isRemedyInstalled(flow)"
                variant="success-subtle"
                size="small"
                >Installed</BaseBadge
              >
              <BaseBadge
                v-else-if="isRemedyRecommended(flow)"
                variant="brand"
                size="small"
                >Recommended</BaseBadge
              >
            </template>

            <BaseProse size="small">
              <p>{{ flow.summary }}</p>
            </BaseProse>
          </BaseAccordion>

          <BaseAccordion
            v-for="policy in policies"
            :key="policy.name"
            navigate
            @click="setSelectedRemedy(policy)"
          >
            <template #heading>
              {{ policy.name }}
              <BaseBadge
                v-if="isRemedyInstalled(policy)"
                variant="success-subtle"
                size="small"
                >Installed</BaseBadge
              >
              <BaseBadge
                v-else-if="isRemedyRecommended(policy)"
                variant="brand"
                size="small"
                >Recommended</BaseBadge
              >
              <BaseBadge
                v-if="policy.comingSoon"
                variant="brand-secondary"
                size="small"
                chip
                >Coming soon</BaseBadge
              >
            </template>

            <BaseProse size="small">
              <BaseLayoutGap wrap
                ><BaseBadge
                  v-for="plugin in policy.plugins"
                  :key="plugin.name"
                  chip
                  ><template #leftIcon
                    ><BaseDuotoneIcon
                      v-if="plugin.icon"
                      :name="plugin.icon" /></template
                  >{{ plugin.name }}</BaseBadge
                ></BaseLayoutGap
              >
              <p>{{ policy.summary }}</p>
            </BaseProse>
          </BaseAccordion>
        </BaseTabsPanel>
      </BaseTabs>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-add-remediation {
  $self: &;

  &__back {
    margin-bottom: $space-small;
    position: relative;
    left: -$space-xxsmall;
  }

  &__endpoint {
    margin-bottom: $space-large;
  }

  &__remedies {
    width: 100%;
  }

  &__remedies-lead {
    margin-bottom: $space-small;
  }
}
</style>
