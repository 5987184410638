<script setup lang="ts">
import { computed } from 'vue';
import '../../assets/styles/primevue/themes/lunar/theme.scss';
import DataTable from 'primevue/datatable';

const props = withDefaults(
  defineProps<{
    /**
     * The data to display in the table.
     */
    value?: any[];
    /**
     * Clicking a row triggers expansion.
     */
    rowExpander?: boolean;
    /**
     * The key to use as the row identifier.
     */
    rowKey?: string;
  }>(),
  {
    rowKey: 'id',
  }
);

const expandedRows = defineModel<any[]>('expandedRows', {
  default: [],
});

const computedClasses = computed(() => ({
  'table--row-expander': props.rowExpander,
}));

const onRowClick = (payload: any) => {
  if (!props.rowExpander) {
    return;
  }

  const data = payload.data;
  const expandedIndex = expandedRows.value.findIndex(
    (row: any) => row[props.rowKey] === data[props.rowKey]
  );

  if (expandedIndex === -1) {
    expandedRows.value = [...expandedRows.value, data];
  } else {
    expandedRows.value = [
      ...expandedRows.value.slice(0, expandedIndex),
      ...expandedRows.value.slice(expandedIndex + 1),
    ];
  }
};
</script>

<template>
  <DataTable
    class="table"
    :class="computedClasses"
    :value="value"
    v-model:expanded-rows="expandedRows"
    @row-click="onRowClick"
  >
    <slot />
    <template #expansion="slotProps">
      <slot name="expansion" v-bind="slotProps" />
    </template>
  </DataTable>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.table {
  font-size: $font-size-small;

  // Strange scrolling behaviour caused by inline style
  // applied during render. This is a workaround to force
  // overflow to hidden.
  &:deep(.p-datatable-wrapper) {
    overflow: hidden !important;

    &:first-of-type {
      overflow: inherit !important;
    }
  }

  // Ensure table rows have relative positioning to contain
  // absolutely positioned child elements.
  &:deep(.p-datatable-thead > tr),
  &:deep(.p-datatable-tbody > tr) {
    position: relative;
  }

  // Overrides for table headers and cells.
  &:deep(.p-datatable-thead > tr > th),
  &:deep(.p-datatable-tbody > tr > td) {
    padding: $space-xxsmall $space-medium;
  }

  &:deep(.p-datatable-thead > tr > th) {
    color: $color-text-tertiary;
    font-family: $font-family-default;
    font-weight: $font-weight-semibold;
  }

  &:deep(.p-datatable-tbody > tr > td) {
    color: $color-text-default;
    height: $scale-xxlarge;
    font-size: $font-size-xsmall;
  }

  // Overrides for sorting icons on table header.
  &:deep(.p-sortable-column .p-sortable-column-icon) {
    height: 1em;
    margin-left: $space-xxsmall;
    position: relative;
    top: -1px;
    width: 1em;
  }

  // Remove background color from row expander icon.
  &:deep(.p-datatable-tbody > tr > td .p-row-toggler:enabled:hover) {
    background: none;
  }

  // Add outer padding for row expansion cell which acts as a container.
  &:deep(.p-datatable-tbody > tr > td[data-pc-section='rowexpansioncell']) {
    background-color: $color-bg-default;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAACtJREFUKFNjZCASMBKpjoE8hZ8/f/6PbAMvLy/cIBQTB1AhvhAgz9f4TAQAMSwQC5ydKKkAAAAASUVORK5CYII=)
      repeat;
    background-size: 6px;
    padding: $space-medium;
  }

  // Crazy selectors to handle border radius of nested tables.
  &:deep(
      .p-datatable-tbody
        > tr
        > td[data-pc-section='rowexpansioncell']
        .p-datatable-table
    ) {
    border: 1px solid $color-border-default;
    border-radius: $space-xxsmall;
    overflow: hidden;
  }

  &:deep(
      .p-datatable-tbody
        > tr
        > td[data-pc-section='rowexpansioncell']
        .p-datatable-thead
        > tr
        > th
    ) {
    font-size: $font-size-xsmall;
    font-weight: $font-weight-bold;
  }

  &:deep(
      .p-datatable-tbody
        > tr
        > td[data-pc-section='rowexpansioncell']
        .p-datatable-table
        .p-datatable-thead
        > tr:first-of-type
        > th:first-of-type
    ) {
    border-radius: $space-xxsmall 0 0 0;
  }

  &:deep(
      .p-datatable-tbody
        > tr
        > td[data-pc-section='rowexpansioncell']
        .p-datatable-table
        .p-datatable-thead
        > tr:first-of-type
        > th:last-of-type
    ) {
    border-radius: 0 $space-xxsmall 0 0;
  }

  &:deep(
      .p-datatable-tbody
        > tr
        > td[data-pc-section='rowexpansioncell']
        .p-datatable-table
        .p-datatable-tbody
        tr:last-of-type
        td:last-of-type
    ) {
    border-radius: 0 0 $space-xxsmall 0;
  }

  &:deep(
      .p-datatable-tbody
        > tr
        > td[data-pc-section='rowexpansioncell']
        .p-datatable-table
        .p-datatable-tbody
        tr:last-of-type
        td:first-of-type
    ) {
    border-radius: 0 0 0 $space-xxsmall;
  }

  // Align first column of nested table with first column of parent row.
  &:deep(
      td[data-pc-section='rowexpansioncell']
        .p-datatable-thead
        > tr
        > th:first-child
    ),
  &:deep(
      td[data-pc-section='rowexpansioncell']
        .p-datatable-tbody
        > tr
        > td:first-child
    ) {
    padding-left: calc(($space-large - $space-xxsmall) * 2) + 32px;
  }

  // Remove border from last row of nested table to avoid doubling effect.
  &:deep(
      td[data-pc-section='rowexpansioncell']
        .p-datatable-tbody
        > tr:last-of-type
        > td
    ) {
    border-bottom: 0;
  }

  // Collapse last empty row and remove the border.
  &:deep(.p-datatable-table .p-datatable-tbody .p-datatable-emptymessage td) {
    padding: 0;
    border-bottom: 0;
  }

  // Funky selector to style the row hover state.
  &--row-expander {
    &:deep(
        .p-datatable-table
          > .p-datatable-tbody
          > tr:not(.p-datatable-row-expansion):not(
            .p-datatable-row-expansion tr
          ):hover
      ) {
      background-color: $color-bg-surface;
      cursor: pointer;
    }
  }
}
</style>
