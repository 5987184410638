<script lang="ts">
import { cva, cx, type VariantProps } from 'cva';

export const messageVariants = {
  variant: {
    none: undefined,
    success: 'message--success',
    info: 'message--info',
    warning: 'message--warning',
    danger: 'message--danger',
  },
  closable: {
    true: 'message--closable',
  },
  alignment: {
    left: undefined,
    center: 'message--center',
  },
  inline: {
    true: 'message--inline',
  },
};

const classes = cva({ variants: messageVariants });

export type MessageProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';

defineProps<{
  variant?: MessageProps['variant'];
  disabled?: boolean;
  closable?: boolean;
  alignment?: MessageProps['alignment'];
  inline?: boolean;
}>();

const emit = defineEmits<{
  close: [];
}>();

const slots = useSlots();

const hasLeftIcon = computed(() => !!slots.leftIcon);

const computedClasses = computed(() => ({
  'message--has-left-icon': hasLeftIcon.value,
}));

const onClose = () => {
  emit('close');
};
</script>

<template>
  <div
    class="message"
    :class="
      cx(classes({ variant, closable, alignment, inline }), computedClasses)
    "
  >
    <BaseLayoutIcons wrapper="div" class="message__container">
      <template v-if="hasLeftIcon" #leftIcon>
        <slot name="leftIcon" />
      </template>
      <slot />
    </BaseLayoutIcons>

    <BaseButton
      v-if="closable"
      class="message__close"
      size="small"
      variant="raw"
      aria-label="Close"
      @click="onClose()"
      @keydown.enter="onClose()"
      ><BaseIcon name="close"
    /></BaseButton>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.message {
  $self: &;

  color: $color-text-tertiary;
  background-color: $color-bg-default-subtle;
  border-radius: $space-xxsmall;
  font-weight: $font-weight-semibold;
  line-height: $line-height-default;
  padding: $space-xxsmall $space-small;
  position: relative;

  + #{$self} {
    margin-top: $space-small;
  }

  &__close {
    font-size: $font-size-xsmall;
    position: absolute;
    transform: translateY(-50%);
    right: $space-small;
    top: 50%;

    &:hover {
      color: $color-text-brand;
    }
  }

  &--has-left-icon {
    padding-left: $space-small;
  }

  &--center {
    text-align: center;
  }

  &--inline {
    display: inline-block;
  }

  &--closable {
    padding-right: $space-xlarge;
  }

  &--success {
    background-color: $color-bg-success-subtle;
    color: $color-text-success;

    #{$self}__close {
      color: $color-text-success;

      &:hover {
        color: $color-text-brand;
      }
    }
  }

  &--danger {
    background-color: $color-bg-danger-subtle;
    color: $color-text-danger;

    #{$self}__close {
      color: $color-text-danger;

      &:hover {
        color: $color-text-brand;
      }
    }
  }

  &--info {
    background-color: $color-bg-info-subtle;
    color: $color-text-info;

    #{$self}__close {
      color: $color-text-info;

      &:hover {
        color: $color-text-brand;
      }
    }
  }

  &--warning {
    background-color: $color-bg-warning-subtle;
    color: $color-text-warning;

    #{$self}__close {
      color: $color-text-warning;

      &:hover {
        color: $color-text-brand;
      }
    }
  }
}
</style>
