import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useUserStore } from '../../stores/user';
import type { FeatureFlag } from '../../types';

export function hasFeatureFlagGuard(featureFlag: FeatureFlag) {
  return async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const userStore = useUserStore();
    await userStore.waitForInitialization();

    if (userStore.permissions.featureFlag[featureFlag]) {
      next();
    } else {
      next({ name: 'dashboard' });
    }
  };
}
