<script setup lang="ts">
import AppLayoutSidebar from '../components/AppLayoutSidebar/AppLayoutSidebar.vue';
import AppArchitecture from '../components/AppArchitecture/AppArchitecture.vue';

import { useUserStore } from '../stores/user';
import { FeatureFlag } from '../types';

defineProps<{
  skeleton?: boolean;
}>();

const userStore = useUserStore();
</script>

<template>
  <template v-if="userStore.hasFeatureFlag(FeatureFlag.AltNavigation)">
    <RouterView v-slot="{ Component }">
      <!-- 
        TODO: KeepAlive isn't available while the provider page is using the 
        data retrieved for the dashboard page. Data needs to be reloaded with each
        route change. 
        -->
      <template v-if="userStore.hasFeatureFlag(FeatureFlag.ApiProviderView)">
        <component :is="Component" :skeleton="skeleton" />
      </template>
      <template v-else>
        <KeepAlive>
          <component :is="Component" :skeleton="skeleton" />
        </KeepAlive>
      </template>
    </RouterView>
  </template>
  <AppLayoutSidebar
    v-else
    class="control-pane"
    id="app-sidebar"
    collapsible
    sticky
  >
    <template #sidebar="{ collapsed }">
      <AppArchitecture
        :permissions="userStore.permissions"
        ut
        :collapsed="collapsed"
        :skeleton="skeleton"
      />
    </template>

    <RouterView v-slot="{ Component }">
      <KeepAlive>
        <component :is="Component" :skeleton="skeleton" />
      </KeepAlive>
    </RouterView>
  </AppLayoutSidebar>
</template>

<style scoped lang="scss">
@use '../../src/assets/styles/utils' as *;

.control-pane {
  &__block {
    flex: 1;
  }

  &__container {
    margin: $space-large $space-small 0;
  }
}
</style>
