import { type PluginContent, RemedyType } from '../../../types/content';

const content: PluginContent = {
  type: RemedyType.Plugin,
  icon: 'account-orchestration',
  name: 'LLMs Orchestration',
  summary: '',
  category: [],
  docsUrl: undefined,
  details: '',
};

export default content;
