<script setup lang="ts">
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStorage } from '@vueuse/core';
import type { DateRange } from '@monorepo/shared-model/src/webserver-events';
import {
  emptyStatusCodeGroups,
  type PrimitiveProvider,
} from '@monorepo/shared-model/src/aggregated-discovery';
import type { RemedyContent } from '../types/content';

import DashboardInstallation from '../components/DashboardInstallation/DashboardInstallation.vue';
import DashboardOverview from '../components/DashboardOverview/DashboardOverview.vue';

import { useDataStore } from '../stores/data';
import { useUserStore } from '../stores/user';
import { FeatureFlag } from '../types';

const props = defineProps<{
  skeleton?: boolean;
}>();

const route = useRoute();
const userStore = useUserStore();
const dataStore = useDataStore();

const dateRange = useStorage<DateRange>(
  'dashboard-date-range',
  dataStore.filters.dateRange,
  sessionStorage,
  {
    serializer: {
      read: (v: any): DateRange => {
        const parsed = JSON.parse(v);

        if (parsed.dateRangeType === 'absolute') {
          return {
            ...parsed,
            startDate: new Date(parsed.startDate),
            endDate: new Date(parsed.endDate),
          };
        }

        return parsed;
      },
      write: (v: any) => JSON.stringify(v),
    },
  }
);
const filteredConsumerTags = useStorage<string[]>(
  'dashboard-filtered-consumer-tags',
  dataStore.filters.filteredConsumerTags,
  sessionStorage
);

const onboarding = computed(() => {
  return (
    !dataStore.isConnected ||
    !dataStore.isProxyInstalled ||
    !dataStore.isInterceptorInstalled
  );
});

const overviewProps = computed<
  InstanceType<typeof DashboardOverview>['$props']
>(() => {
  if (props.skeleton) {
    return {
      skeleton: true,
      noResults: false,
      onboarding: false,
      consumers: dataStore.consumers,
      traffic: {
        count: { current: 0 },
        errorRate: { current: 0 },
        runtimeMs: { avg: { current: 0 } },
        statusCodes: emptyStatusCodeGroups(),
        providers: {
          count: 0,
          endpointCount: 0,
          instances: [],
        },
      },
      urlTree: undefined,
    };
  }

  const noResults =
    dataStore.isConnected &&
    dataStore.isProxyInstalled &&
    dataStore.isInterceptorInstalled &&
    dataStore.traffic.providers.count === 0;

  const traffic = dataStore.traffic;
  const urlTree = dataStore.urlTree;

  return {
    urlTree,
    consumers: dataStore.consumers,
    traffic,
    noResults,
    onboarding: onboarding.value,
  };
});

function onApplyRemediation(
  provider: PrimitiveProvider,
  remedy: RemedyContent
) {
  dataStore.applyRemediation(provider, remedy);
}

function onRemoveRemediation(
  provider: PrimitiveProvider,
  remedy: RemedyContent
) {
  dataStore.removeRemediation(provider, remedy);
}

watch(
  () => route.path,
  () => {
    dataStore.getAggregatedDiscovery({
      dateRange: dateRange.value,
      filteredConsumerTags: filteredConsumerTags.value,
    });
  }
);

watch(
  [dateRange, filteredConsumerTags],
  ([newDateRange, newFilteredConsumerTags]) => {
    dataStore.getAggregatedDiscovery({
      dateRange: newDateRange,
      filteredConsumerTags: newFilteredConsumerTags,
    });
  },
  { immediate: true }
);
</script>

<template>
  <DashboardInstallation
    v-if="userStore.hasFeatureFlag(FeatureFlag.AltNavigation) && onboarding"
  />
  <DashboardOverview
    v-else
    v-bind="overviewProps"
    v-model:date-range="dateRange"
    v-model:filtered-consumer-tags="filteredConsumerTags"
    @apply-remediation="onApplyRemediation"
    @remove-remediation="onRemoveRemediation"
  />
</template>

<style scoped lang="scss">
@use '../assets/styles/utils' as *;

.dashboard {
  display: flex;
  flex: 1;

  &__sidebar {
    width: 320px;
  }
}
</style>
