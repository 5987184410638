<script setup lang="ts">
import { computed } from 'vue';

import type {
  PrimitiveAggregatedDiscovery,
  PrimitiveProvider,
} from '@monorepo/shared-model/src/aggregated-discovery';
import type { DateRange } from '@monorepo/shared-model/src/webserver-events';
import type { RemedyContent } from '../../types/content';
import { FeatureFlag } from '../../types';

import type { PrimitiveUrlTreeDAO } from '@monorepo/shared-model/src/url-tree';

import BaseBlock from '../BaseBlock/BaseBlock.vue';
import BaseBlockHeader from '../BaseBlockHeader/BaseBlockHeader.vue';
import BaseBlockFooter from '../BaseBlockFooter/BaseBlockFooter.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';

import DashboardOverviewTableSkeleton from '../DashboardOverviewTableSkeleton/DashboardOverviewTableSkeleton.vue';
import DashboardOverviewTableOnboarding from '../DashboardOverviewTableOnboarding/DashboardOverviewTableOnboarding.vue';
import DashboardOverviewTableNoResults from '../DashboardOverviewTableNoResults/DashboardOverviewTableNoResults.vue';
import DashboardOverviewTable from '../DashboardOverviewTable/DashboardOverviewTable.vue';
import DashboardOverviewFilterConsumersDropdown from '../DashboardOverviewFilterConsumersDropdown/DashboardOverviewFilterConsumersDropdown.vue';
import DashboardOverviewFilterDateRangeDropdown from '../DashboardOverviewFilterDateRangeDropdown/DashboardOverviewFilterDateRangeDropdown.vue';
import DashboardMetrics from '../DashboardMetrics/DashboardMetrics.vue';

import { useUserStore } from '../../stores/user';

const props = defineProps<{
  skeleton?: boolean;
  onboarding?: boolean;
  noResults?: boolean;
  consumers?: PrimitiveAggregatedDiscovery['consumers'];
  traffic?: PrimitiveAggregatedDiscovery['traffic'];
  urlTree?: PrimitiveUrlTreeDAO;
}>();

const emit = defineEmits<{
  applyRemediation: [provider: PrimitiveProvider, plugin: RemedyContent];
  removeRemediation: [provider: PrimitiveProvider, plugin: RemedyContent];
}>();

const userStore = useUserStore();

const dateRange = defineModel<DateRange>('dateRange');
const filteredConsumerTags = defineModel<string[]>('filteredConsumerTags');

const providerCounterLabel = computed(() => {
  const providersCounter = props.traffic?.providers.count || 0;

  if (providersCounter <= 1) return;

  return `(${providersCounter})`;
});

const onApplyRemediation = (
  provider: PrimitiveProvider,
  remedy: RemedyContent
) => {
  emit('applyRemediation', provider, remedy);
};

const onRemoveRemediation = (
  provider: PrimitiveProvider,
  remedy: RemedyContent
) => {
  emit('removeRemediation', provider, remedy);
};
</script>

<template>
  <BaseBlock class="dashboard-overview">
    <BaseBlockHeader
      class="dashboard-overview__header"
      :icon="
        userStore.hasFeatureFlag(FeatureFlag.AltNavigation)
          ? 'cloud-data-transfer-outline'
          : 'four-squares-outline'
      "
    >
      <template #title
        >{{
          userStore.hasFeatureFlag(FeatureFlag.AltNavigation)
            ? 'Providers'
            : 'Overview'
        }}
        <span
          v-if="providerCounterLabel"
          class="dashboard-overview__counter"
          v-text="providerCounterLabel"
      /></template>

      <div class="dashboard-overview__filters">
        <BaseLayoutGap class="dashboard-overview__filters">
          <DashboardOverviewFilterConsumersDropdown
            v-if="consumers"
            class="dashboard-overview__filter"
            v-model="filteredConsumerTags"
            :consumers="consumers"
            :disabled="skeleton"
          />
          <DashboardOverviewFilterDateRangeDropdown
            class="dashboard-overview__filter"
            v-model="dateRange"
            :disabled="skeleton"
          />
        </BaseLayoutGap>
      </div>
    </BaseBlockHeader>

    <div v-if="traffic" class="dashboard-overview__metrics">
      <DashboardMetrics
        :skeleton="skeleton"
        :onboarding="onboarding"
        :traffic="traffic"
      />
    </div>

    <DashboardOverviewTableSkeleton v-if="skeleton" :rows="9" />
    <DashboardOverviewTableOnboarding v-else-if="onboarding" />
    <DashboardOverviewTableNoResults v-else-if="noResults" />
    <DashboardOverviewTable
      v-else-if="traffic"
      :traffic="traffic"
      :url-tree="urlTree"
      @apply-remediation="onApplyRemediation"
      @remove-remediation="onRemoveRemediation"
    />
    <BaseBlockFooter />
  </BaseBlock>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;
.dashboard-overview {
  height: 100%;
  min-height: calc(100vh - $space-large * 2);

  &__onboarding {
    padding: $space-xlarge $space-large;
  }

  &__header {
    margin-bottom: -$space-xxxsmall - 1px;
    position: relative;
    top: -$space-xxxsmall - 1px;
  }

  &__filters {
    justify-content: flex-end;
  }

  &__counter {
    color: $color-text-tertiary;
    font-size: $font-size-xsmall;
  }

  &__metrics {
    margin-bottom: $space-large;
    padding: $space-large $space-small;
  }
}
</style>
../DashboardOverviewFilterDateRangeDropdown/DashboardOverviewFilterDateRangeDropdown.vue
