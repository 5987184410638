import { type PluginContent, RemedyType } from '../../../types/content';

const content: PluginContent = {
  type: RemedyType.Plugin,
  icon: 'account-orchestration',
  name: 'API Account Orchestration',
  summary:
    'Balance API loads seamlessly across multiple accounts for enhanced performance.',
  category: ['Traffic control'],
  docsUrl:
    'https://docs.lunar.dev/product-features/remedy-plugins/api-account-orchestration',
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: api.com/resource/{id}
    method: GET
    remedies:
      - name: api.com API Account Orchestration
        enabled: true
        config:
          account_orchestration:
            round_robin:
              - aliceb@comp.io
              - johnf@comp.io
              - bobc@comp.io

accounts:
  aliceb@comp.io:
    tokens:
      - header:
          name: Authorization
          value: Bearer 123
  johnf@comp.io:
    tokens:
      - header:
          name: Authorization
          value: Bearer 456
  bobc@comp.io:
    tokens:
      - header:
          name: Authorization
          value: Bearer 789
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
