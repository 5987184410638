import { type PluginContent, RemedyType } from '../../../types/content';

const content: PluginContent = {
  type: RemedyType.Plugin,
  icon: 'retry-policy',
  name: 'Retry',
  summary:
    'Retries failed requests with customizable criteria and client-side offloading.',
  category: ['Traffic control'],
  docsUrl: 'https://docs.lunar.dev/product-features/remedy-plugins/retry',
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: api.com/resource/{id}
    method: GET
    remedies:
      - name: Retry
        enabled: true
        config:
          retry:
            attempts: 3
            initial_cooldown_seconds: 1
            cooldown_multiplier: 2
            conditions:
              status_code:
                from: 500
                to: 599
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
