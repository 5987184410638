import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useUserStore } from '../../stores/user';

export async function hasDevToolAccessGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore();
  await userStore.waitForInitialization();

  if (userStore.permissions.canAccessDevTools) {
    next();
  } else {
    next({ name: 'dashboard' });
  }
}
