import { type PolicyContent, RemedyType } from '../../../types/content';
import strategyBasedThrottling from '../plugin/strategy-based-throttling';
import costBreakdownCalculations from '../plugin/cost-breakdown-calculations';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Cost Limits Management',
  comingSoon: true,
  summary:
    'Manages API costs by enforcing consumption limits and providing real-time cost breakdowns.',
  plugins: [strategyBasedThrottling, costBreakdownCalculations],
  docsUrl: undefined,
  details: `
This policy enforces consumption limits on API usage through Strategy-Based Throttling and provides real-time cost breakdowns using Cost Breakdown Calculations, allowing effective management and optimization of API-related costs.
`,
};

export default content;
