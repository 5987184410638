import type { CreateEndpointOptions, DemoEndpoint } from './types';

export function createEndpointsWorker() {
  const endpointsWorker = new Worker(
    new URL('./endpoints-worker.ts', import.meta.url),
    {
      type: 'module',
    }
  );

  return {
    postMessage(data?: CreateEndpointOptions) {
      endpointsWorker.postMessage(data);
    },
    onMessage(callback: (endpoints: DemoEndpoint[]) => void) {
      endpointsWorker.onmessage = function (
        event: MessageEvent<DemoEndpoint[]>
      ) {
        callback(event.data);
      };
    },
    terminate() {
      endpointsWorker.terminate();
    },
  };
}
