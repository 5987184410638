<script setup lang="ts">
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';

const emit = defineEmits<{
  close: [];
}>();

function onClose() {
  emit('close');
}
</script>

<template>
  <div class="app-tour">
    <div class="app-tour__skip">
      <BaseButton class="app-tour__close" variant="secondary" @click="onClose"
        ><template #leftIcon><BaseIcon name="close" /></template
      ></BaseButton>
    </div>
    <div class="app-tour__tour">
      <div>
        <component
          :is="'script'"
          src="https://js.storylane.io/js/v1/storylane.js"
        />
        <div
          class="sl-embed"
          style="
            position: relative;
            padding-bottom: calc(44.08% + 27px);
            width: 100%;
            height: 0;
            transform: scale(1);
          "
        >
          <iframe
            class="sl-demo"
            src="https://app.storylane.io/demo/exktei4caujs"
            name="sl-embed"
            allow="fullscreen"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100% !important;
              height: 100% !important;
              border: none;
              border-radius: 8px;
              box-sizing: border-box;
            "
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-tour {
  $self: &;

  align-items: center;
  background: rgba($neutral-900, 0.8);
  display: flex;
  justify-content: center;
  height: 100vh;
  position: relative;

  &__skip {
    position: absolute;
    top: $space-xlarge;
    right: $space-xlarge;
  }

  &__close {
    color: $color-text-inverted;

    &:hover,
    &:focus {
      color: $color-text-secondary;
    }
  }

  &__tour {
    border-radius: $space-xxsmall;
    max-width: 80%;
    width: 100%;
  }
}
</style>
