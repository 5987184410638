<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const layoutIconsVariants = {
  size: {
    small: 'layout-icons--small',
  },
};

const classes = cva({ variants: layoutIconsVariants });

export type LayoutIconsProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

const props = withDefaults(
  defineProps<{
    wrapper?: string;
    innerWrappers?: string;
    size?: LayoutIconsProps['size'];
    iconSize?: number;
  }>(),
  {
    wrapper: 'span',
    innerWrappers: 'span',
    iconSize: 24,
  }
);

const slots = useSlots();

const hasDefault = computed(() => !!slots.default);
const hasLeftIcon = computed(() => !!slots.leftIcon);
const hasRightIcon = computed(() => !!slots.rightIcon);

const computedStyles = computed(() => {
  return props.iconSize ? `font-size: ${props.iconSize}px` : undefined;
});
</script>

<template>
  <component :is="wrapper" class="layout-icons" :class="classes({ size })">
    <component
      :is="innerWrappers"
      v-if="hasLeftIcon"
      class="layout-icons__icon layout-icons__icon--left"
      :style="computedStyles"
      ><slot name="leftIcon" /></component
    ><component :is="innerWrappers" v-if="hasDefault" class="layout-icons__text"
      ><slot /></component
    ><component
      :is="innerWrappers"
      v-if="hasRightIcon"
      class="layout-icons__icon layout-icons__icon--right"
      :style="computedStyles"
      ><slot name="rightIcon"
    /></component>
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.layout-icons {
  $self: &;

  display: inline-flex;
  align-items: center;
  position: relative;
  line-height: inherit;
  max-width: 100%;
  vertical-align: middle;

  &__icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    :deep(.loader) {
      top: 2px;
    }

    &--left + #{$self}__text:not(:empty) {
      margin-left: $space-xxsmall;
    }

    &--right {
      margin-left: $space-xxsmall;
    }

    &:empty {
      display: none;
    }
  }

  &__text {
    max-width: 100%;
    overflow: hidden;

    &:empty {
      display: none;
    }
  }

  &--small {
    #{$self}__icon {
      &--left + #{$self}__text:not(:empty) {
        margin-left: $space-xxxsmall + $space-xxxxsmall;
      }

      &--right {
        margin-left: $space-xxxsmall + $space-xxxxsmall;
      }
    }
  }
}
</style>
