<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const appConnectionVariants = {
  direction: {
    row: 'app-connection--row',
    column: 'app-connection--column',
  },
};

const classes = cva({ variants: appConnectionVariants });

export type AppConnectionProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { ref, computed, watch, type ComputedRef } from 'vue';
import AppPageDrawer from '../../components/AppPageDrawer/AppPageDrawer.vue';

import BaseConnectionLine from '../../components/BaseConnectionLine/BaseConnectionLine.vue';
import BaseButton from '../../components/BaseButton/BaseButton.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';

import DashboardInstallationInterceptor from '../../components/DashboardInstallationInterceptor/DashboardInstallationInterceptor.vue';
import DashboardInstallationProxy from '../../components/DashboardInstallationProxy/DashboardInstallationProxy.vue';
import DashboardConnectionProxies from '../../components/DashboardConnectionProxies/DashboardConnectionProxies.vue';
import DashboardConnectionInterceptors from '../../components/DashboardConnectionInterceptors/DashboardConnectionInterceptors.vue';
import DashboardConnectionApiProviders, {
  type ApiProvidersMetric,
} from '../../components/DashboardConnectionApiProviders/DashboardConnectionApiProviders.vue';

import { useDataStore } from '../../stores/data';

const dataStore = useDataStore();

const props = withDefaults(
  defineProps<{
    skeleton?: boolean;
    compact?: boolean;
    direction?: AppConnectionProps['direction'];
  }>(),
  {
    direction: 'column',
  }
);

const showInterceptorsInstallation = ref(false);
const showProxiesInstallation = ref(false);

type BaseConnectionLineProps = InstanceType<
  typeof BaseConnectionLine
>['$props'];

const isInterceptorOnboarding = computed(() => {
  return (
    dataStore.isConnected &&
    dataStore.isProxyInstalled &&
    !dataStore.isInterceptorInstalled
  );
});

const isProxiesOnboarding = computed(() => {
  return dataStore.isConnected && !dataStore.isProxyInstalled;
});

const connectionInterceptorsProps = computed<
  InstanceType<typeof DashboardConnectionInterceptors>['$props']
>(() => {
  // Force skeleton state.
  if (props.skeleton) {
    return {
      skeleton: true,
      compact: props.compact,
    };
  }

  const placeholder =
    !dataStore.isConnected ||
    (dataStore.isConnected && !dataStore.isProxyInstalled);

  const onboarding =
    dataStore.isProxyInstalled && !dataStore.isInterceptorInstalled;

  return {
    interceptors: dataStore.interceptors,
    placeholder,
    onboarding,
    compact: props.compact,
  };
});

const proxiesToInterceptorsConnectorProps: ComputedRef<BaseConnectionLineProps> =
  computed(() => {
    let variant: BaseConnectionLineProps['variant'] = 'neutral';

    // Force netural state.
    if (props.skeleton) {
      return {
        variant,
      };
    }

    if (connectionInterceptorsProps.value.onboarding) {
      variant = 'success';
    } else if (
      !connectionInterceptorsProps.value.onboarding &&
      connectionInterceptorsProps.value.interceptors?.instances.length
    ) {
      variant = 'success';
    }

    return {
      variant,
      direction: props.direction,
    };
  });

const connectionProxiesProps = computed<
  InstanceType<typeof DashboardConnectionProxies>['$props']
>(() => {
  // Force skeleton state.
  if (props.skeleton) {
    return {
      skeleton: true,
      compact: props.compact,
    };
  }

  const placeholder = !dataStore.isConnected;

  const onboarding = dataStore.isConnected && !dataStore.isProxyInstalled;

  return {
    proxies: dataStore.proxies,
    placeholder,
    onboarding,
    compact: props.compact,
  };
});

const proxiesToMetricsConnectorProps: ComputedRef<BaseConnectionLineProps> =
  computed(() => {
    let variant: BaseConnectionLineProps['variant'] = 'neutral';

    // Force netural state.
    if (props.skeleton) {
      return {
        variant,
      };
    }

    if (dataStore.isProxyInstalled && dataStore.isInterceptorInstalled) {
      variant = 'success';
    }

    return {
      variant,
      direction: props.direction,
    };
  });

const connectionApiProvidersProps = computed<
  InstanceType<typeof DashboardConnectionProxies>['$props']
>(() => {
  // Force skeleton state.
  if (props.skeleton) {
    return {
      skeleton: true,
      compact: props.compact,
    };
  }

  const placeholder =
    !dataStore.isConnected ||
    !dataStore.isProxyInstalled ||
    !dataStore.isInterceptorInstalled;

  const metrics: ApiProvidersMetric[] = [
    {
      label: 'Overall',
      counter: dataStore.traffic.providers.count,
    },
    {
      label: 'Endpoints',
      counter: dataStore.traffic.providers.endpointCount,
    },
  ];

  return {
    metrics,
    placeholder,
    compact: props.compact,
  };
});

const toggleInterceptorsInstallation = () => {
  showInterceptorsInstallation.value = !showInterceptorsInstallation.value;
};

const toggleProxiesInstallation = () => {
  showProxiesInstallation.value = !showProxiesInstallation.value;
};

/**
 * Automatically close proxies installation page drawer
 * when proxy is installed.
 */
watch(
  () => dataStore.isProxyInstalled,
  () => {
    if (dataStore.isProxyInstalled && !dataStore.isDemoMode) {
      setTimeout(() => {
        showProxiesInstallation.value = false;
      }, 3000);
    }
  }
);

/**
 * Automatically close interceptors installation page drawer
 * when interceptors are installed.
 */
watch(
  () => dataStore.isInterceptorInstalled,
  () => {
    if (dataStore.isInterceptorInstalled && !dataStore.isDemoMode) {
      setTimeout(() => {
        showInterceptorsInstallation.value = false;
      }, 3000);
    }
  }
);
</script>

<template>
  <div class="app-connection" :class="classes({ direction })">
    <div class="app-connection__flow">
      <DashboardConnectionProxies
        v-bind="connectionProxiesProps"
        class="app-connection__item"
        @install="toggleProxiesInstallation"
      >
        <template #addAction>
          <BaseButton
            v-if="dataStore.isProxyInstalled"
            class="app-connection__add-action"
            data-action="toggle-page-drawer"
            variant="secondary"
            size="small"
            @click="toggleProxiesInstallation"
            ><template #leftIcon><BaseIcon name="plus" /></template
          ></BaseButton>
        </template>
      </DashboardConnectionProxies>

      <div class="app-connection__divider">
        <BaseConnectionLine v-bind="proxiesToInterceptorsConnectorProps" />
      </div>

      <DashboardConnectionInterceptors
        v-bind="connectionInterceptorsProps"
        class="app-connection__item"
        @install="toggleInterceptorsInstallation"
      >
        <template #addAction>
          <BaseButton
            v-if="dataStore.isInterceptorInstalled"
            class="app-connection__add-action"
            data-action="toggle-page-drawer"
            variant="secondary"
            size="small"
            @click="toggleInterceptorsInstallation"
            ><template #leftIcon><BaseIcon name="plus" /></template
          ></BaseButton>
        </template>
      </DashboardConnectionInterceptors>

      <div class="app-connection__divider">
        <BaseConnectionLine v-bind="proxiesToMetricsConnectorProps" />
      </div>

      <DashboardConnectionApiProviders v-bind="connectionApiProvidersProps" />
    </div>
  </div>

  <Teleport to="body">
    <AppPageDrawer v-model="showInterceptorsInstallation">
      <DashboardInstallationInterceptor
        :installed="dataStore.isInterceptorInstalled"
        :onboarding="isInterceptorOnboarding"
      />
    </AppPageDrawer>

    <AppPageDrawer v-model="showProxiesInstallation">
      <DashboardInstallationProxy
        :installed="dataStore.isProxyInstalled"
        :onboarding="isProxiesOnboarding"
      />
    </AppPageDrawer>
  </Teleport>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-connection {
  $self: &;

  flex-shrink: 0;
  width: 100%;

  &__flow {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &--row {
    #{$self} {
      &__flow {
        flex-direction: row;
      }

      &__divider {
        align-items: center;
        display: flex;
      }
    }
  }
}
</style>
