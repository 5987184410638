<script lang="ts">
import md5 from 'md5';
import { take, words } from 'lodash-es';
import { cva, type VariantProps } from 'cva';

export const avatarVariants = {
  size: {
    xsmall: 'avatar--xsmall',
    small: 'avatar--small',
    medium: 'avatar--medium',
    large: 'avatar--large',
    xlarge: 'avatar--xlarge',
  },
};

const classes = cva({ variants: avatarVariants });

export type AvatarProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    size?: AvatarProps['size'];
    email?: string;
    name: string;
    url?: string;
    initials?: number;
  }>(),
  {
    size: 'small',
    email: undefined,
    url: undefined,
    initials: 2,
  }
);

const error = ref(false);

const initials = computed(() => {
  if (props.url) {
    return undefined;
  }
  const nameWords = words(props.name);

  if (!nameWords.length) {
    return props.name;
  }

  const limitedWords = take(nameWords, props.initials);
  const initials = limitedWords
    .map((word: string) => word[0].toUpperCase())
    .join('');

  return initials;
});

const src = computed(() => {
  if (error.value) {
    return undefined;
  }

  if (props.url) {
    return props.url;
  }

  if (props.email) {
    const size = {
      xsmall: 48,
      small: 64,
      medium: 96,
      large: 128,
      xlarge: 192,
      xxlarge: 288,
    };

    return getGravatar({ size: size[props.size], email: props.email });
  }

  return undefined;
});

function getGravatar(options: {
  email: string;
  size?: number;
  default?:
    | '404'
    | 'mp'
    | 'identicon'
    | 'monsterid'
    | 'wavatar'
    | 'retro'
    | 'robohash'
    | 'blank';
}): string {
  /**
   * @see https://en.gravatar.com/site/implement/hash/
   */
  const hash = md5(options.email.trim().toLowerCase());
  const url = new URL(hash, 'https://www.gravatar.com/avatar/');
  url.searchParams.set('s', `${options.size || 128}`);
  url.searchParams.set('default', options.default || '404');
  return url.toString();
}

function onImgError() {
  error.value = true;
}
</script>

<template>
  <span class="avatar" :class="classes({ size })">
    <img
      v-if="src"
      :src="src"
      :alt="`${name}'s avatar image`"
      class="avatar__img"
      @error="onImgError"
    />
    <span
      v-else
      class="avatar__initials"
      role="img"
      :aria-label="name"
      v-text="initials"
    />
  </span>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.avatar {
  background-color: $color-bg-surface;
  border-radius: 50%;
  color: $color-text-tertiary;
  display: inline-block;
  flex-shrink: 0;
  text-align: center;
  text-transform: uppercase;

  &__img {
    display: block;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  /**
   * Size modifiers.
   */
  &--xsmall {
    height: $scale-xsmall;
    font-size: $font-size-xxsmall;
    line-height: $scale-xsmall;
    width: $scale-xsmall;
  }
  &--small {
    height: $scale-small;
    font-size: $font-size-xxsmall;
    line-height: $scale-small;
    width: $scale-small;
  }

  &--medium {
    height: $scale-medium;
    font-size: $font-size-small;
    line-height: $scale-medium;
    width: $scale-medium;
  }

  &--large {
    height: $scale-large;
    font-size: $font-size-medium;
    line-height: $scale-large;
    width: $scale-large;
  }

  &--xlarge {
    height: $scale-xlarge;
    font-size: $font-size-large;
    line-height: $scale-xlarge;
    width: $scale-xlarge;
  }
}
</style>
