<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useStorage } from '@vueuse/core';
import { isEmpty } from 'lodash-es';

import type { PlaygroundVisitor } from './stores/demo/types';

import AppLayoutSidebar from './components/AppLayoutSidebar/AppLayoutSidebar.vue';
import AppPlaygroundAccess from './components/AppPlaygroundAccess/AppPlaygroundAccess.vue';
import AppBanner from './components/AppBanner/AppBanner.vue';
import AppHeader from './components/AppHeader/AppHeader.vue';
import AppTour from './components/AppTour/AppTour.vue';
import AppToast from './components/AppToast/AppToast.vue';

import ColorfulSprite from './colorful-sprite.svg?raw';
import DuotoneSprite from './duotone-sprite.svg?raw';
import Sprite from './sprite.svg?raw';

import { useRedirectToOnboarding } from './use/redirectToOnboarding';

import { useUserStore } from './stores/user';

import { getAsset } from './utils';
import { useDataStore } from './stores/data';
import { FeatureFlag } from './types';
import AppSidebar from './components/AppSidebar/AppSidebar.vue';

const loadingOnboarding = ref(true);

const userStore = useUserStore();
const dataStore = useDataStore();

const hasTouredPlayground = useStorage('has-toured-playground', 'false');
const playgroundVisitor = useStorage<PlaygroundVisitor>(
  'playground-visitor',
  {}
);

const showSkeleton = computed(
  () =>
    !userStore.isUserInitialized ||
    loadingOnboarding.value ||
    (dataStore.isDemoMode && dataStore.isLoading)
);

const showPlaygroundTour = computed(() => {
  if (!userStore.permissions.isPlayground) return;

  // Disable the tour for now.
  return false; // hasTouredPlayground.value !== 'true';
});

const showPlaygroundAccessForm = computed(() => {
  return userStore.permissions.isPlayground && isEmpty(playgroundVisitor.value);
});

const user = computed(
  () =>
    userStore.user || {
      name: 'User',
      email: 'user@lunar.dev',
    }
);

const onTourClose = () => {
  hasTouredPlayground.value = 'true';
};

const setPlaygroundVisitor = (data: PlaygroundVisitor) => {
  playgroundVisitor.value = data;
};

onMounted(async () => {
  const query = new URLSearchParams(window.location.search);
  if (query.get('toured') !== null) {
    hasTouredPlayground.value = query.get('toured');
  }

  await useRedirectToOnboarding(() => {
    loadingOnboarding.value = false;
  });
});

watch(
  () => userStore.isUserInitialized,
  () => {
    if (userStore.isUserInitialized && userStore.token) {
      dataStore.connect();
    }
  },
  { immediate: true }
);
</script>

<template>
  <template v-if="userStore.hasFeatureFlag(FeatureFlag.AltNavigation)">
    <AppPlaygroundAccess
      v-if="showPlaygroundAccessForm"
      @submit="setPlaygroundVisitor"
    />
    <div
      v-else-if="userStore.isUserInitialized && !loadingOnboarding"
      class="app app--alt"
    >
      <AppLayoutSidebar
        id="app-sidebar"
        size="small"
        collapsible
        fixed
        mode="dark"
      >
        <template #sidebar="{ collapsed }">
          <div class="app__sidebar">
            <AppSidebar
              :user="user"
              :org="userStore.org"
              :permissions="userStore.permissions"
              :playground-visitor="playgroundVisitor"
              :disabled="showSkeleton"
              :collapsed="collapsed"
              @logout="userStore.logout()"
            />
          </div>
        </template>

        <div class="app__body">
          <AppBanner
            v-if="userStore.permissions.isPlayground"
            class="app__banner"
          />
          <RouterView :skeleton="showSkeleton" />
        </div>
      </AppLayoutSidebar>
    </div>
  </template>
  <template v-else>
    <AppPlaygroundAccess
      v-if="showPlaygroundAccessForm"
      @submit="setPlaygroundVisitor"
    />
    <div
      v-else-if="userStore.isUserInitialized && !loadingOnboarding"
      class="app"
    >
      <AppBanner v-if="userStore.permissions.isPlayground" />
      <AppHeader
        :user="user"
        :org="userStore.org"
        :permissions="userStore.permissions"
        :playground-visitor="playgroundVisitor"
        :disabled="showSkeleton"
        @logout="userStore.logout()"
      />
      <div class="app__body">
        <RouterView :skeleton="showSkeleton" />
      </div>
    </div>
    <div v-else class="app__loading">
      <video
        autoplay
        loop
        muted
        class="app__video"
        :src="getAsset('/videos/lunar-x.mp4')"
      />
    </div>

    <AppTour v-if="showPlaygroundTour" class="app__tour" @close="onTourClose" />
  </template>

  <div v-html="ColorfulSprite" class="app__sprite" />
  <div v-html="DuotoneSprite" class="app__sprite" />
  <div v-html="Sprite" class="app__sprite" />
  <AppToast />
</template>

<style scoped lang="scss">
@use './assets/styles/utils' as *;

.app {
  $self: &;

  height: 100%;
  overflow: auto;

  &__body {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - $app-header-height);
    padding: $space-large $space-xlarge;
  }

  &__tour {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: layer('modal');
  }

  &__loading {
    align-items: center;
    color: $color-text-brand;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &__video {
    height: 370px;
    width: 370px;
  }

  &__sprite {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &--alt {
    background-color: $navy-950;
    overflow-y: auto;
    overflow-x: hidden;

    #{$self} {
      &__banner {
        border-radius: 0 0 $space-xxsmall $space-xxsmall;
        margin-top: -$space-large;
        margin-bottom: $space-large;
      }

      &__body {
        min-height: 100vh;
        padding: $space-large $space-large $space-large $space-xxxsmall;
      }
    }
  }
}
</style>
