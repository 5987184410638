import retry from '../plugin/retry';
import responseBasedThrottling from '../plugin/response-based-throttling';
import { type PolicyContent, RemedyType } from '../../../types/content';

const content: PolicyContent = {
  type: RemedyType.Policy,
  name: 'Retry and Response Optimization',
  comingSoon: false,
  summary:
    'Handles intermittent API failures with retries and adjusts request rates based on server responses for optimal performance.',
  plugins: [retry, responseBasedThrottling],
  docsUrl: undefined,
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: "example.com"
    method: "GET"
    remedies:
      - name: "Retry"
        enabled: true
        config:
          retry:
            attempts: 3
            initial_cooldown_seconds: 1
            cooldown_multiplier: 2
            conditions:
              status_code:
                from: 500
                to: 599
      - name: "ResponseBasedThrottling"
        enabled: true
        config:
          response_based_throttling:
            retry_after_header: "Retry-After"
            retry_after_type: "relative_seconds"
            quota_group: "quota_group_1"
            relevant_statuses:
              - 429
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
