<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const duotoneIconVariants = {
  name: {
    'account-orchestration': 'AccountOrchestration',
    'auth-mechanism': 'AuthMechanism',
    'har-log-collector': 'HarLogCollector',
    'retry-policy': 'RetryPolicy',
    'smart-caching': 'SmartCaching',
    'strategy-based-queue': 'StrategyBasedQueue',
    throttling: 'Throttling',
  },
};

const classes = cva({ variants: duotoneIconVariants });

export type DuotoneIconProps = VariantProps<typeof classes> & { name: string };
</script>

<script setup lang="ts">
import { computed } from 'vue';

/**
 * Important note:
 * BaseDuotoneIcon uses a sprite that lives in ../../public/duotone-sprite.svg and is generated from the icons
 * in ./icons using https://svgsprit.es. The sprite is embed in ../../index.html.
 */

const props = defineProps<{
  /**
   * The name of the icon.
   */
  name: DuotoneIconProps['name'];
  /**
   * An aria-label is automatically applied to the icon based on the icon name. You can override this using the label prop.
   */
  label?: string;
}>();

const computedAriaLabel = computed(() => {
  const name = props.name
    .split('-')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return props.label ? props.label : `${name} icon`;
});
</script>

<template>
  <span class="duotone-icon">
    <svg
      class="duotone-icon__img"
      role="img"
      :aria-label="computedAriaLabel"
      height="1em"
      width="1em"
    >
      <use
        :xlink:href="`#${duotoneIconVariants.name[props.name]}`"
        :href="`#${duotoneIconVariants.name[props.name]}`"
      />
    </svg>
  </span>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.duotone-icon {
  align-items: center;
  color: inherit;
  display: inline-flex;
  height: 1em;
  fill: currentColor;
  justify-content: center;
  line-height: inherit;
  width: 1em;

  &::before {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    text-rendering: auto;
  }
}
</style>
