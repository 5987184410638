<script setup lang="ts">
import AppFeedbackForm from '../AppFeedbackForm/AppFeedbackForm.vue';

import BaseLayoutIcons from '../BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';
import BaseActions from '../BaseActions/BaseActions.vue';

import { DiscordChannel } from '@monorepo/shared-model/src/webserver-payloads';
import { computed } from 'vue';

const props = defineProps<{
  collapsed?: boolean;
  skeleton?: boolean;
}>();

const openFeedbackForm = defineModel<boolean>('openFeedbackForm', {
  default: false,
});

const computedClasses = computed(() => ({
  'app-community--collapsed': props.collapsed,
}));

const onSubmitted = () => {
  openFeedbackForm.value = false;
};

const toggleFeedbackForm = () => {
  if (props.skeleton) return;

  openFeedbackForm.value = !openFeedbackForm.value;
};
</script>

<template>
  <nav class="app-community" :class="computedClasses">
    <ul class="app-community__items">
      <li class="app-community__item">
        <BaseActions
          class="app-community__suggestion-actions"
          placement="right"
          v-model="openFeedbackForm"
          title="Feature request"
          interactive
        >
          <template #content>
            <div class="app-community__suggestion-form">
              <AppFeedbackForm
                submit-label="Submit suggestion"
                submitting-label="Suggesting…"
                textarea-placeholder="Describe your idea or feature request in as much detail as you can…"
                :channel="DiscordChannel.InternalFeatureRequest"
                @submitted="onSubmitted"
              />
            </div>
          </template>
          <BaseTooltip
            class="app-community__tooltip"
            content="Make a feature request"
            placement="right"
            :disabled="!collapsed || openFeedbackForm"
            @click.stop
          >
            <div
              class="app-community__container"
              tabindex="0"
              @click="toggleFeedbackForm"
              @keydown.enter="toggleFeedbackForm"
            >
              <BaseLayoutIcons :icon-size="16">
                <template #leftIcon>
                  <BaseIcon name="bulb" />
                </template>
                <span v-if="!collapsed">Feature request</span>
              </BaseLayoutIcons>
            </div>
          </BaseTooltip>
        </BaseActions>
      </li>
      <li class="app-community__item">
        <BaseTooltip
          class="app-community__tooltip"
          content="Join our Discord"
          placement="right"
          :disabled="!collapsed"
        >
          <a
            class="app-community__container"
            href="https://discord.gg/Kgqu4XQprN"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <BaseLayoutIcons :icon-size="16">
              <template #leftIcon>
                <BaseIcon name="discord" />
              </template>
              <span v-if="!collapsed">Join our Discord</span>
            </BaseLayoutIcons>
          </a>
        </BaseTooltip>
      </li>
      <li class="app-community__item">
        <BaseTooltip
          class="app-community__tooltip"
          content="Documentation"
          placement="right"
          :disabled="!collapsed"
        >
          <a
            class="app-community__container"
            href="https://docs.lunar.dev/"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <BaseLayoutIcons :icon-size="16">
              <template #leftIcon>
                <BaseIcon name="book-open-text" />
              </template>
              <span v-if="!collapsed">Documentation</span>
            </BaseLayoutIcons>
          </a>
        </BaseTooltip>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-community {
  $self: &;

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline-flex;
    width: 100%;
  }

  &__tooltip {
    width: 100%;
  }

  &__container {
    align-items: center;
    border-radius: $space-xxxsmall;
    color: $color-text-inverted;
    cursor: pointer;
    display: flex;
    height: 37px;
    text-decoration: none;
    padding: $space-xxsmall $space-xsmall;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $navy-900;
      color: $color-text-inverted;
      outline: none;
    }
  }

  &__suggestion-actions {
    width: 100%;
  }

  &__suggestion-form {
    min-width: 360px;
  }
}
</style>
