<script setup lang="ts">
import { computed, ref } from 'vue';

import BaseDropdown from '../BaseDropdown/BaseDropdown.vue';
import BaseDivider from '../BaseDivider/BaseDivider.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseMenu from '../BaseMenu/BaseMenu.vue';
import BaseMenuItem from '../BaseMenuItem/BaseMenuItem.vue';
import BaseCalendar from '../BaseCalendar/BaseCalendar.vue';
import type { DateRange } from '@monorepo/shared-model/src/webserver-events';
import { format, endOfYesterday } from 'date-fns';

defineProps<{
  disabled?: boolean;
}>();

const selection = defineModel<DateRange>({
  default: {
    dateRangeType: 'relative',
    length: 1,
    unit: 'day',
  },
});

const dropdown = ref<InstanceType<typeof BaseDropdown>>();
const dateRange = ref<string[]>();
const disableClickOutside = ref(false);
const relativeOptions = ref<DateRange[]>([
  {
    dateRangeType: 'relative',
    length: 5,
    unit: 'minute',
  },
  {
    dateRangeType: 'relative',
    length: 30,
    unit: 'minute',
  },
  {
    dateRangeType: 'relative',
    length: 1,
    unit: 'hour',
  },
  {
    dateRangeType: 'relative',
    length: 1,
    unit: 'day',
  },
  {
    dateRangeType: 'relative',
    length: 1,
    unit: 'week',
  },
  {
    dateRangeType: 'relative',
    length: 1,
    unit: 'month',
  },
]);

const selectedLabel = computed(() => {
  return getSelectionLabel(selection.value);
});

function onDateRange() {
  const startDate =
    dateRange.value && dateRange.value[0]
      ? new Date(dateRange.value[0])
      : undefined;
  const endDate =
    dateRange.value && dateRange.value[1]
      ? new Date(dateRange.value[1])
      : dateRange.value && dateRange.value[0]
        ? new Date(dateRange.value[0])
        : undefined;

  if (!startDate || !endDate) return;

  selection.value = {
    dateRangeType: 'absolute',
    startDate,
    endDate,
  };
}

function onChange(dateRange: DateRange) {
  selection.value = dateRange;
  dropdown.value?.close();
}

function getSelectionLabel(dateRange: DateRange) {
  if (dateRange.dateRangeType === 'relative') {
    switch (dateRange.unit) {
      case 'minute':
        return dateRange.length === 1
          ? 'Last minute'
          : `Last ${dateRange.length} minutes`;
      case 'hour':
        return dateRange.length === 1
          ? 'Last hour'
          : `Last ${dateRange.length} hours`;
      case 'day':
        return dateRange.length === 1
          ? 'Last 24 hours'
          : `Last ${dateRange.length} days`;
      case 'week':
        return dateRange.length === 1
          ? 'Last week'
          : `Last ${dateRange.length} weeks`;
      case 'month':
        return dateRange.length === 1
          ? 'Last month'
          : `Last ${dateRange.length} months`;
    }
  }

  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;

  return startDate.toISOString() === endDate.toISOString()
    ? format(startDate, 'dd/MM/yyyy')
    : `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`;
}

function setDisableClickOutside(boolean: boolean) {
  disableClickOutside.value = boolean;
}
</script>

<template>
  <BaseDropdown
    ref="dropdown"
    class="dashboard-overview-filter-date-range-dropdown"
    placement="bottom-end"
    :disable-click-outside="disableClickOutside"
    :disabled="disabled"
  >
    <template #trigger>
      <BaseButton variant="select" :disabled="disabled"
        >{{ selectedLabel
        }}<template #rightIcon><BaseIcon name="chevron-down" /></template
      ></BaseButton>
    </template>

    <BaseMenu class="dashboard-overview-filter-date-range-dropdown__menu">
      <BaseMenuItem
        v-for="(option, index) in relativeOptions"
        :key="index"
        @click="onChange(option)"
        >{{ getSelectionLabel(option) }}</BaseMenuItem
      >

      <BaseDivider wrapper="li" size="xsmall" />

      <BaseMenuItem>
        <BaseCalendar
          placeholder="Select a day or date range…"
          v-model="dateRange"
          selection-mode="range"
          :manual-input="false"
          :max-date="endOfYesterday()"
          @update:model-value="onDateRange"
          @show="setDisableClickOutside(true)"
          @hide="setDisableClickOutside(false)"
        />
      </BaseMenuItem>
    </BaseMenu>
  </BaseDropdown>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-overview-filter-date-range-dropdown {
  $self: &;

  &__menu {
    min-width: 256px;
  }
}
</style>
