import { computed } from 'vue';
import { defineStore } from 'pinia';

import { useDemoStore } from '../demo';
import { useSocketStore, type MappedPathPartsRequest } from '../socket';
import { useUserStore } from '../user';
import type { PrimitiveProvider } from '../demo/types';
import type { RemedyContent } from '../../types/content';
import type { MappedPathPart } from '@prisma/client';
import type { GetAggregatedDiscoveryPayload } from '@monorepo/shared-model/src/webserver-events';

export const useDataStore = defineStore('data', () => {
  const userStore = useUserStore();
  const demoStore = useDemoStore();
  const socketStore = useSocketStore();

  const isDemoMode = computed(() => demoStore.isDemoMode);

  const isLoading = computed(() => {
    // TODO: Implement loading state on socketStore.
    return demoStore.isDemoMode ? demoStore.isLoading : false;
  });

  const isConnected = computed(() => socketStore.isConnected);
  const isProxyInstalled = computed(() => socketStore.isProxyInstalled);
  const isInterceptorInstalled = computed(
    () => socketStore.isInterceptorInstalled
  );
  const error = computed(() => socketStore.error);
  const consumers = computed(() => socketStore.consumers);
  const proxies = computed(() => socketStore.proxies);
  const interceptors = computed(() => socketStore.interceptors);
  const traffic = computed(() => socketStore.traffic);
  const urlTree = computed(() => socketStore.urlTree);
  const filters = computed(() => socketStore.filters);

  const setDemoMode = (value: boolean) => {
    demoStore.forceDemoMode = value;
  };

  const connect = () => {
    if (isDemoMode.value) {
      demoStore.connect();
    } else {
      if (!userStore.token?.id_token) {
        console.error('No user token found.');
        return;
      }

      socketStore.connect(userStore.token.id_token);
    }
  };

  const getAggregatedDiscovery = (
    payload: GetAggregatedDiscoveryPayload | undefined = undefined
  ) => {
    return socketStore.getAggregatedDiscovery(payload);
  };

  async function getUrlTree() {
    if (isDemoMode.value) {
      return await demoStore.getUrlTree();
    }

    return await socketStore.getUrlTree();
  }

  async function getUrlTreeProvider(host: string) {
    if (isDemoMode.value) {
      return await demoStore.getUrlTreeProvider(host);
    }

    return await socketStore.getUrlTreeProvider(host);
  }

  async function createOrUpdateMappedPathParts(
    payload: MappedPathPartsRequest
  ): Promise<MappedPathPart[] | undefined> {
    if (isDemoMode.value) {
      return await demoStore.createOrUpdateMappedPathParts(payload);
    }

    return await socketStore.createOrUpdateMappedPathParts(payload);
  }

  const applyRemediation = (
    provider: PrimitiveProvider,
    remedy: RemedyContent
  ) => {
    if (isDemoMode.value) {
      return demoStore.applyRemediation(provider, remedy);
    }

    return;
  };

  const removeRemediation = (
    provider: PrimitiveProvider,
    remedy: RemedyContent
  ) => {
    if (isDemoMode.value) {
      return demoStore.removeRemediation(provider, remedy);
    }

    return;
  };

  return {
    isDemoMode,
    isLoading,
    isConnected,
    isProxyInstalled,
    isInterceptorInstalled,
    error,
    consumers,
    proxies,
    interceptors,
    traffic,
    urlTree,
    filters,
    connect,
    getAggregatedDiscovery,
    setDemoMode,
    getUrlTree,
    getUrlTreeProvider,
    createOrUpdateMappedPathParts,
    applyRemediation,
    removeRemediation,
  };
});
