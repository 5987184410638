<script setup lang="ts">
import { computed } from 'vue';

import type {
  PrimitiveAggregatedDiscovery,
  ProxyInstance,
} from '@monorepo/shared-model/src/aggregated-discovery.ts';
import type { ToRaw } from '@monorepo/toolkit-core/types';

import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseAnchor from '../BaseAnchor/BaseAnchor.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';

import DashboardConnection from '../DashboardConnection/DashboardConnection.vue';
import DashboardConnectionItem from '../DashboardConnectionItem/DashboardConnectionItem.vue';
import DashboardConnectionItemSkeleton from '../DashboardConnectionItemSkeleton/DashboardConnectionItemSkeleton.vue';

type PrimativeProxyInstance = ToRaw<ProxyInstance>;

const props = defineProps<{
  placeholder?: boolean;
  onboarding?: boolean;
  skeleton?: boolean;
  proxies?: PrimitiveAggregatedDiscovery['proxies'];
  compact?: boolean;
}>();

const emit = defineEmits<{
  install: [];
}>();

/**
 * TODO: At the moment proxies don't have a persistent state, so each time they restart a new instance of the
 * same proxy is created. This is a temporary solution to only show the latest instance of each proxy. We will
 * be refactoring this when we work on the "Enviornment" feature.
 */
const concatenatedInstances = computed(() => {
  const instances = props.proxies?.instances.reduce(
    (acc, instance) => {
      const now = new Date().toISOString();

      if (
        !acc[instance.name] ||
        (acc[instance.name].lastTransactionDate || now) <=
          (instance.lastTransactionDate || now)
      ) {
        acc[instance.name] = instance;
      }

      return acc;
    },
    {} as Record<string, PrimativeProxyInstance>
  );

  return instances;
});

const proxyCounterLabel = computed(() => {
  const proxyCounter =
    concatenatedInstances.value &&
    Object.keys(concatenatedInstances.value).length;

  if (!proxyCounter || proxyCounter === 1) return;

  return `(${proxyCounter})`;
});

const onInstall = () => {
  emit('install');
};

const isConnected = computed(() => {
  return !!(props.proxies && props.proxies.instances.length);
});
</script>

<template>
  <DashboardConnection
    class="dashboard-connection-proxies"
    icon="server-line-outline"
    :connected="isConnected"
    :placeholder="placeholder"
    :onboarding="onboarding"
    :compact="compact"
  >
    <template #title
      >Gateway<span
        v-if="proxyCounterLabel"
        class="dashboard-connection-interceptors__counter"
        v-text="proxyCounterLabel"
    /></template>
    <template #addAction><slot name="addAction" /></template>

    <template v-if="isConnected">
      <DashboardConnectionItem
        v-for="proxy in concatenatedInstances"
        :key="proxy.name"
      >
        {{ proxy.name }}
        <template #additional>v. {{ proxy.version }}</template>
      </DashboardConnectionItem>
    </template>

    <template v-else-if="onboarding && !skeleton">
      <BaseLayoutGap
        class="dashboard-connection-proxies__onboarding"
        direction="column"
        alignment="center"
      >
        <BaseProse alignment="center"
          >Let's first install the Gateway</BaseProse
        >
        <BaseButton @click="onInstall">Continue</BaseButton>
      </BaseLayoutGap>
    </template>

    <template v-else-if="!compact">
      <DashboardConnectionItemSkeleton
        v-for="n in 1"
        :key="n"
        :placeholder="!skeleton"
      />
    </template>

    <template v-if="isConnected" #footer>
      <BaseLayoutGap alignment="space-between">
        <BaseProse size="small" alignment="right">
          <BaseAnchor
            class="dashboard-connection-proxies__docs-link"
            href="https://docs.lunar.dev/installation-configuration/proxy"
            size="small"
            blank
            ><template #leftIcon><BaseIcon name="book-open-text" /></template
            >Docs</BaseAnchor
          >
        </BaseProse>
      </BaseLayoutGap>
    </template>
  </DashboardConnection>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-connection-proxies {
  &__onboarding {
    margin-bottom: $space-large;
  }

  &__counter {
    color: $color-text-tertiary;
    font-size: $font-size-xsmall;
  }

  &__docs-link {
    margin-left: auto;
  }
}
</style>
