import { defineStore } from 'pinia';
import { ref } from 'vue';
import { io, type Socket } from 'socket.io-client';
import {
  emptyStatusCodeGroups,
  type PrimitiveAggregatedDiscovery,
} from '@monorepo/shared-model/src/aggregated-discovery';
import {
  DefaultDateRange,
  ServerBoundWebSocketEvents,
  type GetAggregatedDiscoveryPayload,
} from '@monorepo/shared-model/src/webserver-events';
import { useToast } from '../../use/toast';
import { useUserStore } from '../user';
import { UserStatus } from '@monorepo/shared-model/src/user';
import {
  PathPartKindDAO,
  type PrimitivePathPartDAO,
  type PrimitiveUrlTreeDAO,
  type PrimitiveUrlTreeProviderDAO,
} from '@monorepo/shared-model/src/url-tree';
import { useFetch } from '../../use/fetch';
import type { MappedPathPart } from '@prisma/client';

export interface MappedPathPartsRequest {
  [id: number]: Pick<PrimitivePathPartDAO, 'kind' | 'value'> & {
    mappingId?: number;
  };
}

export const useSocketStore = defineStore('socket', () => {
  const toast = useToast();
  const userStore = useUserStore();

  let socket: Socket | undefined = undefined;

  const isConnected = ref(false);
  const isProxyInstalled = ref(false);
  const isInterceptorInstalled = ref(false);
  const error = ref<string | undefined>(undefined);
  const proxies = ref<PrimitiveAggregatedDiscovery['proxies']>({
    lastTransactionDate: undefined,
    instances: [],
  });
  const interceptors = ref<PrimitiveAggregatedDiscovery['interceptors']>({
    lastTransactionDate: undefined,
    instances: [],
  });
  const consumers = ref<PrimitiveAggregatedDiscovery['consumers']>([]);

  const traffic = ref<PrimitiveAggregatedDiscovery['traffic']>({
    count: { current: 0 },
    errorRate: { current: 0 },
    runtimeMs: { avg: { current: 0 } },
    statusCodes: emptyStatusCodeGroups(),
    providers: {
      count: 0,
      endpointCount: 0,
      instances: [],
    },
  });
  const filters = ref<GetAggregatedDiscoveryPayload>({
    dateRange: DefaultDateRange,
    filteredConsumerTags: [],
  });
  const urlTree = ref<PrimitiveUrlTreeDAO>();

  function listen() {
    socket?.on('connect', () => {
      isConnected.value = true;
    });

    socket?.on('disconnect', () => {
      isConnected.value = false;
    });

    socket?.on('aggregated-discovery', async (res) => {
      proxies.value = res.data.proxies;
      interceptors.value = res.data.interceptors;
      consumers.value = res.data.consumers;
      traffic.value = res.data.traffic;

      isProxyInstalled.value =
        res.data.proxies.instances.length > 0 ||
        userStore?.extendedUser?.status === UserStatus.Integrated;
      isInterceptorInstalled.value =
        res.data.interceptors.instances.length > 0 ||
        userStore?.extendedUser?.status === UserStatus.Integrated;
    });

    socket?.on('connect_failed', () => {
      toast.add({
        severity: 'error',
        summary: 'Failed to connect to server.',
        life: 3000,
      });
    });

    socket?.on('connect_error', () => {
      toast.add({
        severity: 'error',
        summary: 'An unexpected error occurred.',
        life: 3000,
      });
    });
  }

  function getAggregatedDiscovery(
    payload: GetAggregatedDiscoveryPayload | undefined = undefined
  ) {
    if (payload) {
      filters.value = payload;
    }

    socket?.emit(
      ServerBoundWebSocketEvents.GetAggregatedDiscovery,
      filters.value
    );
  }

  async function getUrlTreeProvider(
    host: string
  ): Promise<PrimitiveUrlTreeProviderDAO | undefined> {
    const { data, error } = await useFetch(`/known-urls?host=${host}`).json();

    return !error.value && data.value.providers[host];
  }

  async function getUrlTree(): Promise<PrimitiveUrlTreeDAO | undefined> {
    const { data, error } = await useFetch(`/known-urls`).json();

    return !error.value && data.value;
  }

  async function createOrUpdateMappedPathParts(
    payload: MappedPathPartsRequest
  ): Promise<MappedPathPart[] | undefined> {
    const data: MappedPathPart[] = [];
    const errors: number[] = [];

    for (const id in payload) {
      const { kind, value, mappingId } = payload[id];

      if (kind === PathPartKindDAO.Assumed) {
        const result = await createMappedPathPart(Number(id), value);

        if (result) {
          data.push(result);
        } else {
          errors.push(Number(id));
        }
      } else if (kind === PathPartKindDAO.Mapped) {
        const result = await updateMappedPathPart(Number(mappingId), value);

        if (result) {
          data.push(result);
        } else {
          errors.push(Number(id));
        }
      } else {
        errors.push(Number(id));
      }
    }

    return errors.length ? undefined : data;
  }

  async function createMappedPathPart(
    id: number,
    value: string
  ): Promise<MappedPathPart | undefined> {
    const { data, error } = await useFetch(`/path-part/${id}/mapped-path-part`)
      .post({
        value,
      })
      .json<MappedPathPart>();

    return (!error.value && data.value) || undefined;
  }

  async function updateMappedPathPart(
    id: number,
    value: string
  ): Promise<MappedPathPart | undefined> {
    const { data, error } = await useFetch(`/mapped-path-part/${id}`)
      .patch({
        value,
      })
      .json<MappedPathPart>();

    return (!error.value && data.value) || undefined;
  }

  function connect(token: string) {
    const url = import.meta.env.VITE_WS_URL;
    const path = import.meta.env.VITE_WS_PATH;

    socket = io(url, {
      path,
      auth: { token },
    });

    socket.connect();

    listen();
  }

  function disconnect() {
    if (socket) {
      socket.disconnect();
    }
  }

  return {
    isConnected,
    isProxyInstalled,
    isInterceptorInstalled,
    error,
    proxies,
    interceptors,
    consumers,
    traffic,
    urlTree,
    filters,
    listen,
    connect,
    disconnect,
    getAggregatedDiscovery,
    getUrlTree,
    getUrlTreeProvider,
    createOrUpdateMappedPathParts,
  };
});
