<script setup lang="ts">
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

const props = defineProps<{
  to: string;
  disabled?: boolean;
  exact?: boolean;
}>();

const computedClasses = computed(() => ({
  'app-navigation-item--disabled': props.disabled,
}));
</script>

<template>
  <li class="app-navigation-item" :class="computedClasses">
    <span v-if="disabled" class="app-navigation-item__link"><slot /></span>
    <RouterLink
      v-else
      :to="to"
      custom
      v-slot="{ href, isExactActive, isActive, navigate }"
    >
      <a
        class="app-navigation-item__link"
        :class="{
          'app-navigation-item__link--active': exact ? isExactActive : isActive,
        }"
        :href="href"
        @click="navigate"
      >
        <slot
      /></a>
    </RouterLink>
  </li>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;
.app-navigation-item {
  $self: &;

  + #{$self} {
    margin-left: $space-xxxsmall;
  }

  &__link {
    color: $color-text-secondary;
    display: inline-flex;
    padding: $space-small;
  }

  &:not(#{$self}--disabled) {
    #{$self} {
      &__link {
        color: $color-text-secondary;
        text-decoration: none;
        position: relative;

        &:hover,
        &--active {
          color: $color-text-brand;

          &::after {
            background-color: $color-border-brand;
            border-radius: 4px 4px 0 0;
            bottom: 0;
            display: block;
            content: '';
            left: 0;
            height: 4px;
            width: 100%;
            position: absolute;
          }
        }
      }
    }
  }

  &--disabled {
    #{$self}__link {
      cursor: default;
    }
  }
}
</style>
