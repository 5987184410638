<script setup lang="ts">
import { computed, ref } from 'vue';
import type { User } from '@auth0/auth0-vue';
import type { UserPermissions } from '../../types';
import type { OrganizationDAO } from '@monorepo/shared-model/src/user';

import BaseAvatar from '../BaseAvatar/BaseAvatar.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseDropdown from '../BaseDropdown/BaseDropdown.vue';
import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseDivider from '../BaseDivider/BaseDivider.vue';
import BaseMenu from '../BaseMenu/BaseMenu.vue';
import BaseMenuItem from '../BaseMenuItem/BaseMenuItem.vue';
import BaseMenuLink from '../BaseMenuLink/BaseMenuLink.vue';
import BaseMenuRouterLink from '../BaseMenuRouterLink/BaseMenuRouterLink.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';

const props = defineProps<{
  user: User;
  org?: OrganizationDAO;
  permissions?: UserPermissions;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  logout: [];
}>();

const dropdown = ref<InstanceType<typeof BaseDropdown>>();

const onLogout = () => {
  emit('logout');
};

const onNavigated = () => {
  dropdown.value?.close();
};

const displayUser = computed(() => {
  const chosenName =
    props.user.user_metadata &&
    (props.user.user_metadata.given_name ||
      props.user.user_metadata.family_name)
      ? `${props.user.user_metadata.given_name} ${props.user.user_metadata.family_name}`.trim()
      : undefined;

  return (
    props.user.email ||
    props.user.nameIdAttributes.value ||
    chosenName ||
    props.user.name
  );
});

const avatarEmail = computed(() => {
  return props.user.email || props.user.nameIdAttributes.value || '';
});

const avatarName = computed(() => {
  const firstName = props.user.user_metadata?.given_name;
  const lastName = props.user.user_metadata?.family_name;
  const name = props.user.name;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (firstName && !lastName) {
    return firstName;
  }

  if (!firstName && !lastName && name) {
    return name;
  }

  return avatarEmail.value || '?';
});

const avatarInitials = computed(() => (avatarName.value.includes('@') ? 1 : 2));

const displayOrgNavigation = computed(() => {
  return props.org ? 'Organization' : 'Invite members';
});
</script>

<template>
  <BaseDropdown
    ref="dropdown"
    class="app-account-dropdown"
    placement="bottom-end"
    :disabled="disabled"
    ><template #trigger
      ><BaseButton
        class="app-account-dropdown__action"
        variant="select"
        size="xlarge"
        :disabled="disabled"
      >
        <span class="app-account-dropdown__avatar-container">
          <BaseAvatar
            class="app-account-dropdown__avatar"
            :email="avatarEmail"
            :name="avatarName"
            :initials="avatarInitials"
            size="medium"
          />
        </span>
        <template #rightIcon
          ><BaseIcon name="chevron-down" /></template></BaseButton
    ></template>
    <BaseMenu class="app-account-dropdown__menu">
      <BaseMenuItem>
        <BaseLayoutGap>
          <BaseAvatar
            :email="avatarEmail"
            :name="avatarName"
            :initials="avatarInitials"
            size="medium"
          />
          <BaseLayoutGap direction="column" size="xxxsmall">
            <BaseProse>{{ displayUser }}</BaseProse>
          </BaseLayoutGap>
        </BaseLayoutGap>
      </BaseMenuItem>
      <BaseDivider wrapper="li" size="xxsmall" />
      <BaseMenuLink href="https://www.lunar.dev/pricing" :icon-size="16"
        >Upgrade your account
        <template #rightIcon><BaseIcon name="expand" /></template
      ></BaseMenuLink>
      <BaseMenuRouterLink
        to="/settings/profile"
        disable-active-state
        @navigated="onNavigated"
        >Profile</BaseMenuRouterLink
      >
      <BaseMenuRouterLink
        to="/settings/account"
        disable-active-state
        @navigated="onNavigated"
        >Account</BaseMenuRouterLink
      >
      <BaseMenuRouterLink
        to="/settings/organization"
        disable-active-state
        @navigated="onNavigated"
        >{{ displayOrgNavigation }}</BaseMenuRouterLink
      >
      <template v-if="permissions?.canAccessDevTools">
        <BaseDivider wrapper="li" size="xsmall" />
        <BaseMenuRouterLink
          to="/settings/dev-tools"
          disable-active-state
          @navigated="onNavigated"
          >Dev tools</BaseMenuRouterLink
        >
      </template>
      <BaseDivider wrapper="li" size="xxsmall" />
      <BaseMenuItem @click="onLogout" @keydown.enter="onLogout"
        >Logout</BaseMenuItem
      >
    </BaseMenu>
  </BaseDropdown>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-account-dropdown {
  $self: &;

  &__action {
    padding-left: $space-xxsmall !important;
    padding-right: $space-xxsmall !important;
  }

  &__avatar-container {
    display: inline-block;
    margin-right: $space-xxsmall;
    width: $scale-xsmall;
  }

  &__avatar {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
