import './assets/styles/index.scss';

import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import router from './router';
import { createPinia } from 'pinia';
import { createAuth0 } from '@auth0/auth0-vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import posthog from 'posthog-js';

import App from './App.vue';

const app = createApp(App);

if (
  !window.location.host.includes('127.0.0.1') &&
  !window.location.host.includes('localhost')
) {
  // Posthog integration as demonstrated in
  // https://posthog.com/docs/libraries/js#option-2-install-via-package-manager
  posthog.init('phc_ul74xqSuxx9ugfFEVdcuXekfpaYXnALoFOhJ9IvgjIs', {
    api_host: 'https://us.posthog.com',
    autocapture: false, // Disable automatic capture of events, handled through GTM.
  });

  // Sentry integration for error tracking
  Sentry.init({
    app,
    dsn: 'https://bbe6744c5cba382b20ec8150a8600238@o4507050721411072.ingest.us.sentry.io/4507050732027904',
    integrations: [Sentry.browserTracingIntegration()],
    environment: import.meta.env.MODE,
  });
}

app.use(createPinia());
app.use(router);
app.use(PrimeVue);
app.use(ToastService);

// We extract the organization and invitation parameters from
// the URL to support the invitation flow
const params = new URL(document.location.toString()).searchParams;
const organization = params.get('organization');
const organizationName = params.get('organization_name');
const invitation = params.get('invitation');
const invitationParams =
  organization && organizationName && invitation
    ? { organization, organization_name: organizationName, invitation }
    : {};
const connection = params.get('connection') || undefined;

app.use(
  createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      connection,
      ...invitationParams,
    },
  })
);

app.mount('#app');
