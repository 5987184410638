import {
  type PrimitiveUrlTreeDAO,
  PathPartKindDAO,
} from '@monorepo/shared-model/src/url-tree';
import type { DemoProvider } from './types';
import {
  InterceptorType,
  type PrimitiveAggregatedDiscovery,
} from '@monorepo/shared-model/src/aggregated-discovery';

const now = new Date().toISOString();

export const mockGateways: PrimitiveAggregatedDiscovery['proxies'] = {
  instances: [
    {
      id: '1',
      name: 'Lunar.dev Demo',
      type: 'lunar-proxy',
      version: '1.18.0',
      lastTransactionDate: new Date().toString(),
    },
  ],
};

export const mockConsumers: PrimitiveAggregatedDiscovery['consumers'] = [
  {
    tag: 'LLM Service',
    lastTransactionDate: new Date().toString(),
  },
  {
    tag: 'Geolocation App',
    lastTransactionDate: new Date().toString(),
  },
  {
    tag: 'Travel System',
    lastTransactionDate: new Date().toString(),
  },
  {
    tag: 'Payment Gateway',
    lastTransactionDate: new Date().toString(),
  },
  {
    tag: 'Other',
    lastTransactionDate: new Date().toString(),
  },
];

export const mockInterceptors: PrimitiveAggregatedDiscovery['interceptors'] = {
  instances: [
    {
      name: 'ts-interceptor',
      type: InterceptorType.LunarTsInterceptor,
      version: '0.1.1',
      lastTransactionDate: new Date().toString(),
    },
    {
      name: 'java-interceptor',
      type: InterceptorType.LunarJavaInterceptor,
      version: '0.1.4',
      lastTransactionDate: new Date().toString(),
    },
    {
      name: 'python-interceptor',
      type: InterceptorType.LunarPyInterceptor,
      version: '0.1.2',
      lastTransactionDate: new Date().toString(),
    },
    {
      name: 'direct-traffic',
      type: InterceptorType.Unknown,
      version: 'N/A',
      lastTransactionDate: new Date().toString(),
    },
  ],
  lastTransactionDate: new Date().toString(),
};

export const mockProvidersGroups: Record<string, DemoProvider[]> = {
  LLM: [
    'api.openai.com',
    'api.anthropic.com',
    'cohere.com',
    'api.mistral.ai',
    'api.llama-api.com',
    'api.elevenlabs.io',
  ],
  Geolocation: [
    'google.com',
    'api.mapbox.com',
    'here.com',
    'api.fourkites.com',
    'api.shipengine.com',
  ],
  Travel: [
    'api.amadeus.com',
    'google.com',
    'api.sabre.com',
    'api.ean.com',
    'demandapi.booking.com',
  ],
  Payment: [
    'api.stripe.com',
    'production.plaid.com',
    'api.paypal.com',
    'finicity.com',
    'api.intuit.com',
  ],
  Other: [
    'api.github.com',
    'httpbin.org',
    'api.sendgrid.com',
    'api.datadoghq.com',
  ],
};

export const mockProviders: Record<DemoProvider, Record<string, string[]>> = {
  'api.github.com': {
    '/orgs/{org_id}/issues': ['GET'],
    '/issues/{issue_number}': ['GET'],
    '/issues/{issue_number}/comments': ['GET'],
    '/repos/{_param_1}/{_param_2}/issues': ['GET'],
    '/repos/{_param_1}/{_param_2}/issues/{_param_3}': ['GET'],
    '/repos/{_param_1}/{_param_2}/pulls': ['GET'],
    '/repos/{_param_1}/{_param_2}/pulls/{_param_3}': ['GET'],
    '/repos/{_param_1}/{_param_2}/pulls/{_param_3}/commits': ['GET'],
    '/repos/{_param_1}/{_param_2}/pulls/{_param_3}/files': ['GET'],
  },
  'httpbin.org': {
    '/get': ['GET'],
    '/post': ['POST'],
    '/put': ['PUT'],
    '/delete': ['DELETE'],
    '/patch': ['PATCH'],
    '/status/{code}': ['GET'],
    '/delay/{delay}': ['GET'],
    '/headers': ['GET'],
    '/redirect/{n}': ['GET'],
    '/relative-redirect/{n}': ['GET'],
    '/absolute-redirect/{n}': ['GET'],
    '/cookies': ['GET'],
    '/cookies/set': ['GET'],
    '/cookies/delete': ['GET'],
    '/basic-auth/{user}/{passwd}': ['GET'],
    '/hidden-basic-auth/{user}/{passwd}': ['GET'],
    '/digest-auth/{qop}/{user}/{passwd}': ['GET'],
    '/stream/{n}': ['GET'],
    '/drip': ['GET'],
    '/range/{numbytes}': ['GET'],
    '/html': ['GET'],
    '/xml': ['GET'],
    '/json': ['GET'],
    '/robots.txt': ['GET'],
    '/deny': ['GET'],
    '/cache': ['GET'],
    '/cache/{n}': ['GET'],
    '/etag/{etag}': ['GET'],
    '/response-headers': ['GET'],
    '/redirect-to': ['GET'],
    '/redirect-to/{url}': ['GET'],
    '/relative-redirect-to': ['GET'],
    '/relative-redirect-to/{url}': ['GET'],
    '/absolute-redirect-to': ['GET'],
    '/absolute-redirect-to/{url}': ['GET'],
  },
  'api.sendgrid.com': {
    '/v3/whitelabel/domains': ['GET'],
    '/v3/mail/send': ['POST'],
    '/v3/messages/{msg_id}': ['GET', 'PATCH', 'DELETE'],
    '/v3/alerts': ['GET'],
    '/v3/alerts/{id}': ['GET', 'PATCH', 'DELETE'],
    '/v3/api_keys': ['GET'],
    '/v3/templates': ['GET', 'POST'],
    '/v3/templates/{id}': ['GET', 'PATCH', 'DELETE'],
    '/v3/templates/versions': ['GET'],
    '/v3/templates/versions/{id}': ['GET', 'PATCH', 'DELETE'],
  },
  'api.datadoghq.com': {
    '/api/v2/hosts': ['GET'],
    '/api/v2/hosts/{host_name}': ['GET', 'PATCH', 'DELETE'],
    '/api/v2/hosts/{host_name}/metrics': ['GET'],
    '/api/v2/logs': ['GET', 'POST'],
    '/api/v2/logs/{id}': ['GET', 'PATCH', 'DELETE'],
    '/api/v2/logs/search': ['GET'],
    '/api/v2/team': ['GET', 'POST'],
    '/api/v2/team/{id}': ['GET', 'PATCH', 'DELETE'],
    '/api/v2/team/search': ['GET'],
  },

  // Payment - Stripe (https://stripe.com/docs/api)
  'api.stripe.com': {
    '/v1/products': ['GET', 'POST'],
    '/v1/products/{id}': ['GET', 'PATCH', 'DELETE'],
    '/v1/products/search': ['GET'],
    '/v1/prices': ['GET', 'POST'],
    '/v1/prices/{id}': ['GET', 'PATCH', 'DELETE'],
    '/v1/prices/search': ['GET'],
    '/v1/invoices': ['GET', 'POST'],
    '/v1/invoices/{id}': ['GET', 'PATCH', 'DELETE'],
    '/v1/invoices/upcoming': ['GET'],
    '/v1/invoices/search': ['GET'],
  },

  // Payment - Plaid (https://plaid.com/docs/)
  'production.plaid.com': {
    '/v1/accounts': ['GET'],
    '/v1/transactions': ['GET'],
    '/v1/transactions/{transaction_id}': ['GET'],
    '/v1/transactions/{transaction_id}/metadata': ['PATCH'],
    '/v1/transactions/{transaction_id}/attachments': ['GET', 'POST'],
    '/v1/transactions/{transaction_id}/attachments/{attachment_id}': [
      'GET',
      'DELETE',
    ],
    '/v1/investments/holdings': ['GET'],
    '/v1/investments/transactions': ['GET'],
    '/v1/transfer': ['POST'],
    '/v1/transfer/{transfer_id}': ['GET', 'PATCH', 'DELETE'],
  },

  // Payment - PayPal (https://developer.paypal.com/docs/api/overview/)
  'api.paypal.com': {
    '/v2/payments': ['GET', 'POST'],
    '/v2/payments/{payment_id}': ['GET', 'PATCH'],
    '/v2/payments/{payment_id}/capture': ['POST'],
    '/v2/payments/{payment_id}/void': ['POST'],
    '/v2/payments/{payment_id}/refund': ['POST'],
  },

  // Payment - Finicity (https://docs.finicity.com/)
  'finicity.com': {
    '/aggregation/v2/partners': ['GET'],
    '/aggregation/v2/customers': ['GET', 'POST'],
    '/aggregation/v2/customers/{customer_id}': ['GET', 'PATCH', 'DELETE'],
    '/aggregation/v2/customers/{customer_id}/accounts': ['GET'],
    '/aggregation/v2/customers/{customer_id}/accounts/{account_id}': ['GET'],
    '/aggregation/v2/customers/{customer_id}/accounts/{account_id}/transactions':
      ['GET'],
  },

  // Payment - Intuit (https://developer.intuit.com/app/developer/qbo/docs/api/accounting)
  'api.intuit.com': {
    '/v3/company/{company_id}/accounts': ['GET'],
    '/v3/company/{company_id}/accounts/{account_id}': ['GET', 'PATCH'],
    '/v3/company/{company_id}/customers': ['GET', 'POST'],
    '/v3/company/{company_id}/customers/{customer_id}': ['GET', 'PATCH'],
    '/v3/company/{company_id}/invoices': ['GET', 'POST'],
    '/v3/company/{company_id}/invoices/{invoice_id}': ['GET', 'PATCH'],
    '/v3/company/{company_id}/payments': ['GET', 'POST'],
    '/v3/company/{company_id}/payments/{payment_id}': ['GET', 'PATCH'],
  },

  // LLM - OpenAI (https://beta.openai.com/docs/api-reference/chat)
  'api.openai.com': {
    '/v1/chat/completions': ['POST'],
  },

  // LLM - Anthropic (https://docs.anthropic.com/claude/reference/getting-started-with-the-api)
  'api.anthropic.com': {
    '/v1/message': ['POST'],
    '/v1/complete': ['POST'],
  },

  // LLM - Cohere (https://docs.cohere.com/reference/about)
  'cohere.com': {
    '/chat': ['POST'],
    '/embed': ['POST'],
    '/embed-jobs': ['POST'],
    '/rerank': ['POST'],
    '/classify': ['POST'],
    '/datasets': ['GET', 'POST', 'DELETE'],
    '/tokenize': ['POST'],
    '/detokenize': ['POST'],
    '/connectors': ['GET', 'POST', 'PATCH', 'DELETE'],
    '/model': ['GET'],
    '/finetuning': ['GET', 'POST', 'PATCH', 'DELETE'],
  },

  // LLM - Azure

  // LLM - Mistral (https://docs.mistral.ai/api)
  'api.mistral.ai': {
    '/v1/chat/completions': ['POST'],
    '/v1/embeddings': ['POST'],
    '/models': ['GET'],
  },

  // LLM - LLaMa (https://docs.llama-api.com/api-reference/endpoint/create)
  'api.llama-api.com': {
    '/chat/completions': ['POST'],
  },

  // LLM - Eleven Labs (https://elevenlabs.io)
  'api.elevenlabs.io': {
    '/v1/text-to-speech/{voice_id}': ['POST'],
    '/v1/text-to-speech/{voice_id}/stream': ['POST'],
    '/v1/speech-to-speech/{voice_id}': ['POST'],
    '/v1/speech-to-speech/{voice_id}/stream': ['POST'],
    '/v1/history': ['GET'],
    '/v1/history/{history_item_id}': ['GET', 'DELETE'],
    '/v1/history/{history_item_id}/get': ['GET'],
    '/v1/history/download': ['POST'],
    '/v1/voices/{voice_id}/samples/{sample_id}': ['GET', 'DELETE'],
    '/v1/user/': ['GET'],
    '/v1/user/subscription': ['GET'],
  },

  // Geolocation, Travel - Google Maps (https://developers.google.com/maps/documentation/geolocation/overview, https://developers.google.com/travel)
  'google.com': {
    '/geolocation/v1/geolocate': ['POST'],
    '/travel/v1/trips': ['GET', 'POST'],
    '/travel/v1/trips/{_param_1}': ['GET', 'PUT', 'DELETE'],
    '/travel/v1/trips/{_param_1}/itinerary': ['GET'],
    '/travel/v1/trips/{_param_1}/itinerary/{_param_2}': ['GET'],
    '/travel/v1/trips/{_param_1}/itinerary/{_param_2}/flight': ['GET'],
    '/travel/v1/trips/{_param_1}/itinerary/{_param_2}/hotel': ['GET'],
    '/travel/v1/trips/{_param_1}/itinerary/{_param_2}/car': ['GET'],
  },

  // Gelocation - Mapbox (https://docs.mapbox.com/api/maps/geocoding/)
  'api.mapbox.com': {
    '/geocoding/v5/mapbox.places/query.json': ['GET'],
  },

  // Geolocation - HERE (https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/endpoint-geocode-brief.html)
  'here.com': {
    '/v1/geocode': ['GET'],
  },

  // Geolocation - FourKites (https://developer.fourkites.com/docs/overview)
  'api.fourkites.com': {
    '/geolocation': ['POST'],
  },

  // Geolocation - ShipEngine (https://www.shipengine.com/docs/)
  'api.shipengine.com': {
    '/v1/geolocation': ['POST'],
  },

  // Travel - Amadeus (https://developers.amadeus.com/self-service/category/travel-apis)
  'api.amadeus.com': {
    '/v2/shopping/flight-offers': ['GET'],
    '/v2/shopping/flight-offers/pricing': ['POST'],
    '/v2/shopping/flight-offers/recommendation': ['POST'],
    '/v2/shopping/flight-offers/extensive-search': ['GET'],
    '/v2/shopping/flight-offers/pricing/extensive-search': ['POST'],
    '/v2/shopping/flight-offers/recommendation/extensive-search': ['POST'],
  },

  // Travel - Sabre (https://developer.sabre.com/docs/soap_apis/management/itinerary)
  'api.sabre.com': {
    '/v1/shop/flights': ['GET'],
    '/v1/shop/flights/fares': ['GET'],
    '/v1/shop/flights/fares/extensive-search': ['GET'],
    '/v1/shop/flights/fares/recommendation': ['GET'],
    '/v1/shop/flights/fares/pricing': ['GET'],
    '/v1/shop/flights/fares/pricing/extensive-search': ['GET'],
    '/v1/shop/flights/fares/pricing/recommendation': ['GET'],
  },

  // Travel - Expedia Group (https://developers.expediagroup.com/docs/products/rapid)
  'api.ean.com': {
    '/v3/payment-sessions': ['POST'],
    '/v3/regions': ['GET'],
    '/v3/regions/{region_id}': ['GET'],
    '/v3/properties/content': ['GET'],
    '/v3/properties/inactive': ['GET'],
    '/v3/properties/availability': ['GET'],
    '/v3/properties/{property_id}/guest-reviews': ['GET'],
    '/v3/properties/{property_id}/availability': ['GET'],
    '/v3/properties/{property_id}/payment-options': ['GET'],
    '/v3/properties/{property_id}/rooms/{room_id}/rates/{rate_id}': ['GET'],
    '/v3/properties/geography': ['POST'],
    '/v3/calendars/availability': ['GET'],
    '/v3/files/properties/catalog': ['GET'],
    '/v3/files/properties/content': ['GET'],
    '/v3/chains': ['GET'],
    '/v3/itineraries': ['GET', 'POST'],
    '/v3/itineraries/{itinerary_id}': ['GET', 'PUT', 'DELETE'],
    '/v3/itineraries/{itinerary_id}/payment-sessions': ['PUT'],
    '/v3/itineraries/{itinerary_id}/rooms/{room_id}': ['PUT', 'DELETE'],
    '/v3/itineraries/{itinerary_id}/rooms/{room_id}/pricing': ['PUT'],
  },

  // Travel - Booking.com (https://developers.booking.com/api)
  'demandapi.booking.com': {
    '/3.1/accomodations/availability': ['POST'],
    '/3.1/accomodations/chain': ['POST'],
    '/3.1/accomodations/constants': ['POST'],
    '/3.1/accomodations/details': ['POST'],
    '/3.1/accomodations/details/changes': ['POST'],
    '/3.1/accomodations/reviews': ['POST'],
    '/3.1/accomodations/reviews/scores': ['POST'],
    '/3.1/accomodations/search': ['POST'],
    '/3.1/common/locations/airports': ['POST'],
    '/3.1/common/locations/cities': ['POST'],
    '/3.1/common/locations/countries': ['POST'],
    '/3.1/common/locations/districts': ['POST'],
    '/3.1/common/locations/landmarks': ['POST'],
    '/3.1/common/locations/regions': ['POST'],
    '/3.1/common/payments/cards': ['POST'],
    '/3.1/common/payments/currencies': ['POST'],
    '/3.1/common/languages': ['POST'],
    '/3.1/orders/cancel': ['POST'],
    '/3.1/orders/create': ['POST'],
    '/3.1/orders/details': ['POST'],
    '/3.1/orders/details/accomodations': ['POST'],
    '/3.1/orders/preview': ['POST'],
  },
};

export const mockConsumersProvidersLookup: Record<string, string[]> = {
  ['LLM Service']: [
    'api.openai.com',
    'api.anthropic.com',
    'cohere.com',
    'api.mistral.ai',
    'api.llama-api.com',
    'api.elevenlabs.io',
  ],
  ['Geolocation App']: [
    'google.com',
    'api.mapbox.com',
    'here.com',
    'api.fourkites.com',
    'api.shipengine.com',
  ],
  ['Travel System']: [
    'api.amadeus.com',
    'google.com',
    'api.sabre.com',
    'api.ean.com',
    'demandapi.booking.com',
  ],
  ['Payment Gateway']: [
    'api.stripe.com',
    'production.plaid.com',
    'api.paypal.com',
    'finicity.com',
    'api.intuit.com',
  ],
  Other: [
    'api.github.com',
    'httpbin.org',
    'api.sendgrid.com',
    'api.datadoghq.com',
  ],
};

export const mockUrlTree: PrimitiveUrlTreeDAO = {
  providers: {
    'api.github.com': {
      declared: {
        '/orgs/{org_id}/issues': [
          {
            id: 1,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'orgs',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 2,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 4,
              value: 'org_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 3,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'issues',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/issues/{issue_number}': [
          {
            id: 130,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'issues',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 131,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 132,
              value: 'issue_number',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/issues/{issue_number}/comments': [
          {
            id: 130,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'issues',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 131,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 132,
              value: 'issue_number',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 132,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'comments',
            createdAt: now,
            updatedAt: now,
          },
        ],
      },
      underdeclared: {
        '/repos/{_param_1}/{_param_2}/issues': [
          {
            id: 9,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'repos',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 10,
            index: 2,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 11,
            index: 3,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 12,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'issues',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/repos/{_param_1}/{_param_2}/issues/{_param_3}': [
          {
            id: 9,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'repos',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 10,
            index: 2,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 11,
            index: 3,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 12,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'issues',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 13,
            index: 5,
            kind: PathPartKindDAO.Assumed,
            value: '_param_3',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/repos/{_param_1}/{_param_2}/pulls': [
          {
            id: 9,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'repos',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 10,
            index: 2,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 11,
            index: 3,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 14,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'pulls',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/repos/{_param_1}/{_param_2}/pulls/{_param_3}': [
          {
            id: 9,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'repos',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 10,
            index: 2,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 11,
            index: 3,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 14,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'pulls',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 15,
            index: 5,
            kind: PathPartKindDAO.Assumed,
            value: '_param_3',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/repos/{_param_1}/{_param_2}/pulls/{_param_3}/commits': [
          {
            id: 9,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'repos',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 10,
            index: 2,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 11,
            index: 3,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 14,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'pulls',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 15,
            index: 5,
            kind: PathPartKindDAO.Assumed,
            value: '_param_3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 16,
            index: 6,
            kind: PathPartKindDAO.Constant,
            value: 'commits',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/repos/{_param_1}/{_param_2}/pulls/{_param_3}/files': [
          {
            id: 9,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'repos',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 10,
            index: 2,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 11,
            index: 3,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 14,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'pulls',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 15,
            index: 5,
            kind: PathPartKindDAO.Assumed,
            value: '_param_3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 16,
            index: 6,
            kind: PathPartKindDAO.Constant,
            value: 'files',
            createdAt: now,
            updatedAt: now,
          },
        ],
      },
    },
    'httpbin.org': {
      declared: {
        '/status/{code}': [
          {
            id: 44,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'status',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 45,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 46,
              value: 'code',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/delay/{delay}': [
          {
            id: 47,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'delay',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 48,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 49,
              value: 'delay',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/relative-redirect/{n}': [
          {
            id: 50,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'relative-redirect',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 51,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 52,
              value: 'n',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/absolute-redirect/{n}': [
          {
            id: 53,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'absolute-redirect',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 54,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 55,
              value: 'n',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/basic-auth/{user}/{passwd}': [
          {
            id: 56,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'basic-auth',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 57,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 58,
              value: 'user',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 59,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 60,
              value: 'passwd',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/hidden-basic-auth/{user}/{passwd}': [
          {
            id: 61,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'hidden-basic-auth',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 62,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 63,
              value: 'user',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 64,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 65,
              value: 'passwd',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/stream/{n}': [
          {
            id: 66,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'stream',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 67,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 68,
              value: 'n',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/range/{numbytes}': [
          {
            id: 69,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'range',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 70,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 71,
              value: 'numbytes',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/cache/{n}': [
          {
            id: 72,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'cache',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 73,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 74,
              value: 'n',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/etag/{etag}': [
          {
            id: 75,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'etag',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 76,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 77,
              value: 'etag',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/redirect-to/{url}': [
          {
            id: 78,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'redirect-to',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 79,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 80,
              value: 'url',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/relative-redirect-to/{url}': [
          {
            id: 81,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'relative-redirect-to',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 82,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 83,
              value: 'url',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/absolute-redirect-to/{url}': [
          {
            id: 84,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'absolute-redirect-to',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 85,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 86,
              value: 'url',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/digest-auth/{qop}/{user}/{passwd}': [
          {
            id: 132,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'digest-auth',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 133,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 134,
              value: 'qop',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 135,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 136,
              value: 'user',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 137,
            index: 2,
            kind: PathPartKindDAO.Mapped,
            value: '_param_3',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 138,
              value: 'passwd',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
      },
      underdeclared: {},
    },
    'api.sendgrid.com': {
      declared: {
        '/v3/messages/{msg_id}': [
          {
            id: 87,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 88,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'messages',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 89,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 90,
              value: 'msg_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/alerts/{id}': [
          {
            id: 87,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 91,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'alerts',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 92,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 93,
              value: 'id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/templates/{id}': [
          {
            id: 87,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 94,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'templates',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 95,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 96,
              value: 'id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/templates/versions/{id}': [
          {
            id: 87,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 94,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'templates',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 97,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'versions',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 98,
            index: 4,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 99,
              value: 'id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
      },
      underdeclared: {},
    },
    'api.stripe.com': {
      declared: {
        '/v1/products/{id}': [
          {
            id: 100,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 101,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'products',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 102,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 103,
              value: 'id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v1/prices/{id}': [
          {
            id: 100,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 104,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'prices',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 105,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 106,
              value: 'id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v1/invoices/{id}': [
          {
            id: 100,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 107,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'invoices',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 108,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 109,
              value: 'id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
      },
      underdeclared: {},
    },
    'production.plaid.com': {
      declared: {
        '/v1/transactions/{transaction_id}': [
          {
            id: 110,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 112,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'transactions',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 113,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 114,
              value: 'transaction_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v1/transactions/{transaction_id}/metadata': [
          {
            id: 110,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 112,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'transactions',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 113,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 114,
              value: 'transaction_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 115,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'metadata',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v1/transactions/{transaction_id}/attachments': [
          {
            id: 110,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 112,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'transactions',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 113,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 114,
              value: 'transaction_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 116,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'attachments',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v1/transactions/{transaction_id}/attachments/{attachment_id}': [
          {
            id: 110,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 112,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'transactions',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 113,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 114,
              value: 'transaction_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 116,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'attachments',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 117,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 118,
              value: 'attachment_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v1/transfer/{transfer_id}': [
          {
            id: 119,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 120,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'transfer',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 121,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 122,
              value: 'transfer_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
      },
      underdeclared: {},
    },
    'api.paypal.com': {
      declared: {
        '/v1/payments/{payment_id}': [
          {
            id: 123,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 124,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'payments',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 125,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 126,
              value: 'payment_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v1/payments/{payment_id}/capture': [
          {
            id: 123,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 124,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'payments',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 125,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 126,
              value: 'payment_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 127,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'capture',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v1/payments/{payment_id}/void': [
          {
            id: 123,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 124,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'payments',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 125,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 126,
              value: 'payment_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 128,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'void',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v1/payments/{payment_id}/refund': [
          {
            id: 123,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 124,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'payments',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 125,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 126,
              value: 'payment_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 129,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'refund',
            createdAt: now,
            updatedAt: now,
          },
        ],
      },
      underdeclared: {},
    },
    'finicity.com': {
      declared: {
        '/aggregation/v2/customers/{customer_id}': [
          {
            id: 130,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'aggregation',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 131,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 132,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'customers',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 133,
            index: 4,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 134,
              value: 'customer_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/aggregation/v2/customers/{customer_id}/accounts': [
          {
            id: 130,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'aggregation',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 131,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 132,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'customers',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 133,
            index: 4,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 134,
              value: 'customer_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 135,
            index: 5,
            kind: PathPartKindDAO.Constant,
            value: 'accounts',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/aggregation/v2/customers/{customer_id}/accounts/{account_id}': [
          {
            id: 130,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'aggregation',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 131,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 132,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'customers',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 133,
            index: 4,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 134,
              value: 'customer_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 135,
            index: 5,
            kind: PathPartKindDAO.Constant,
            value: 'accounts',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 136,
            index: 6,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 137,
              value: 'account_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/aggregation/v2/customers/{customer_id}/accounts/{account_id}/transactions':
          [
            {
              id: 130,
              index: 1,
              kind: PathPartKindDAO.Constant,
              value: 'aggregation',
              createdAt: now,
              updatedAt: now,
            },
            {
              id: 131,
              index: 2,
              kind: PathPartKindDAO.Constant,
              value: 'v2',
              createdAt: now,
              updatedAt: now,
            },
            {
              id: 132,
              index: 3,
              kind: PathPartKindDAO.Constant,
              value: 'customers',
              createdAt: now,
              updatedAt: now,
            },
            {
              id: 133,
              index: 4,
              kind: PathPartKindDAO.Mapped,
              value: '_param_1',
              createdAt: now,
              updatedAt: now,
              mapping: {
                id: 134,
                value: 'customer_id',
                createdAt: now,
                updatedAt: now,
              },
            },
            {
              id: 135,
              index: 5,
              kind: PathPartKindDAO.Constant,
              value: 'accounts',
              createdAt: now,
              updatedAt: now,
            },
            {
              id: 136,
              index: 6,
              kind: PathPartKindDAO.Mapped,
              value: '_param_2',
              createdAt: now,
              updatedAt: now,
              mapping: {
                id: 137,
                value: 'account_id',
                createdAt: now,
                updatedAt: now,
              },
            },
            {
              id: 138,
              index: 7,
              kind: PathPartKindDAO.Constant,
              value: 'transactions',
              createdAt: now,
              updatedAt: now,
            },
          ],
      },
      underdeclared: {},
    },
    'api.intuit.com': {
      declared: {
        '/v3/company/{company_id}/accounts/{account_id}': [
          {
            id: 139,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 140,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'company',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 141,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 142,
              value: 'company_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 143,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'accounts',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 144,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 145,
              value: 'account_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/company/{company_id}/customers/{customer_id}': [
          {
            id: 139,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 140,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'company',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 141,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 142,
              value: 'company_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 146,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'customers',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 147,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 148,
              value: 'customer_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/company/{company_id}/invoices/{invoice_id}': [
          {
            id: 139,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 140,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'company',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 141,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 142,
              value: 'company_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 149,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'invoices',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 150,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 151,
              value: 'invoice_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/company/{company_id}/payments/{payment_id}': [
          {
            id: 139,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 140,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'company',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 141,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 142,
              value: 'company_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 152,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'payments',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 153,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 154,
              value: 'payment_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
      },
      underdeclared: {},
    },
    'api.elevenlabs.io': {
      declared: {
        '/v1/text-to-speech/{voice_id}': [
          {
            id: 155,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 156,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'text-to-speech',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 157,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 158,
              value: 'voice_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v1/text-to-speech/{voice_id}/stream': [
          {
            id: 155,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 156,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'text-to-speech',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 157,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 158,
              value: 'voice_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 159,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'stream',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v1/speech-to-speech/{voice_id}': [
          {
            id: 155,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 160,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'speech-to-speech',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 161,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 162,
              value: 'voice_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v1/speech-to-speech/{voice_id}/stream': [
          {
            id: 155,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 160,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'speech-to-speech',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 161,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 162,
              value: 'voice_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 163,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'stream',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v1/history/{history_item_id}': [
          {
            id: 155,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 164,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'history',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 165,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 166,
              value: 'history_item_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v1/history/{history_item_id}/get': [
          {
            id: 155,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 164,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'history',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 165,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 166,
              value: 'history_item_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 167,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'get',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v1/voices/{voice_id}/samples/{sample_id}': [
          {
            id: 155,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 168,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'voices',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 169,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 170,
              value: 'voice_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 171,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'samples',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 172,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 173,
              value: 'sample_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
      },
      underdeclared: {},
    },
    'google.com': {
      declared: {},
      underdeclared: {
        '/travel/v1/trips/{_param_1}': [
          {
            id: 174,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'travel',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 175,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 176,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'trips',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 177,
            index: 4,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/travel/v1/trips/{_param_1}/itinerary': [
          {
            id: 174,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'travel',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 175,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 176,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'trips',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 177,
            index: 4,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 178,
            index: 5,
            kind: PathPartKindDAO.Constant,
            value: 'itinerary',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/travel/v1/trips/{_param_1}/itinerary/{_param_2}': [
          {
            id: 174,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'travel',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 175,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 176,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'trips',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 177,
            index: 4,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 178,
            index: 5,
            kind: PathPartKindDAO.Constant,
            value: 'itinerary',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 179,
            index: 6,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/travel/v1/trips/{_param_1}/itinerary/{_param_2}/flight': [
          {
            id: 174,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'travel',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 175,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 176,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'trips',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 177,
            index: 4,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 178,
            index: 5,
            kind: PathPartKindDAO.Constant,
            value: 'itinerary',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 179,
            index: 6,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 180,
            index: 7,
            kind: PathPartKindDAO.Constant,
            value: 'flight',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/travel/v1/trips/{_param_1}/itinerary/{_param_2}/hotel': [
          {
            id: 174,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'travel',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 175,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 176,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'trips',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 177,
            index: 4,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 178,
            index: 5,
            kind: PathPartKindDAO.Constant,
            value: 'itinerary',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 179,
            index: 6,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 181,
            index: 7,
            kind: PathPartKindDAO.Constant,
            value: 'hotel',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/travel/v1/trips/{_param_1}/itinerary/{_param_2}/car': [
          {
            id: 174,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'travel',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 175,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 176,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'trips',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 177,
            index: 4,
            kind: PathPartKindDAO.Assumed,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 178,
            index: 5,
            kind: PathPartKindDAO.Constant,
            value: 'itinerary',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 179,
            index: 6,
            kind: PathPartKindDAO.Assumed,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 182,
            index: 7,
            kind: PathPartKindDAO.Constant,
            value: 'car',
            createdAt: now,
            updatedAt: now,
          },
        ],
      },
    },
    'api.ean.com': {
      declared: {
        '/v3/regions/{region_id}': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 184,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'regions',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 185,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 186,
              value: 'region_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/properties/{property_id}/guest-reviews': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 187,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'properties',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 188,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 189,
              value: 'property_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 190,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'guest-reviews',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v3/properties/{property_id}/availability': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 187,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'properties',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 188,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 189,
              value: 'property_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 191,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'availability',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v3/properties/{property_id}/payment-options': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 187,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'properties',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 188,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 189,
              value: 'property_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 192,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'payment-options',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v3/properties/{property_id}/rooms/{room_id}/rates/{rate_id}': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 187,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'properties',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 188,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 189,
              value: 'property_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 193,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'rooms',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 194,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 195,
              value: 'room_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 196,
            index: 6,
            kind: PathPartKindDAO.Constant,
            value: 'rates',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 197,
            index: 7,
            kind: PathPartKindDAO.Mapped,
            value: '_param_3',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 198,
              value: 'rate_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/itineraries/{itinerary_id}': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 199,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'itineraries',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 200,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 201,
              value: 'itinerary_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/itineraries/{itinerary_id}/payment-sessions': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 199,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'itineraries',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 200,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 201,
              value: 'itinerary_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 202,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'payment-sessions',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/v3/itineraries/{itinerary_id}/rooms/{room_id}': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 199,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'itineraries',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 200,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 201,
              value: 'itinerary_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 203,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'rooms',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 204,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 205,
              value: 'room_id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/v3/itineraries/{itinerary_id}/rooms/{room_id}/pricing': [
          {
            id: 183,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'v3',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 199,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'itineraries',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 200,
            index: 3,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 201,
              value: 'itinerary_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 203,
            index: 4,
            kind: PathPartKindDAO.Constant,
            value: 'rooms',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 204,
            index: 5,
            kind: PathPartKindDAO.Mapped,
            value: '_param_2',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 205,
              value: 'room_id',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 206,
            index: 6,
            kind: PathPartKindDAO.Constant,
            value: 'pricing',
            createdAt: now,
            updatedAt: now,
          },
        ],
      },
      underdeclared: {},
    },
    'api.datadoghq.com': {
      declared: {
        '/api/v2/hosts/{host_name}': [
          {
            id: 207,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'api',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 208,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 209,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'hosts',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 210,
            index: 4,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 211,
              value: 'host_name',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/api/v2/hosts/{host_name}/metrics': [
          {
            id: 207,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'api',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 208,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 209,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'hosts',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 210,
            index: 4,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 211,
              value: 'host_name',
              createdAt: now,
              updatedAt: now,
            },
          },
          {
            id: 212,
            index: 5,
            kind: PathPartKindDAO.Constant,
            value: 'metrics',
            createdAt: now,
            updatedAt: now,
          },
        ],
        '/api/v2/logs/{id}': [
          {
            id: 207,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'api',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 213,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 214,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'logs',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 215,
            index: 4,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 216,
              value: 'id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
        '/api/v2/team/{id}': [
          {
            id: 207,
            index: 1,
            kind: PathPartKindDAO.Constant,
            value: 'api',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 217,
            index: 2,
            kind: PathPartKindDAO.Constant,
            value: 'v2',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 218,
            index: 3,
            kind: PathPartKindDAO.Constant,
            value: 'team',
            createdAt: now,
            updatedAt: now,
          },
          {
            id: 219,
            index: 4,
            kind: PathPartKindDAO.Mapped,
            value: '_param_1',
            createdAt: now,
            updatedAt: now,
            mapping: {
              id: 220,
              value: 'id',
              createdAt: now,
              updatedAt: now,
            },
          },
        ],
      },
      underdeclared: {},
    },
  },
};
