<script setup lang="ts">
import { computed } from 'vue';
import { getAsset } from '../../utils';

import { UserStatus } from '@monorepo/shared-model/src/user';

import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseTable from '../BaseTable/BaseTable.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseHeading from '../BaseHeading/BaseHeading.vue';
import Column from 'primevue/column';

import { useUserStore } from '../../stores/user';
import { useDataStore } from '../../stores/data';

const userStore = useUserStore();
const dataStore = useDataStore();

const isInstalled = computed(
  () =>
    userStore?.extendedUser?.status === UserStatus.Integrated ||
    dataStore.isDemoMode
);
</script>

<template>
  <div class="dashboard-overview-table-no-results">
    <BaseTable>
      <Column style="padding-right: 32px" />
      <Column field="name" header="API provider" style="width: 20%"></Column>
      <Column
        field="totalEndpoints"
        header="Total endpoints"
        style="width: 20%"
      ></Column>
      <Column
        field="totalCalls"
        header="Total API calls"
        style="width: 20%"
      ></Column>
      <Column
        field="errorRate"
        header="Error rate (%)"
        style="width: 20%"
      ></Column>
      <Column
        field="avgRuntime"
        header="Avg. runtime"
        style="width: 20%"
      ></Column>
    </BaseTable>

    <BaseLayoutGap
      class="dashboard-overview-table-no-results__body"
      alignment="center"
      direction="column"
    >
      <img
        :src="getAsset('/images/no-api-calls.svg')"
        alt="End to end connectivity"
      />
      <template v-if="!isInstalled">
        <BaseHeading :level="2" variant="brand"
          >Lunar is installed 🚀</BaseHeading
        >
        <BaseProse alignment="center">
          <p>
            Your app and Lunar are now connected!<br />
            Once the first API call occurs you'll see it here.
          </p>
        </BaseProse>
      </template>
      <template v-else>
        <BaseHeading :level="2" variant="brand">No data found</BaseHeading>
        <BaseProse alignment="center">
          <p>
            There is no data in the selected timeframe.<br />
            Please expand the timeframe to see your API consumption.
          </p>
        </BaseProse>
      </template>
    </BaseLayoutGap>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;
.dashboard-overview-table-no-results {
  &__body {
    padding: $space-xlarge $space-xlarge $space-xlarge * 3;
  }
}
</style>
