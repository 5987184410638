<script setup lang="ts">
import { type IconProps } from '../BaseIcon/BaseIcon.vue';
import BaseBlock from '../BaseBlock/BaseBlock.vue';
import BaseBlockHeader from '../BaseBlockHeader/BaseBlockHeader.vue';
import BaseBlockFooter from '../BaseBlockFooter/BaseBlockFooter.vue';
import DashboardConnectionItemSkeleton from '../DashboardConnectionItemSkeleton/DashboardConnectionItemSkeleton.vue';
import { computed } from 'vue';

const props = defineProps<{
  icon: IconProps['name'];
  onboarding?: boolean;
  placeholder?: boolean;
  connected?: boolean;
  hideConnection?: boolean;
  compact?: boolean;
}>();

const computedClasses = computed(() => ({
  'dashboard-connection--connected': props.connected,
  'dashboard-connection--hide-connection': props.hideConnection,
  'dashboard-connection--compact': props.compact,
}));

const computedVariant = computed(() => {
  return props.onboarding ? 'brand-dashed' : undefined;
});
</script>

<template>
  <BaseBlock
    class="dashboard-connection"
    :class="computedClasses"
    :variant="computedVariant"
  >
    <BaseBlockHeader class="dashboard-connection__header" :icon="icon">
      <template v-if="!compact" #title><slot name="title" /></template
      ><template v-if="!compact" #default><slot name="addAction" /></template
    ></BaseBlockHeader>
    <div v-if="!compact" class="dashboard-connection__body">
      <template v-if="placeholder">
        <DashboardConnectionItemSkeleton
          v-for="n in 2"
          :key="n"
          class="dashboard-connection__placeholder"
        />
      </template>
      <template v-else>
        <slot />
      </template>
    </div>
    <BaseBlockFooter v-if="!compact">
      <slot name="footer" />
    </BaseBlockFooter>
  </BaseBlock>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;
.dashboard-connection {
  $self: &;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &::before {
    background-color: $color-bg-neutral;
    border-radius: 100%;
    display: block;
    content: '';
    height: $scale-xxxxsmall;
    left: $space-xsmall;
    position: absolute;
    top: $space-xsmall;
    width: $scale-xxxxsmall;
  }

  &--connected {
    &::before {
      background-color: $pine-400;
    }
  }

  &--hide-connection {
    &::before {
      display: none;
    }
  }

  &--compact {
    #{$self}__header {
      padding: $space-small $space-small + $space-xxxxsmall;
    }
  }
}
</style>
