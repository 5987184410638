import { type PluginContent, RemedyType } from '../../../types/content';

const content: PluginContent = {
  type: RemedyType.Plugin,
  icon: 'throttling',
  name: 'Response-Based Throttling',
  summary:
    'Minimize cooldown periods by handling API rate limits based on response headers.',
  category: ['Traffic control'],
  docsUrl:
    'https://docs.lunar.dev/product-features/remedy-plugins/response-based-throttling',
  details: `
Copy the following configuration to your local \`policies.yaml\` file. Modify the pre-defined configuration settings as you wish and according to
your own business logic.

#### Test configuration

\`\`\`powershell
/etc/lunar-proxy/policies.yaml
\`\`\`

\`\`\`yaml
endpoints:
  - url: api.com/resource/{id}
    method: GET
    remedies:
      - name: api.com Response-Based Throttling
        enabled: true
        config:
          response_based_throttling:
            retry_after_header: Retry-After
            retry_after_type: relative_seconds
            quota_group: quota_group_1
            relevant_statuses:
              - 429
\`\`\`

#### Apply policies

After you have altered policies.yaml according to your needs, run the \`apply_policies\` command:

\`\`\`powershell
docker exec lunar-proxy apply_policies
\`\`\`
`,
};

export default content;
