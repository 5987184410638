<script setup lang="ts">
import AppConnection from '../AppConnection/AppConnection.vue';

import BaseActions from '../BaseActions/BaseActions.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';
import BaseStatusIndicator from '../BaseStatusIndicator/BaseStatusIndicator.vue';

import { useDataStore } from '../../stores/data';
import { computed } from 'vue';

defineProps<{
  compact?: boolean;
}>();

const dataStore = useDataStore();

const openConnectionStatus = defineModel<boolean>('openConnectionStatus', {
  default: false,
});

const toggleConnectionStatus = () => {
  openConnectionStatus.value = !openConnectionStatus.value;
};

const isOnboarded = computed(
  () =>
    dataStore.isConnected &&
    dataStore.isProxyInstalled &&
    dataStore.isInterceptorInstalled
);
const connectionStatusText = computed(() =>
  isOnboarded.value ? 'Signal' : 'Waiting…'
);
const connectionStatusIndicator = computed(() =>
  isOnboarded.value ? 'success' : 'neutral'
);
</script>

<template>
  <BaseActions
    class="app-connection-status"
    placement="right"
    v-model="openConnectionStatus"
    :disabled="!isOnboarded"
  >
    <template #content>
      <div class="app-connection-status__connection">
        <AppConnection />
      </div>
    </template>

    <BaseTooltip
      v-if="compact"
      class="app-connection-status__tooltip"
      content="Signal"
      placement="right"
      :disabled="openConnectionStatus && !isOnboarded"
      @click.stop
    >
      <BaseButton
        class="app-connection-status__action"
        variant="ghost"
        mode="dark"
        :disabled="!isOnboarded"
        @click.stop="toggleConnectionStatus"
        ><template #leftIcon
          ><BaseStatusIndicator
            :variant="connectionStatusIndicator" /></template
      ></BaseButton>
    </BaseTooltip>
    <BaseButton
      v-else
      class="app-connection-status__action"
      variant="ghost"
      mode="dark"
      :disabled="!isOnboarded"
      @click.stop="toggleConnectionStatus"
      ><template #leftIcon
        ><BaseStatusIndicator :variant="connectionStatusIndicator" /></template
      ><span v-text="connectionStatusText"
    /></BaseButton>
  </BaseActions>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.app-connection-status {
  $self: &;

  min-width: $scale-large + $scale-xtiny;
  width: auto;

  &__connection {
    max-width: 320px;
    z-index: layer('overlap');
  }
}
</style>
