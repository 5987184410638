<script setup lang="ts">
import { computed, onMounted, ref, nextTick, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import {
  useForm,
  useIsFormValid,
  useIsFormDirty,
  useResetForm,
} from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';

import {
  type PrimitiveUrlTreeProviderDAO,
  type PrimitivePathPartDAO,
  PathPartKindDAO,
} from '@monorepo/shared-model/src/url-tree';

import BaseLoader from '../BaseLoader/BaseLoader.vue';
import BaseInput from '../BaseInput/BaseInput.vue';
import BaseForm from '../BaseForm/BaseForm.vue';
import BaseDivider from '../BaseDivider/BaseDivider.vue';
import BaseHighlight from '../BaseHighlight/BaseHighlight.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';

import { useToast } from '../../use/toast';
import { type MappedPathPartsRequest } from '../../stores/socket';
import { useDataStore } from '../../stores/data';
import { validPathParamsRegex } from '../../utils';
import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';
import { cloneDeep } from 'lodash-es';

type MappedOrUnmappedUrlTree =
  | PrimitiveUrlTreeProviderDAO['declared']
  | PrimitiveUrlTreeProviderDAO['underdeclared'];

const props = defineProps<{
  host: string;
}>();

const fields = ref<{ [row: number]: { [partId: number]: any } }>({});
const filter = ref<string>('');
const loading = ref<boolean>(true);
const urlTreeProvider = ref<PrimitiveUrlTreeProviderDAO | undefined>();
const initialModels = ref<MappedPathPartsRequest | undefined>({});
const editingId = ref<number | null>(null);
const cachedValue = ref<string | null>(null);
const activeRow = ref<number | null>(null);

const dataStore = useDataStore();
const toast = useToast();

const schema = yup.object({
  models: yup.lazy((obj) =>
    yup.object(
      Object.keys(obj).reduce(
        (acc: Record<string, yup.AnySchema>, key: string) => {
          // Check if the key is a number
          if (!isNaN(Number(key))) {
            acc[key] = yup.object().shape({
              kind: yup
                .string()
                .oneOf([PathPartKindDAO.Mapped, PathPartKindDAO.Assumed])
                .required(),
              value: yup.string().required(),
            });
          }
          return acc;
        },
        {}
      )
    )
  ),
});

const { errors, handleSubmit, isSubmitting, validate, defineField } = useForm({
  validationSchema: toTypedSchema(schema),
  initialValues: {
    models: {},
  },
});

const isFormValid = useIsFormValid();
const isFormDirty = useIsFormDirty();
const resetForm = useResetForm();

const [models] = defineField('models');

const sortedUrlTreeProvider = computed(() => {
  if (!urlTreeProvider.value) return;

  const filterObject = (obj: MappedOrUnmappedUrlTree) => {
    return Object.keys(obj)
      .filter((key: string) =>
        key.toLowerCase().includes(filter.value.toLowerCase())
      )
      .reduce(
        (res: MappedOrUnmappedUrlTree, key: string) => (
          (res[key] = obj[key]), res
        ),
        {}
      );
  };

  const sortedObjectByKey = (obj: MappedOrUnmappedUrlTree) => {
    return Object.keys(obj)
      .sort()
      .reduce(
        (res: MappedOrUnmappedUrlTree, key: string) => (
          (res[key] = obj[key]), res
        ),
        {}
      );
  };

  const filteredUrlTreeProvider = {
    declared: sortedObjectByKey(filterObject(urlTreeProvider.value.declared)),
    underdeclared: sortedObjectByKey(
      filterObject(urlTreeProvider.value.underdeclared)
    ),
  };

  // Combine the mapped and unmapped endpoints into a single array
  const combinedUrlTrees = [
    ...Object.values(filteredUrlTreeProvider.underdeclared),
    ...Object.values(filteredUrlTreeProvider.declared),
  ];

  // Sort each endpoint array by the index property
  return combinedUrlTrees.map((endpointArray) =>
    endpointArray
      .slice()
      .sort(
        (a: PrimitivePathPartDAO, b: PrimitivePathPartDAO) => a.index - b.index
      )
  );
});

const noResults = computed(() => {
  return (
    !loading.value &&
    (!sortedUrlTreeProvider.value ||
      (sortedUrlTreeProvider.value && sortedUrlTreeProvider.value.length === 0))
  );
});

const onMappablePart = async (row: number, part: PrimitivePathPartDAO) => {
  const id = part.id;

  if (editingId.value !== id) {
    editingId.value = id;
    cachedValue.value = part.value;
    activeRow.value = row;

    await nextTick();

    fields.value[row][id].field.focus();

    onClickOutside(fields.value[row][id].field, () => {
      closeMappablePart(part);
    });
  }
};

const closeMappablePart = (part: PrimitivePathPartDAO) => {
  if (editingId.value) {
    if (
      isInvalid(editingId.value) &&
      cachedValue.value &&
      (models.value as MappedPathPartsRequest)[part.id]
    ) {
      (models.value as MappedPathPartsRequest)[part.id].value =
        cachedValue.value;
    }

    editingId.value = null;
    cachedValue.value = null;
    activeRow.value = null;
  }
};

const getPartDisplayValue = (part: PrimitivePathPartDAO) => {
  if (part.kind !== PathPartKindDAO.Constant) {
    const id = part.id;

    return (models.value as MappedPathPartsRequest)[id]?.value;
  }

  return part.value;
};

const isPartMappable = (part: PrimitivePathPartDAO) => {
  return (
    part.kind !== PathPartKindDAO.Constant &&
    part.kind !== PathPartKindDAO.Wildcard
  );
};

const isPartMapped = (part: PrimitivePathPartDAO) => {
  return (
    part.kind === PathPartKindDAO.Mapped ||
    ((models.value as MappedPathPartsRequest)[part.id] &&
      (models.value as MappedPathPartsRequest)[part.id].value !==
        (initialModels.value as MappedPathPartsRequest)[part.id].value)
  );
};

const setModels = () => {
  if (!urlTreeProvider.value) return;

  const newModels: MappedPathPartsRequest = {};

  Object.values({
    ...urlTreeProvider.value.declared,
    ...urlTreeProvider.value.underdeclared,
  }).forEach((endpoint) => {
    endpoint.forEach((part) => {
      if (part.kind === PathPartKindDAO.Mapped && part.mapping) {
        newModels[part.id] = {
          kind: PathPartKindDAO.Mapped,
          value: part.mapping.value,
          mappingId: part.mapping.id,
        };
      } else if (part.kind === PathPartKindDAO.Assumed) {
        newModels[part.id] = {
          kind: PathPartKindDAO.Assumed,
          value: part.value,
        };
      }
    });
  });

  return newModels;
};

const setFields = (row: number, partId: number, field: any) => {
  if (!fields.value[row]) {
    fields.value[row] = {};
  }

  fields.value[row][partId] = field;
};

const setPartHoverHandlers = async () => {
  await nextTick();

  document.querySelectorAll('[data-mappable="true"]').forEach((element) => {
    element.addEventListener('mouseover', () => {
      const id = element.getAttribute('data-part-id');
      document
        .querySelectorAll(`[data-mappable="true"][data-part-id="${id}"]`)
        .forEach((matchingElement) => {
          matchingElement.classList.add(
            'dashboard-path-params-form__part--highlighted'
          );
        });
    });

    element.addEventListener('mouseout', () => {
      const id = element.getAttribute('data-part-id');
      document
        .querySelectorAll(`[data-mappable="true"][data-part-id="${id}"]`)
        .forEach((matchingElement) => {
          matchingElement.classList.remove(
            'dashboard-path-params-form__part--highlighted'
          );
        });
    });
  });
};

const isDuplicatePart = (index: number, part: PrimitivePathPartDAO) => {
  return activeRow.value !== index && editingId.value === part.id;
};

const isInvalid = (index: number) => {
  return Object.values(errors.value).includes(
    `models.${index}.value is a required field`
  );
};

const saveChanges = handleSubmit(async (values) => {
  // Prepare only the values that have changed.
  const payload = Object.keys(values.models).reduce(
    (acc: MappedPathPartsRequest, key: string) => {
      const id = Number(key);
      if (
        (values.models as MappedPathPartsRequest)[id].value !==
        (initialModels.value as MappedPathPartsRequest)[id].value
      ) {
        acc[id] = (values.models as MappedPathPartsRequest)[id];
      }
      return acc;
    },
    {}
  );

  const results = await dataStore.createOrUpdateMappedPathParts(payload);

  if (!results) {
    toast.add({
      severity: 'error',
      summary: 'Failed to save path params.',
      life: 3000,
    });
  } else {
    results.forEach((result) => {
      (models.value as MappedPathPartsRequest)[result.pathPartId] = {
        kind: PathPartKindDAO.Mapped,
        value: result.value,
        mappingId: result.id,
      };
    });

    initialModels.value = cloneDeep(models.value) as MappedPathPartsRequest;
    resetForm({ values: { models: models.value } });

    toast.add({
      severity: 'success',
      summary: 'Path params saved successfully.',
      life: 3000,
    });
  }
});

onMounted(async () => {
  urlTreeProvider.value = await dataStore.getUrlTreeProvider(props.host);
  loading.value = false;

  models.value = setModels();

  resetForm({ values: { models: models.value } });
  initialModels.value = cloneDeep(models.value) as MappedPathPartsRequest;

  await setPartHoverHandlers();
});

// Validate the form whenever the models change.
watch(
  () => models.value,
  () => {
    validate();
  },
  {
    deep: true,
  }
);

// Update the part hover handlers whenever the filter changes.
watch(
  () => filter.value,
  async () => {
    await setPartHoverHandlers();
  }
);
</script>

<template>
  <BaseLayoutGap
    class="dashboard-path-params-form"
    direction="column"
    size="medium"
  >
    <header class="dashboard-path-params-form__header">
      <BaseInput type="search" placeholder="Filter paths…" v-model="filter" />
    </header>
    <BaseDivider />
    <BaseForm
      class="dashboard-path-params-form__form"
      submit-label="Save"
      submitting-label="Saving…"
      v-model:valid="isFormValid"
      v-model:dirty="isFormDirty"
      v-model:submitting="isSubmitting"
      @submit="saveChanges"
    >
      <ul class="dashboard-path-params-form__items">
        <li
          v-if="loading"
          class="dashboard-path-params-form__item dashboard-path-params-form__item--loading"
        >
          <BaseLayoutGap><BaseLoader /> Retrieving paths…</BaseLayoutGap>
        </li>
        <li v-else-if="noResults" class="dashboard-path-params-form__item">
          No paths found.
        </li>
        <template v-else>
          <li
            v-for="(urlTree, index) in sortedUrlTreeProvider"
            :key="index"
            class="dashboard-path-params-form__item"
            :class="{
              'dashboard-path-params-form__item--active': activeRow === index,
            }"
          >
            <span
              v-for="part in urlTree"
              :key="part.id"
              class="dashboard-path-params-form__part"
              :data-part-id="part.id"
              :data-mappable="isPartMappable(part)"
              :data-mapped="isPartMapped(part)"
              @click="onMappablePart(index, part)"
            >
              <span class="dashboard-path-params-form__part-text">
                <BaseHighlight
                  :string="getPartDisplayValue(part)"
                  :query="filter"
                /><span
                  class="dashboard-path-params-form__part-input"
                  :class="{
                    'dashboard-path-params-form__part-input--visible':
                      editingId === part.id,
                  }"
                  ><BaseTooltip
                    v-if="
                      isPartMappable(part) &&
                      (models as MappedPathPartsRequest)[part.id]
                    "
                    class="dashboard-path-params-form__part-tooltip"
                    size="small"
                    :show="isInvalid(part.id) && activeRow === index"
                    :disabled="!isInvalid(part.id)"
                  >
                    <template #content
                      ><span class="dashboard-path-params-form__part-error"
                        >Path param is required.</span
                      ></template
                    >
                    <BaseInput
                      :ref="(el) => setFields(index, part.id, el)"
                      class="dashboard-path-params-form__part-field"
                      v-model="
                        (models as MappedPathPartsRequest)[part.id].value
                      "
                      :valid-characters="validPathParamsRegex"
                      :invalid="isInvalid(part.id)"
                      size="compact"
                      @focus="onMappablePart(index, part)"
                      @blur="closeMappablePart(part)"
                      :disabled="isDuplicatePart(index, part)"
                    />
                  </BaseTooltip>
                </span>
              </span>
            </span>
          </li>
        </template>
      </ul>
    </BaseForm>
  </BaseLayoutGap>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.dashboard-path-params-form {
  $self: &;

  width: 100%;

  &__header {
    width: 100%;
  }

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    color: $color-text-tertiary;
    padding-bottom: $space-xxsmall;

    &:last-of-type {
      margin-bottom: 0;
    }

    &--active {
      position: relative;
      z-index: layer('overlap');
    }
  }

  &__part {
    display: inline-block;
    position: relative;

    &:before {
      content: '/';
      color: $color-text-tertiary;
    }

    &[data-mappable='true'] {
      #{$self}__part-text {
        color: $color-text-default;

        &:before {
          content: '{';
        }
        &:after {
          content: '}';
        }
      }
    }

    &[data-mappable='true'][data-mapped='false'] {
      #{$self}__part-text {
        background-color: $color-bg-warning-subtle;
      }
    }

    &--highlighted[data-mappable='true'][data-mapped='false'],
    &--highlighted[data-mappable='true'][data-mapped='true'] {
      #{$self}__part-text {
        cursor: pointer;
        background-color: $color-bg-info-subtle !important;
      }
    }
  }

  &__part-error {
    color: $color-text-danger;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-semibold;
  }

  &__part-input {
    left: -9999px;
    position: absolute;
    top: 0;
    opacity: 0;
    z-index: layer('base');

    &--visible {
      left: 5px;
      top: -3px;
      opacity: 1;
    }
  }

  &__part-field {
    min-width: $space-medium;
    width: calc(100% - 5px);
  }
}
</style>
