<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const skeletonVariants = {
  variant: {
    round: 'skeleton--round',
  },
  placeholder: {
    true: 'skeleton--placeholder',
  },
};

const classes = cva({ variants: skeletonVariants });

export type SkeletonProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  /**
   * Available style variants:
   *
   * * **default**: Square/rectangle skeleton based on supplied height and width.
   * * **round**: Circular skeleton where height and width are equal (width value is disregarded in favour of height value).
   */
  variant?: SkeletonProps['variant'];
  /**
   * A valid CSS value e.g. 24px, 1em, 100% for the height.
   */
  height: string;
  /**
   * A valid CSS value e.g. 24px, 1em, 100% for the width.
   */
  width?: string;
  /**
   * Skeletons are animated by default, this prop disables the animation so the skeleton can
   * be used as a placeholder.
   */
  placeholder?: boolean;
}>();

const dimensions = computed(() => {
  const height = props.height;

  // If variant is round, then width is same as height
  const width = props.variant !== 'round' ? props.width : props.height;

  return `height: ${height}; width: ${width};`;
});
</script>

<template>
  <div
    class="skeleton"
    :style="dimensions"
    :class="classes({ variant, placeholder })"
  />
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.skeleton {
  background: $color-bg-default-subtle;
  border-radius: $space-xxsmall;
  animation: skeleton 0.7s linear infinite alternate;

  &--round {
    border-radius: 50%;
  }

  &--placeholder {
    animation: none;
  }
}

@keyframes skeleton {
  from {
    background-color: $color-bg-default-subtle;
  }

  to {
    background-color: $color-bg-default-subtle-hover;
  }
}
</style>
